import { Box, Button, Flex, useColorModeValue, useToast, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { history } from '../../../../ApplicationRouter'
import { InputControl } from '../../../common/formik-chakra'
import { IOrganization, OrganizationFormValues } from '../../../../models/organization/IOrganization'
import { useCreateOrganizationMutation, useEditOrganizationMutation } from '../../../../api/queries/organization/OrganizationQueries'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { validationSchema } from './Organization.Validation'

interface IProps {
  organization?: IOrganization
}

export const OrganizationForm = (props: IProps) => {
  const { t } = useTranslation();
  const boxBackground = useColorModeValue("bgSecondaryWhite", "bgSecondaryBlack");
  const [formValues, setFormValues] = useState(new OrganizationFormValues())
  const handleCreateOrganization = useCreateOrganizationMutation();
  const handleEditOrganization = useEditOrganizationMutation();
  const queryClient = useQueryClient()
  const toast = useToast();

  useEffect(() => {
    if (props.organization) setFormValues(new OrganizationFormValues(props.organization))
  }, [props.organization])

  const handleSubmit = async (values: any) => {
    setFormValues(values);
    if (props.organization) {
      handleEditOrganization.mutate({ id: props.organization.id, values: values }, {
        onSuccess() {
          queryClient.invalidateQueries(['statistics'])
          history.back();
          toast({
            title: t("Organization.Success.Toast_Title"),
            description: t("Organization.Success.Toast_Description"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Registration.Error.Toast_User_Exists_Title"),
            description: t("Registration.Error.Toast_User_Exists_Description"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    } else {
      handleCreateOrganization.mutate(values, {
        onSuccess() {
          history.back();
          toast({
            title: t("Organization.Success.Toast_Title"),
            description: t("Organization.Success.Toast_Description"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Registration.Error.Toast_User_Exists_Title"),
            description: t("Registration.Error.Toast_User_Exists_Description"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Box bgColor={boxBackground} p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="name" label="Name" />
                <InputControl name="description" label="Description" />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">{t("Submit")}</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  )
}