import { Box, Button, SimpleGrid, useBreakpointValue, useToast, VStack } from '@chakra-ui/react'
import { CustomerProfileSettingsFormValues, ICustomerSimple } from '../../../models/user/ICustomerProfile'
import { Form, Formik } from 'formik'
import { ImageResizerMode, ImageShape } from '../../../models/general/IImageResizerOptions'
import { InputControl } from '../../common/formik-chakra'
import { UploadImage } from '../../common/file-upload'
import { useEditCustomerProfile } from '../../../api/queries/user/EditProfileQuery'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validationSchema } from './ProfileForm.Validation'

interface IProps {
  customer?: ICustomerSimple;
}

export const ProfileForm = (props: IProps) => {
  const { t } = useTranslation();
  const isDesktop = useBreakpointValue({ base: false, md: true })
  const toast = useToast();
  const [formValues, setFormValues] = useState(new CustomerProfileSettingsFormValues());
  const handleEditCustomerProfile = useEditCustomerProfile();

  useEffect(() => {
    if (props.customer) setFormValues(new CustomerProfileSettingsFormValues(props.customer))
  }, [])

  const handleSubmit = (values: CustomerProfileSettingsFormValues) => {
    handleEditCustomerProfile.mutate(values, {
      onSuccess() {
        toast({
          title: t("Customer.Profile.Profile"),
          description: t("Customer.Toast.Profile_Edit_Success"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: t("Customer.Profile.Profile"),
          description: t("Customer.Toast.Profile_Edit_Error"),
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      }
    })
  }

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          {isDesktop ?
            <SimpleGrid columns={2}>
              <VStack>
                <InputControl name="userName" label={t("Customer.Profile.Form.Username")} isDisabled />
                <InputControl name="firstName" label={t("Customer.Profile.Form.FirstName")} />
                <InputControl name="lastNamePrefix" label={t("Customer.Profile.Form.LastNamePrefix")} />
                <InputControl name="lastName" label={t("Customer.Profile.Form.LastName")} />
                <InputControl name="phone" label={t("Customer.Profile.Form.Phone")} />
              </VStack>
              <VStack alignSelf="center" height="full">
                <Box justifySelf="center" alignSelf="center" height="full">
                  <UploadImage
                    name='profileImage'
                    label={t("Customer.Profile.Form.Profile_Image")}
                    imagePath={props.customer?.profileImage?.path}
                    imageResizerOptions={{ mode: ImageResizerMode.scale, height: 100, width: 100 }}
                    imageShape={ImageShape.circle}
                  />
                </Box>
                <Box justifySelf="center" alignSelf="center" height="full" pt="5">
                  <UploadImage
                    name='bannerImage'
                    label={t("Customer.Profile.Form.Banner_Image")}
                    imagePath={props.customer?.bannerImage?.path}
                    imageResizerOptions={{ mode: ImageResizerMode.scale, height: 100, width: 100 }}
                    imageShape={ImageShape.rectangle}
                  />
                </Box>
              </VStack>
              <Box width="full" pt="3">
                <Button type="submit">{t("Submit")}</Button>
              </Box>
            </SimpleGrid>
            :
            <VStack>
              <InputControl name="userName" label={t("Customer.Profile.Form.Username")} isDisabled />
              <InputControl name="firstName" label={t("Customer.Profile.Form.FirstName")} />
              <InputControl name="lastNamePrefix" label={t("Customer.Profile.Form.LastNamePrefix")} />
              <InputControl name="lastName" label={t("Customer.Profile.Form.LastName")} />
              <InputControl name="phone" label={t("Customer.Profile.Form.Phone")} />
              <Box justifySelf="center" alignSelf="center">
                <UploadImage
                  name='profileImage'
                  label={t("Customer.Profile.Form.Profile_Image")}
                  imagePath={props.customer?.profileImage?.path}
                  imageResizerOptions={{ mode: ImageResizerMode.scale, height: 100, width: 100 }}
                  imageShape={ImageShape.circle}
                />
              </Box>
              <Box justifySelf="center" alignSelf="center" pt="5" >
                <UploadImage
                  name='bannerImage'
                  label={t("Customer.Profile.Form.Banner_Image")}
                  imagePath={props.customer?.bannerImage?.path}
                  imageResizerOptions={{ mode: ImageResizerMode.scale, height: 100, width: 100 }}
                  imageShape={ImageShape.rectangle}
                />
              </Box>
              <Box width="full" pt="5">
                <Button width="full" type="submit">{t("Submit")}</Button>
              </Box>
            </VStack>
          }
        </Form>
      )}
    </Formik>
  )
}