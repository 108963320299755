import React from 'react'
import { BeeldmerkDiaCiphers, IconDiscord, IconVimeo, SocialMediaLinkedin } from '../../../assets/icons/components'
import { Box, Flex, Icon, Link as ChakraLink, Text } from '@chakra-ui/react'
import { Link, NavLink } from 'react-router-dom'

export const FooterB2B = () => {
  return (
    <Box as='footer' width="100%" p={12} zIndex={999} maxHeight="fit-content" borderTop="1px solid #55579E" position="relative">
      <Flex flexFlow="row wrap" justifyContent={{ base: "center", md: "flex-start" }} alignItems="center">
        <Flex flexBasis={{ base: "100%", md: "auto" }} mr={{ base: 0, md: 10 }} mb={{ base: 10, md: 0 }} justifyContent="center">
          <Link to="/">
            <Icon mx="auto" as={BeeldmerkDiaCiphers} w={10} h={10} color="#55579E" />
          </Link>
        </Flex>
        <Flex mx={{ base: "auto", lg: "0px" }} mb={{ base: 10, md: 0 }}>
          <Text as={NavLink} to="/privacy-policy" color="#55579E" >Privacy Policy</Text>
        </Flex>
        <Flex ml="auto" flexBasis={{ base: "100%", md: "auto" }} justifyContent={{ base: "center", md: "flex-start" }}>
          <ChakraLink isExternal href='https://discord.gg/X69zQm4kWk'>
            <Icon as={IconDiscord} w={8} h={8} mr={10} color="#55579E" />
          </ChakraLink>
          <ChakraLink isExternal href='https://www.linkedin.com/company/ciphersme/ '>
            <Icon as={SocialMediaLinkedin} w={8} h={8} mr={10} color="#55579E" />
          </ChakraLink>
          <ChakraLink isExternal href='https://vimeo.com/ciphersme'>
            <Icon as={IconVimeo} w={8} h={8} color="#55579E" />
          </ChakraLink>
        </Flex>
      </Flex>
    </Box>
  )
}
