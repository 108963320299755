import { Box, HStack } from '@chakra-ui/react'
import { ScrollItem } from './ScrollItem'
import './HScroll.css'

interface IProps {
  children: React.ReactNode[]
}

/*
  Generic horizonal scroll component, takes up full width of parent component.
*/
export const HScroll = (props: IProps) => {

  return (
    <Box borderRadius="14px 14px 14px 14px" w="100%">
      <HStack spacing="4" overflowX="scroll" overflowY="hidden" className="hscroll" pb="4">
        {props.children.map((child, index) => {
          return (
            <ScrollItem key={index}>
              {child}
            </ScrollItem>
          )
        })}
      </HStack>
    </Box>
  )
}