import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface IProps {
  descriptions: string[];
}

export const JoyrideBaseComponent = (props: IProps) => {
  const { t } = useTranslation();

  if (props.descriptions.length <= 0) return null

  return (
    <>
      {props.descriptions.map((description, index) => (
        <Text size="large" key={index} color="bgPrimaryBlack">
          {t(description)}
        </Text>
      ))}
    </>
  )
}