import { EProfilePrivacy } from '../../../../enums/EProfilePrivacy'
import { HiCalendar } from 'react-icons/hi'
import { HStack, Icon, Stack, StackProps, Text, useColorModeValue } from '@chakra-ui/react'
import { INftOverSerie } from '../../../../models/user/ICustomerProfile'
import { ToolTipRoutButton } from '../../../common/routing/ToolTipRoutButton'
import { useTranslation } from 'react-i18next'
import { ViewIcon } from '@chakra-ui/icons'

export interface UserInfoProps extends StackProps {
  privacySetting?: EProfilePrivacy
  stats: INftOverSerie
  memberSince: string
}
export const UserInfo = (props: UserInfoProps) => {

  const { privacySetting, stats, memberSince, ...stackProps } = props
  const { t } = useTranslation()

  // TODO: Figure out what text to use on smaller screens as noOfLines is obviously not gonna cut it in a client facing environment.

  return (
    <Stack
      direction={{ base: 'column', sm: 'row' }}
      spacing={{ base: '1', sm: '6' }}
      mt="4"
      fontSize="sm"
      fontWeight="medium"
      color={useColorModeValue('primary', 'primary_DM')}
      {...stackProps}
    >
      {privacySetting !== undefined &&
        <HStack>
          <Icon as={ViewIcon} />
          <ToolTipRoutButton
            to="./edit?tab=visibility"
            toolTipText={privacySetting === EProfilePrivacy.Public
              ? t("Profile.Public_Profile_Description")
              : t("Profile.Private_Profile_Description")
            }
          >
            <Text noOfLines={1}>
              {privacySetting === EProfilePrivacy.Public ? t("Profile.Public_Profile") : t("Profile.Private_Profile")}
            </Text>
          </ToolTipRoutButton>
        </HStack>
      }
      <HStack>
        <Text fontWeight="bold" color={useColorModeValue('primary', 'primary_DM')}>
          {stats.ownedNfts}
        </Text>
        <Text noOfLines={1}>
          {t("Series.Owned_Over", { 0: stats.nftSeries })}
        </Text>
      </HStack>
      <HStack>
        <Icon as={HiCalendar} />
        <Text>{memberSince}</Text>
      </HStack>
    </Stack>
  )
}
