import { ComponentSingleStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

export const textStyles: ComponentSingleStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => ({
    color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.900"
  }),
  variants: {
    "campaignCardGridBase": {
      textShadow: "1px 1px 1px black"
    }
  }
}