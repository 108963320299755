import { forwardRef } from 'react'
import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const Component = forwardRef((props: any, ref) => (
  <GridItem ref={ref as any} {...props}>
    {props.children}
  </GridItem>
))

const SGrid = forwardRef((props: any, ref) => (
  <SimpleGrid ref={ref as any} {...props}>
    {props.children}
  </SimpleGrid>
));

export const MotionSimpleGrid = motion(SGrid);

export const MotionGridItem = motion(Component);