import parrotStamp from '../../../../assets/b2b/home/NFT_TEMP.png'
import React from 'react'
import { Box, Button, Center, Flex, Heading, Image, Text, useBreakpointValue, VisuallyHidden } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Stamps = () => {
  const { t } = useTranslation();
  const boxWidth = useBreakpointValue({base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px"});

  const handleBoxClick = (ref: React.RefObject<HTMLDivElement>, position: number = 0): void => {
    if (position === 4) return;
    if (!(ref.current?.childNodes[position] as HTMLInputElement)?.value) {
      return (ref.current?.childNodes[position] as HTMLInputElement)?.focus();
    } else {
      return handleBoxClick(ref, position + 1);
    }
  };

  return (
    <Center as="section" id="stamps">
      <Flex width={boxWidth} flexFlow="row wrap" justifyContent="space-between" alignItems="center" backgroundColor="#4129FF" py={10} px={{ base: 6, md: 16 }}>
        <Box>
          <Flex>
            <Box display={{ md: "block" }} w={{ base: "100%", md: "75%" }}>
              <Heading as="h3" color="whiteAlpha.900" mb={4}>
                {t("B2B.Home.Stamps.NotReceived.Title")}
              </Heading>
              <Text color="whiteAlpha.900" mb={4}>
                {t("B2B.Home.Stamps.NotReceived.Description")}
              </Text>
              <Button as={NavLink} to="/contact" variant="b2bPrimary" mb={4} width={{ base: "100%", md: "fit-content" }}>
                {t("B2B.Home.Stamps.NotReceived.Button").toUpperCase()}
              </Button>
            </Box>
            <VisuallyHidden>
              <Box>
                <Image
                  src={parrotStamp}
                  borderRadius={{ base: "15px", md: "25px", lg: "35px" }}
                  boxShadow="dark-lg"
                  _hover={{ hueRotate: "10deg" }}
                  border={"1px solid white"}
                  alt="stamps"
                  pos={{ base: "relative", md: "relative", lg: "absolute" }}
                  mt={{ base: "0px", md: "-50px", lg: "-75px" }}
                  style={{
                    transform: "rotate(13deg)",
                  }}
                  h={{ base: "100px", sm: "150px", md: "250px", lg: "300px" }}
                />
              </Box>
            </VisuallyHidden>
          </Flex>
        </Box>
      </Flex>
    </Center>
  );
};
