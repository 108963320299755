import { EditCustomer } from '../../pages/customer/EditCustomer'
import { IRoute } from '../../models/routes/IRoute'
import { PersonalPage } from '../../pages/secure/customer/PersonalPage'
import { Roles } from '../../enums/Roles'

export const CustomerRoutes: IRoute[] = [
  {
    element: <PersonalPage />,
    path: "",
    permission: [Roles.CUSTOMER],
    title: "Route_Personal_Page"
  },
  {
    element: <EditCustomer />,
    path: "edit",
    permission: [Roles.CUSTOMER],
    title: "Route_Personal_Page_Edit"
  }
]