import { ApplicationRouter } from './ApplicationRouter'
import { AuthContext, authTokenKey, useAuth } from './hooks/useAuth'
import { AxiosInterceptor } from './components/axiosInterceptor/AxiosInterceptor'
import { ChakraProvider, extendTheme, GlobalStyle, useToast } from '@chakra-ui/react'
import { HelmetProvider } from 'react-helmet-async'
import { isDesktop } from 'react-device-detect'
import { JoyrideContext, useJoyride } from './hooks/useJoyride'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { theme } from './styles/Theme/theme'
import { Translation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useScreenSize } from './hooks/useScreenSize'

const queryClient = new QueryClient();

function App() {
  const authObject = useAuth(localStorage.getItem(authTokenKey) ?? "");
  const joyrideObject = useJoyride();

  const chakraTheme = extendTheme(theme);
  const toast = useToast();

  const windowSizeListener = useScreenSize();
  const [currBreakpoint, setCurrBreakpoint] = useState("base");

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      let breakpoint = "base"
      let colorscheme = "red";
      if (windowSizeListener.width! > 0 && windowSizeListener.width! < 320) {
        breakpoint = "base"
        colorscheme = "red"
      }

      if (windowSizeListener.width! > 320 && windowSizeListener.width! < 723) {
        breakpoint = "SM"
        colorscheme = "cyan"
      }

      if (windowSizeListener.width! > 723 && windowSizeListener.width! < 933) {
        breakpoint = "MD"
        colorscheme = "blue"
      }

      if (windowSizeListener.width! > 933 && windowSizeListener.width! < 1127) {
        breakpoint = "LG"
        colorscheme = "green"
      }

      if (windowSizeListener.width! > 1127) {
        breakpoint = "XL"
        colorscheme = "purple"
      }

      if (breakpoint !== currBreakpoint) {
        toast({
          title: (isDesktop ? "Desktop" : "Mobile") + ' breakpoint changed to: ' + breakpoint,
          description: "Screen Width: " + windowSizeListener.width!,
          duration: 5000,
          colorScheme: colorscheme,
          isClosable: true,
        })
        setCurrBreakpoint(breakpoint);
      }
    }
  }, [windowSizeListener.width])

  return (
    <AuthContext.Provider value={authObject!}>
      <JoyrideContext.Provider value={joyrideObject!}>
        <ChakraProvider theme={chakraTheme}>
          <GlobalStyle />
          <AxiosInterceptor>
            <QueryClientProvider client={queryClient}>
              <HelmetProvider>
                <Translation>
                  {() =>
                    <ApplicationRouter />
                  }
                </Translation>
              </HelmetProvider>
              <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
            </QueryClientProvider>
          </AxiosInterceptor>
        </ChakraProvider>
      </JoyrideContext.Provider>
    </AuthContext.Provider>
  )
}

export default App;
