import { Center, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { imageOrPlaceholder } from '../../utils/General'
import { INft } from '../../models/nfts/INft'
import { isPortrait } from '../../utils/imageUtils/isPortrait'
import { useMemo } from 'react'
import './NFT.css'

interface IProps {
  nft: INft;
  context?: "home" | "collection" | "serie" // Reuse this component for different contexts
  className?: string //
  overwriteIsClaimed?: boolean; // Overwrite used to show the NFT as available in the Showcase component
}

export const NFT = (props: IProps) => {

  const { context, nft, overwriteIsClaimed } = props;

  // TODO: Add a loading state
  // TODO: Make use of useBreakPointValue to change the size of the NFT based on the screen size
  const getWidth = () => {
    switch (context) {
      case "home":
      case "serie":
        return "204px"
      case "collection":
        return "145px"
      default:
        return "204px"
    }
  }

  const getHeight = () => {
    switch (context) {
      case "home":
      case "serie":
        return "274px"
      case "collection":
        return "206px"
      default:
        return "274px"
    }
  }

  const width = getWidth();
  const height = getHeight();

  const imgSource = useMemo(() => imageOrPlaceholder(nft?.nftImage?.path), [nft?.nftImage?.path])

  const img = <img src={imgSource} alt={nft?.translation?.title} style={{ width: width, height: parseInt(height.slice(0, height.length - 2)) - 98 + "px", objectFit: isPortrait(imgSource) ? "contain" : "cover" }} />

  return (
    <Stack opacity={(!overwriteIsClaimed && !nft.isClaimed) ? "0.5" : "1"} gap={0} spacing={0} w={width} h={height} boxShadow={"0px 11px 20px 0px rgba(0,0,0,0.06)"} className={props.className ? props.className : ""}>
      <Center flex="1" bgColor="gray.700" borderRadius="7px 7px 0px 0px" overflow="hidden">
        {img}
      </Center>
      <Stack p="4" h={{ md: "98px" }} bgColor={useColorModeValue("primaryBlack", "primaryWhite")} borderRadius="0px 0px 7px 7px" >
        <Text color={useColorModeValue("primaryWhite", "primaryBlack")} fontWeight={"bold"} fontFamily="CircularStd">
          {nft?.translation?.title}
        </Text>
        <Text color={useColorModeValue("whiteAlpha.600", "blackAlpha.600")}>
          01/05 TODO:
        </Text>
      </Stack>
    </Stack>
  )
}