import { apiClient } from './../../base/ApiClients'
import { CustomerProfileSettingsFormValues } from '../../../models/user/ICustomerProfile'
import { CustomerVisibilitySettingsFormValues } from './../../../models/user/ICustomerProfile'
import { useMutation } from 'react-query'

export const EditCustomerProfile = async (values: CustomerProfileSettingsFormValues) => {
  let formData = new FormData();
  formData.append("userName", values.userName);
  formData.append("firstName", values.firstName);
  formData.append("lastNamePrefix", values.lastNamePrefix);
  formData.append("lastName", values.lastName);
  formData.append("phone", values.phone);
  formData.append("profileImage", values.profileImage);
  formData.append("bannerImage", values.bannerImage);

  return await apiClient.put("profile/customer", formData);
}

export const useEditCustomerProfile = () => useMutation(EditCustomerProfile, {})

export const EditVisibilitySettings = async (values: CustomerVisibilitySettingsFormValues) => {
  return await apiClient.put("profile/customer/visibility", values);
}

export const useEditVisibilitySettings = () => useMutation(EditVisibilitySettings, {})