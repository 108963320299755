import React from 'react'
import { AspectRatio, Box, Center, Flex, Heading, List, ListIcon, ListItem, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { IconCheck } from '../../../../assets/icons/components'
import { Trans, useTranslation } from 'react-i18next'

export const Benefits = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation()
  return (
    <Center as='section' id='benefits' zIndex={1}>
      <Box width={boxWidth}>
        <Box display="flex" flexFlow="row wrap" justifyContent="space-between" position="relative">
          <Box flexBasis={{ base: "100%", lg: "55%" }} mb={{ base: 8, lg: 0 }}>
            <Heading as="h2" fontSize="4xl" mb={12} color="white" textAlign={{ base: "center", md: "left" }}>
              <Trans i18nKey="B2B.Home.Benefits.Title">
                Placeholder <span className='magic'></span>
              </Trans>
            </Heading>
            <VStack spacing={4} alignItems="flex-start">
              <Text color="white" lineHeight="1.75">{t("B2B.Home.Benefits.Description")}</Text>
            </VStack>
          </Box>
          <Flex
            flexBasis={{ base: "100%", lg: "40%" }}
            flexDirection="column"
            justify={{ base: "flex-start", lg: "center" }}
            alignItems={{ base: "flex-start", lg: "center" }}
            zIndex={1}
          >
            <Box marginLeft={{ base: "0px", lg: "auto" }}>
              <Heading as="h3" fontSize="3xl" mb={5} color="white">Benefits</Heading>
              <List
                spacing={4}
                color="white"
              >
                {
                  [...Array(6)].map((e, i) => (
                    <ListItem>
                      <ListIcon as={IconCheck} w={5} h={5} color='ciphers.main.green' />
                      {t(`B2B.Home.Benefits.Pros.${i + 1}`)}
                    </ListItem>
                  ))
                }
              </List>
            </Box>
          </Flex>
          <AspectRatio
            width={{ base: "200%", md: "100%", lg: "60%" }}
            right={{ base: "-75%", md: "-20%" }}
            top={{ base: "60%", sm: "50%", md: "30%", lg: "-10%" }}
            ratio={1 / 1}
            position="absolute"
            transform="rotateZ(0deg)"
            zIndex={-1}
            _after={{
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              transform: "scale(0.8)",
              clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
              bgGradient: 'linear(to-tr, transparent, transparent, #11095D )'
            }}
          >
            {/* Add empty Box because AspectRatio needs at least one child */}
            <Box></Box>
          </AspectRatio>
        </Box>
      </Box>
    </Center >
  )
}
