import { DevLog } from '../utils/DevLog'
import { isLocalhost } from '../utils/General'
import { useEffect } from 'react'

/**
 * Adds the specified tracking code(s) to the head.
 * @param trackingId - The tracking code to be used.
 * @param devTrackingId - Optional tracking code used for dev-validation.
 */
export const UseGoogleAnalytics = (trackingId: string, devTrackingId?: string) => {
  useEffect(() => {
    DevLog("-- On a non local environment, we'd set Google Analytics here --", trackingId);
    if (isLocalhost()) {
      return;
    }

    // Check if the GA script is already loaded
    if (!(window as any).gtag) {
      // Create a script element to insert GA tracking code

      const asyncScript = document.createElement("script");
      asyncScript.async = true;
      asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
      document.head.appendChild(asyncScript);

      if (devTrackingId != undefined) {
        const asyncDevScript = document.createElement("script");
        asyncDevScript.async = true;
        asyncDevScript.src = `https://www.googletagmanager.com/gtag/js?id=${devTrackingId}`;
        document.head.appendChild(asyncDevScript);
      }

      const gtagScript = document.createElement("script");
      gtagScript.innerHTML = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${trackingId}');
${devTrackingId != undefined ? `gtag('config', '${devTrackingId}');` : ''}
      `;

      document.head.appendChild(gtagScript);
    } else {
      // Send pageview if GA script is already loaded
      (window as any).gtag('send', 'pageview');
    }
  }, [trackingId])
}