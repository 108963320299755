import axios from 'axios'

//Two Axios clients because the login api has a seperate endpoint.
//This can probrably be fixed with some config code so we could use one client/
export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 10000,
  headers: {
    "Accept": "application/json",
    // "Content-Type": "application/json",
    // Removed the explicit content type declaration for support of multipart/form-data
    // Axios also looks at the request body for adding a "Content-Type": "application/json" if the body is plain javascript, js object or a js array.

    // Authorization token has to be set in the interceptor
    // This to ensure the newest token is used.
  }
})

export const loginClient = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  timeout: 3000,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  }
})