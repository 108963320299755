import React from 'react'
import { Action } from '../../../../components/b2b/Action'
import { Box, Center, Icon, useBreakpointValue } from '@chakra-ui/react'
import { ConversionExchange, EmailStamp } from '../../../../assets/icons/components'
import { Trans, useTranslation } from 'react-i18next'

export const SellingPoints = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Center as='section' id='sellingPoints'>
      <Box width={boxWidth}>
        <Box display="flex" flexFlow="row wrap" justifyContent="space-between">
          <Action
            icon={<Icon as={EmailStamp} color="ciphers.electricViolet" w={16} h={16} mr="auto" mb={20} />}
            header={t("B2B.PostalAction.SellingPoints.1.Title")}
            text={
              <Trans i18nKey="B2B.PostalAction.SellingPoints.1.Description">
                Placeholder <br></br>
              </Trans>
            }
            flexBasis={{ base: "100%", md: "48%" }}
          />
          <Action
            icon={<Icon as={ConversionExchange} color="ciphers.main.green" w={16} h={16} mr="auto" mb={20} />}
            header={t("B2B.PostalAction.SellingPoints.2.Title")}
            text={
              <Trans i18nKey="B2B.PostalAction.SellingPoints.2.Description">
                Placeholder <br></br>
              </Trans>
            }
            flexBasis={{ base: "100%", md: "48%" }}
          />
        </Box>
      </Box>
    </Center>
  )
}
