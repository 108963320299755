import header_image from '../../../../assets/b2b//home/header-image.png'
import React from 'react'
import { Box, Button, Center, Flex, Heading, Image, useBreakpointValue } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

export const Hero = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  const stampsSection = document.getElementById("stamps")

  const handleClick = () => {
    if (stampsSection) {
      window.scrollTo({
        top: stampsSection.offsetTop - 100,
        behavior: "smooth"
      })
    }
  }

  return (
    <Center as='section' id='heading' mb={{ base: 10, md: 4 }}>
      <Flex width={boxWidth} flexFlow="row wrap" justifyContent="space-between" alignItems="center">
        <Box flexBasis={{ base: "100%", md: "52%" }} order={{ base: 2, md: 1 }}>
          <Heading as="h1" variant="pageTitle" mb={{ base: 12, md: 16 }} textAlign={{ base: "center", md: "left" }} fontSize={{ base: "4xl", md: "5xl" }}>
            <Trans i18nKey="B2B.Home.Hero.Title">
              Placeholder <span className='magic'></span>
            </Trans>
          </Heading>
          <Flex flexFlow="row wrap" justifyContent="space-between">
            <Button
              as={NavLink}
              to="/contact"
              variant="b2bPrimary"
              borderRadius={4}
              flexBasis={{ base: "100%", lg: "48%" }}
            >
              {t("B2B.Home.Hero.ButtonSecondary").toUpperCase()}
            </Button>
          </Flex>
        </Box>
        <Box flexBasis={{ base: "100%", md: "48%" }} order={{ base: 1, md: 2 }} mb={{ base: 8, lg: 0 }} p={{base: "2rem 0rem 0rem 4rem", md:"0rem 0rem 0rem 0rem"}}>
          <Image ml="auto" src={header_image} width={{base: "100%", md:"80%"}} htmlWidth={720} htmlHeight={621} alt='a hand with a stamp hovering above it' />
        </Box>
      </Flex >
    </Center >
  )
}
