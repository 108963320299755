import { apiClient } from './../../base/ApiClients'
import { IDashboardOrganization, OrganizationFormValues, OrganizationFormValuesWithId } from './../../../models/organization/IOrganization'
import { useMutation } from 'react-query'

export const GetDashboardOrganizationsQuery = async () => {
  const { data } = await apiClient.get<IDashboardOrganization[]>("Organization/dashboard")
  return data;
}

export const GetDashboardOrganizationQuery = async (id: any) => {
  const { data } = await apiClient.get<IDashboardOrganization>(`Organization/dashboard/${id}`)
  return data;
}

export const CreateOrganization = async (values: OrganizationFormValues) => {
  return await apiClient.post('Organization', values)
}

export const useCreateOrganizationMutation = () => useMutation(CreateOrganization, {});

export const EditOrganization = async (values: OrganizationFormValuesWithId) => {
  return await apiClient.put(`Organization/${values.id}`, values.values);
}

export const useEditOrganizationMutation = () => useMutation(EditOrganization, {});