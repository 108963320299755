import { apiClient } from '../../base/ApiClients'
import { ESerieHighlightSpot } from '../../../enums/ESerieHighlightSpot'
import { IFeaturedSerie, ISerieDto } from '../../../models/Serie/ISerie'

export const GetFeaturedSeriesQuery = async () => {
  const { data } = await apiClient.get<IFeaturedSerie[]>("serie-featured")
  return data;
}

export const GetFeaturedSerieQuery = async (highlightSpot: ESerieHighlightSpot) => {
  const { data } = await apiClient.get<ISerieDto>(`serie-featured/highlight?highlightSpot=${highlightSpot}`);
  return data;
}