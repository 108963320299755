export const colors = {
  blackAlpha: {
    900: "#141517",
    800: "#1A1B1E",
    700: "#373A3F",
    600: "rgba(0, 0, 0, 0.48)",
    500: "rgba(0, 0, 0, 0.36)",
    400: "rgba(0, 0, 0, 0.24)",
    300: "rgba(0, 0, 0, 0.16)",
    200: "#2C2D30",
    100: "rgba(0, 0, 0, 0.06)",
    50: "rgba(0, 0, 0, 0.04)",
  },
  gray: {
    900: "#5F6062",
    800: "#918E8D",
    700: "#D7D7D7",
    600: "#4A5568",
    500: "#718096",
    400: "#A0AEC0",
    300: "#CBD5E0",
    200: "#E2E8F0",
    100: "#EDF2F7",
    50: "#F7FAFC",
  },
  whiteAlpha: {
    900: "#FEFDFD",
    800: "#FCFCFC",
    700: "#F2F2F2",
    600: "#E9E9E9",
    500: "rgba(255, 255, 255, 0.36)",
    400: "rgba(255, 255, 255, 0.24)",
    300: "rgba(255, 255, 255, 0.16)",
    200: "rgba(255, 255, 255, 0.08)",
    100: "rgba(255, 255, 255, 0.06)",
    50: "rgba(255, 255, 255, 0.04)",
  },
  bgPrimary: "#000031",
  bgSecondary: "#08093f",
  bgTertiary: "#101152",
  primaryBlack: "#1A1B1E",
  bgPrimaryBlack: "#141517",
  bgSecondaryBlack: "#1E1F22",
  bgTertiaryBlack: "#202225",
  bgWhiteWhite: "white",
  bgPrimaryBlackNavbar: "rgba(20, 21, 23, 0.7)",
  bgPrimaryWhiteNavbar: "rgba(254, 253, 253, 0.7)",
  bgPrimaryWhite: "#FEFDFD",
  bgSecondaryWhite: "#F2F2F2",
  bgTertiaryWhite: "#E9E9E9",
  primaryWhite: "#FEFDFD",
  primaryAccent: "#FFC74C",
  primary: "#9747FF",
  primary_DM: "#BC58FF",
  ciphers: {
    viridianGreen: "#0f9b90",
    ultraMarine: "#1a099f",
    cornflowerBlue: "#6a77ff",
    stratos: "#000132",
    deluge: "#7071ad",
    electricViolet: "#8721ff",
    pigmentIndigo: "#48039a",
    scampi: "#55579e",
    blueZodiac: "#101152",
    luckyPoint: "#222472",
    jazzberryJam: "#a10862",
    heliotrope: "#AC7BFF",
    coldPurple: "#adaedc",
    frenchPass: "#B4FFFF",
    lavenderRose: "#FCA9FC",
    persianRose: "#ff36ad",
    errorBackground: "#FF165F",
    main: {
      blue: "#4129ff",
      dark: "#08093f",
      green: "#38ffef",
      buttonSecondary: "#070851",
      buttonPrimary: "#3f24ff",
      textSecondary: "#7071ad"
    }
  }
}