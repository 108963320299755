import { apiClient } from '../../base/ApiClients'
import { AxiosError } from 'axios'
import { MarketingCodeFormValues } from '../../../models/b2b/MarketingCode'
import { useMutation } from 'react-query'

export async function claimMarketingCode(values: MarketingCodeFormValues) {
  return await apiClient.put('/b2b/MarketingCode', values);
}

export const useMarketingCodeMutation = () => useMutation(claimMarketingCode, {
  onError: (err: AxiosError) => { }
});

export async function validateMarketingCode(values: MarketingCodeFormValues) {
  return await apiClient.put('/b2b/marketingcode/validate', values);
}

export const useValidateMarketingCodeMutattion = () => useMutation(validateMarketingCode, {
  onError: (err: AxiosError) => { }
})