import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface IProps {
  heading: string;
}

export const JoyrideHeaderComponent = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Text size="large" color="bgPrimaryBlack">
        {t(props.heading)}
      </Text>
    </>
  )
}