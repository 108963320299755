import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, Spinner, useColorModeValue } from '@chakra-ui/react'
import { GetDashboardSerie } from '../../../../api/queries/serie/SerieQueries'
import { history } from '../../../../ApplicationRouter'
import { SerieForm } from '../../../../components/secure/dashboard/serie/SerieForm'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

export const EditSerie = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  const { data } = useQuery(['serie', id], () => GetDashboardSerie(id), { enabled: !!id });

  return (
    <AutoWidthCenter bg={useColorModeValue("bgTertiaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Series.Edit')}</Heading>
          <Button onClick={() => history.back()}>{t('Return')}</Button>
        </HStack>
        <Divider />
        {data ?
          <SerieForm serie={data} />
          :
          <Spinner />
        }
      </Box>
    </AutoWidthCenter >
  )
}