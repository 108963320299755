import { Box, Center, chakra, forwardRef } from '@chakra-ui/react'
import { motion } from 'framer-motion'
/*
  Add default layout to a page and center it.
*/
export const AutoWidthCenter = forwardRef((props: any, ref) => {
  return (
    <Center width={"100%"} px={{ base: "0", md: "4" }} bgColor={props.bg ? props.bg : null} ref={ref as any}>
      <Box width={{ xl: "1127px", lg: "933px", md: "723px", sm: "100%", base: "100%" }} h="100%">
        {props.children}
      </Box>
    </Center>
  )
})

export const MotionAutoWidthCenter = motion(chakra(AutoWidthCenter));

export const ChakraAutoWidthCenter = chakra(AutoWidthCenter);