import limitedOfferImage from '../../../../assets/b2b/postal-action/limited-offer-stars.png'
import React from 'react'
import { AspectRatio, Box, Button, Center, Flex, Heading, Img, Text, useBreakpointValue } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

export const StartFreePilot = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Center as='section' id='startFreePilot' mt={{ base: "24", lg: "18" }}>
      <Box
        width={boxWidth}
        pt={{ base: 4, lg: 12 }}
        pl={{ base: 4, lg: 12 }}
        pr={{ base: 4, lg: 12 }}
        backgroundColor="#101152"
      >
        <Flex
          py={{ base: 10, md: 12 }}
          px={{ base: 4, md: 12 }}
          bgGradient="linear(to-r, #4129FF, #FF36AD, )"
          position="relative"
          overflow="hidden"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          mb={{ base: 3, md: 0 }}
        >
          <Heading as="h2" mb={4} color="white">{t("B2B.PostalAction.StartFreePilot.Title")}</Heading>
          <Text mb={9} color="white">{t("B2B.PostalAction.StartFreePilot.Subtitle")}</Text>
          <Button as={NavLink} to="/contact/1" zIndex={2} width={{ base: "100%", lg: "fit-content" }} variant="b2bPrimary" py={2} px={6}>{t("B2B.PostalAction.StartFreePilot.Button1").toUpperCase()}</Button>
          <AspectRatio
            display={{ base: "none", md: "block" }}
            width="100%"
            ratio={1 / 1}
            position="absolute"
            left="-50%"
            top="-67%"
            transform="rotateZ(180deg)"
            _after={{
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              transform: "scale(0.65)",
              clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
              background: 'rgba(0, 0, 0, 0.15)'
            }}
          >
            {/* Add empty Box because AspectRatio needs at least one child */}
            <Box></Box>
          </AspectRatio>
          <AspectRatio
            display={{ base: "none", md: "block" }}
            width="100%"
            ratio={1 / 1}
            position="absolute"
            right="-50%"
            top="-67%"
            transform="rotateZ(180deg)"
            _after={{
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              transform: "scale(0.65)",
              clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
              background: 'rgba(0, 0, 0, 0.15)'
            }}
          >
            {/* Add empty Box because AspectRatio needs at least one child */}
            <Box></Box>
          </AspectRatio>
        </Flex>
        <Center p={6}>
          <Flex alignItems="center" flexDirection={{ base: "column", xl: "row" }}>
            <Flex mr={{ base: 0, xl: 4 }} mb={{ base: 4, xl: 0 }} alignItems="flex-start" flexShrink={0} ml={{ base: -6, md: 0 }}>
              <Img src={limitedOfferImage} mt="-20px" mr="-5px" />
              <Box backgroundColor="#FF36AD" borderRadius="20px" py={2} px={3} color="white">{t("B2B.PostalAction.StartFreePilot.Button2")}</Box>
            </Flex>
            <Flex flexDirection="column" justify="center" textAlign={{ base: "center", xl: "left" }}>
              <Text as="span" color="white" mb={{ base: 2, xl: 0 }}>
                <Trans i18nKey="B2B.PostalAction.StartFreePilot.Message">
                  Placeholder <span className='magic'></span>
                </Trans>
              </Text>
            </Flex>
          </Flex>
        </Center>
      </Box>
    </Center >
  )
}
