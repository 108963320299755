import * as Yup from 'yup'

export const validationSchema = Yup.object({
  translation: Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
  }),
  serieType: Yup.number().required(),
  bannerImage: Yup.mixed().required(),
  smallImage: Yup.mixed().required(),
})