import * as Yup from 'yup'

export const validationSchema = Yup.object({
  username: Yup.string(),
  firstName: Yup.string(),
  lastNamePrefix: Yup.string(),
  lastName: Yup.string(),
  phone: Yup.string(),
  profilePicture: Yup.object().shape({
    name: Yup.string()
  }).nullable(),
  bannerImage: Yup.object().shape({
    name: Yup.string()
  }).nullable(),
})