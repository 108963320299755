import React from 'react'
import { AspectRatio, Box, Center, Flex, Heading, Icon, Text, useBreakpointValue } from '@chakra-ui/react'
import { Blockchain, Marketplace } from '../../../../assets/icons/components'
import { Trans, useTranslation } from 'react-i18next'

export const Roadmap = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Center as='section' id='roadmap'>
      <Box width={boxWidth} mb={36}>
        <Heading as="h2" color="white" mb={4}>
          <Trans i18nKey={"B2B.PostalAction.Roadmap.Title"}>
            placeholder <span className='magic'></span>
          </Trans>
        </Heading>
        <Text as="p" color="white" mb={14} width={{base: "100%", md: "60%"}}>
          {t("B2B.PostalAction.Roadmap.Message")}
        </Text>

        <Flex justifyContent="space-between" flexFlow="row wrap" >
          <Box flexBasis={{ base: "100%", md: "48%" }} flexFlow="row wrap" p={8} pb={20} borderRadius={8} backgroundColor="#101152" position="relative" overflow="hidden" zIndex={1} mb={{ base: 4, lg: 0 }}>
            <Flex justifyContent="space-between" alignItems="flex-end" mb={8}>
              <Text backgroundColor="whiteAlpha.400" as="span" color="whiteAlpha.600" px={2} py={1} borderRadius={10}>{t("B2B.PostalAction.Roadmap.1.Phase")}</Text>
              <Icon as={Blockchain} color="white" w={14} h={14} />
            </Flex>
            <Heading as="h2" mb={4} color="white">
              <Trans i18nKey="B2B.PostalAction.Roadmap.1.Title">
                Placeholder <br></br>
              </Trans>
            </Heading>
            <Text as="span" color="whiteAlpha.500">
              {t("B2B.PostalAction.Roadmap.1.Message")}
            </Text>
            <AspectRatio
              width="100%"
              ratio={1 / 1}
              position="absolute"
              left="-30%"
              top="-70%"
              transform="rotateZ(180deg)"
              filter="blur(30px)"
              zIndex={-1}
              _after={{
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: -1,
                clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                bgGradient: 'linear(to-l, #FF36AD, #33159D)'
              }}
            >
              {/* Add empty Box because AspectRatio needs at least one child */}
              <Box></Box>
            </AspectRatio>
          </Box>
          <Box flexBasis={{ base: "100%", md: "48%" }} flexFlow="row wrap" p={8} pb={20} borderRadius={8} backgroundColor="#101152" position="relative" overflow="hidden" zIndex={1}>
            <Flex justifyContent="space-between" alignItems="flex-end" mb={8}>
              <Text backgroundColor="whiteAlpha.400" as="span" color="whiteAlpha.600" px={2} py={1} borderRadius={10}>{t("B2B.PostalAction.Roadmap.2.Phase")}</Text>
              <Icon as={Marketplace} color="white" w={14} h={14} />
            </Flex>
            <Heading as="h2" mb={4} color="white">{t("B2B.PostalAction.Roadmap.2.Title")}</Heading>
            <Text as="span" color="whiteAlpha.500">
              {t("B2B.PostalAction.Roadmap.2.Message")}
            </Text>
            <AspectRatio
              width="100%"
              ratio={1 / 1}
              position="absolute"
              right="-50%"
              top="20%"
              transform="rotateZ(180deg)"
              filter="blur(30px)"
              zIndex={-1}
              _after={{
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: -1,
                clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                backgroundColor: '#3C73FA'
              }}
            >
              {/* Add empty Box because AspectRatio needs at least one child */}
              <Box></Box>
            </AspectRatio>
          </Box>
        </Flex>
      </Box>
    </Center>
  )
}
