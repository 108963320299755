import { DashboardHome } from '../../pages/secure/dashboard/DashboardHome'
import { IRoute } from '../../models/routes/IRoute'
import { Roles } from '../../enums/Roles'

export const GeneralRoutes: IRoute[] = [
  {
    element: <DashboardHome />,
    path: "",
    permission: [Roles.ADMIN, Roles.ORGANIZATIONMANAGER],
    title: "Dashboard"
  }
]