import { CreateOrganization } from '../../pages/secure/dashboard/organization/CreateOrganization'
import { EditOrganization } from '../../pages/secure/dashboard/organization/EditOrganization'
import { IRoute } from '../../models/routes/IRoute'
import { Organizations } from '../../pages/secure/dashboard/organization/Organizations'
import { Roles } from '../../enums/Roles'

export const OrganizationRoutes : IRoute[] = [
  {
    element: <Organizations/>,
    path: "organization",
    permission: [Roles.ADMIN],
    showInMenu: true,
    title: "Organizations"
  },
  {
    element: <CreateOrganization />,
    path: "organization/new",
    permission: [Roles.ADMIN],
    title: "Organizations"
  },
  {
    element: <EditOrganization />,
    path: "organization/edit/:id",
    permission: [Roles.ADMIN],
    title: "Organizations"
  }
]