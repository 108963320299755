import { Box, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import '../../home/text-magic.css'

export const Hero = () => {
  const translation = useBreakpointValue({ base: "B2B.AboutUs.Hero.TitleMobile", lg: "B2B.AboutUs.Hero.Title" })
  const { t } = useTranslation()
  return (
    <Box my={{ base: "32", lg: "24" }} textAlign={{ base: "center", lg: "left" }} mb="0" p={{ base: "4", lg: 0 }}>
      <Heading as="h1" variant={"pageTitle"} maxW={{ base: "100%", lg: "80%" }} fontSize={{base: "4xl", md: "5xl"}}>
        <Trans i18nKey={translation}>
          placeholder <span className='magic'></span>
        </Trans>
      </Heading>

      <Text as="p" fontSize={{ base: 'larger' }} mt={{ base: "10", lg: "8" }} maxW={{ base: "100%", lg: "70%" }} color="white">
        {t("B2B.AboutUs.Hero.Description1")}
      </Text>
      <Text as="p" fontSize={{ base: 'larger' }} mt={{ base: "10", lg: "8" }} maxW={{ base: "100%", lg: "70%" }} color="white">
        {t("B2B.AboutUs.Hero.Description2")}
      </Text>
    </Box >
  )
}