import * as React from 'react'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { Carousel, CarouselSlide, useCarousel } from './Carousel'
import { useEffect } from 'react'
import {
  AspectRatio,
  Box,
  Circle,
  Flex,
  HStack,
  LayoutProps,
  Stack,
  StackProps,
} from '@chakra-ui/react'

interface GalleryProps {
  items: React.ReactNode[]
  aspectRatio: number
  hideOverflowItems?: boolean
  height?: LayoutProps["height"]
  rootProps?: StackProps
  startingIndex?: number
}

export const Gallery = (props: GalleryProps) => {
  const { items, aspectRatio, rootProps, hideOverflowItems = false } = props
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    slides: {
      perView: 1,
      spacing: 30,
    }
  })

  const hasPrevious = currentSlide !== 0
  const hasNext = currentSlide < items.length - 1

  useEffect(() => {
    if (props.startingIndex) {
      slider.current?.moveToIdx(props.startingIndex)
    }
  }, [])

  return (
    <Stack spacing="4" {...rootProps}>
      <Box
        overflow={hideOverflowItems ? "hidden" : "visible"}
      >
        <Carousel ref={ref}>
          {items.map((item, i) => (
            <CarouselSlide key={i}>
              <AspectRatio
                transition="all 200ms"
                ratio={aspectRatio}
                height={props.height}
              >
                {item}
              </AspectRatio>
            </CarouselSlide>
          ))}
        </Carousel>

        <HStack width="full" justify="center" py="4" position="absolute" right="0" bottom="-10">
          <Flex display={{ base: "flex", lg: "none" }} className="circles">
            {items.map((_, index) => (
              <Circle key={index} size="2" mr={2} bg={currentSlide === index ? "#38FFEF" : '#55579E'} />
            ))}
          </Flex>
          <Box display={{ base: "none", lg: "block" }} position="absolute" right="0">
            <ArrowBackIcon
              visibility={!hasPrevious ? "hidden" : "visible"}
              onClick={() => slider.current?.prev()}
              aria-label="Previous Slide"
              color="#6A77FF"
              width={8}
              height={8}
              zIndex={2}
              _hover={{
                cursor: "pointer",
              }}
            />
            <ArrowForwardIcon
              visibility={!hasNext ? "hidden" : "visible"}
              onClick={() => slider.current?.next()}
              aria-label="Next Slide"
              color="#6A77FF"
              width={8}
              height={8}
              zIndex={2}
              _hover={{
                cursor: "pointer",
              }}
            />
          </Box>
        </HStack>
      </Box>
    </Stack>
  )
}
