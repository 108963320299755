export enum Roles {
  ADMIN = "admin",
  CUSTOMER = "customer",
  ORGANIZATIONMANAGER = "organizationmanager",
}

export const RolesDashboards = [
  Roles.ADMIN,
  Roles.ORGANIZATIONMANAGER,
];

export const RolesUsers = [Roles.CUSTOMER]