import posterImage from '../../../../assets/Carousel.png'
import React from 'react'
import { AspectRatio, Box, Flex, Heading, LinkBox, LinkOverlay, Text, useBreakpointValue } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

export const PromoVideo = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Flex width={boxWidth} flexFlow="row wrap" justifyContent="space-between" alignItems="center" backgroundColor="bgSecondary" py={10} px={{ base: 6, md: 16 }}>
      <Box flexBasis={{ base: "100%", md: "30%" }} textAlign={{ base: "center", md: "left" }}>
        <Heading as="h2" color="white" fontSize={{ base: "3xl", md: "4xl" }} mb={6}>
          <Trans i18nKey="B2B.Home.PromoVideo.Title">
            Placeholder <span className="magic"></span>
          </Trans>
        </Heading>
        <Text as="p" color="white" fontSize={{ base: "md", md: "lg" }} mb={{ base: 10, md: 0 }}>
          {t("B2B.Home.PromoVideo.Description")} <br /> <br />
          <a href="https://Ciphers.me/" rel='noreferrer' target='_blank'>Ciphers.me</a>
        </Text>
      </Box>
      <AspectRatio flexBasis={{ base: "100%", md: "65%" }} ratio={16 / 9}>
        <LinkBox position="relative" width="100%" height="100%" bgGradient="linear(to-r, #8721FF, #FF36AD)" p={1}>
          <LinkOverlay href="https://Ciphers.me/" target='_blank'>
            <img src={posterImage} alt="poster" width="100%" />
          </LinkOverlay>
        </LinkBox>
      </AspectRatio>
    </Flex>
  );
};
