import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, useColorModeValue } from '@chakra-ui/react'
import { FaqQuestionForm } from '../../../../components/secure/dashboard/faq/FaqQuestionForm'
import { history } from '../../../../ApplicationRouter'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const CreateFaqQuestion = () => {
  const { t } = useTranslation();
  let { id } = useParams();

  return (
    <AutoWidthCenter bg={useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Faq_Question.Create')}</Heading>
          <Button onClick={() => history.back()}>{t('Return')}</Button>
        </HStack>
        <Divider />
        <FaqQuestionForm categoryId={id} />
      </Box>
    </AutoWidthCenter >
  )
}