import { FormControl as ChakraFormControl, FormControlProps, FormErrorMessage, FormErrorMessageProps, FormHelperText, FormLabel, FormLabelProps, HelpTextProps } from '@chakra-ui/react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

export interface BaseProps extends Omit<FormControlProps, 'label'> {
  name: string;
  label?: React.ReactNode;
  labelProps?: FormLabelProps;
  helperText?: React.ReactNode;
  helperTextProps?: HelpTextProps;
  errorMessageProps?: FormErrorMessageProps;
}

export const FormControl: React.FC<BaseProps> = (props: BaseProps) => {
  const { t } = useTranslation();
  const {
    children,
    name,
    label,
    labelProps,
    helperText,
    helperTextProps,
    errorMessageProps,
    isRequired,
    ...rest
  } = props;
  const [, { error, touched, value }] = useField(name);

  // We add a custom check for when there is an error, the field is required, but no value has been provided by the user.
  // This is done to prevent the default error message, which can't be translated
  let customError = "";
  if (error) customError = t(error) // Seperated to prevent translations of undefined
  if (error && isRequired && value === "") {
    customError = t("yup.mixed.required")
  }

  return (
    <ChakraFormControl isInvalid={!!error && touched} isRequired={isRequired} {...rest}>
      {label && typeof label === 'string' ? (
        <FormLabel htmlFor={name} {...labelProps}>
          {label}
        </FormLabel>
      ) : (
        label
      )}
      {children}
      {customError && (
        <FormErrorMessage {...errorMessageProps}>{customError}</FormErrorMessage>
      )}
      {helperText && typeof helperText === 'string' ? (
        <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
      ) : (
        helperText
      )}
    </ChakraFormControl>
  );
};

export default FormControl;