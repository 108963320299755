import { BaseProps, FormControl } from '../formik-chakra'
import { Button, ButtonProps, InputGroup } from '@chakra-ui/react'
import { ChangeEvent, ForwardedRef, forwardRef, useRef, useState } from 'react'
import { EditIcon } from '@chakra-ui/icons'
import { useField } from 'formik'

type FileUploadProps = {
  accept?: string
  multiple?: boolean
  image?: any;
}

export type FileControlProps = BaseProps & FileUploadProps & { buttonProps?: ButtonProps };

export const FileControl: React.FC<FileControlProps> = forwardRef(
  (props: FileControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, label, buttonProps, multiple = false, accept = 'image', ...rest } = props
    const [{ onChange, ...field }, , { setValue }] = useField(name)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [newImage, setNewImage] = useState<any>("");

    const handleClick = () => {
      inputRef.current?.click()
    }

    const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
      value.target.files && setValue(value.target.files?.[0])
      value.target.files && setNewImage(value.target.files?.[0])
    }

    // TODO: preview fixen zodra files goed opgehaald en opgeslagen worden
    const preview = newImage
      ? <img className="preview" src={newImage} alt="preview" />
      : props.image
        ? <img className="preview" src={props.image} alt="preview" />
        : <></>

    return (
      <FormControl name={name} label={label} {...rest} {...ref}>
        <InputGroup onClick={handleClick}>
          <input
            onChange={handleChange}
            type='file'
            accept={accept}
            multiple={multiple}
            id={name}
            ref={inputRef}
            hidden />
          <Button w={'full'} variant={'secondary'} {...buttonProps} {...field} leftIcon={<EditIcon />} isLoading={false} paddingX={24}>
            {'Upload_Image'}
          </Button>
        </InputGroup>
        {preview}
      </FormControl>
    )
  }
)

export default FileControl