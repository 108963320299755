import React from 'react'
import { Center, SystemStyleObject } from '@chakra-ui/react'

export enum ArrowPointing {
  Top,
  Right,
  Bottom,
  Left
}

type Props = {
  errorMessage: string;
  arrowPointing?: ArrowPointing
}

//calculate the color between 2 colors.
// https://stackoverflow.com/questions/14482226/how-can-i-get-the-color-halfway-between-two-colors
export const ErrorMessage = ({ errorMessage, arrowPointing }: Props) => {
  const calculateColor = (c1: string, c2: string) => {
    let c = "#";
    for (let i = 0; i < 3; i++) {
      const sub1 = c1.substring(1 + 2 * i, 3 + 2 * i);
      const sub2 = c2.substring(1 + 2 * i, 3 + 2 * i);
      const v1 = parseInt(sub1, 16);
      const v2 = parseInt(sub2, 16);
      const v = Math.floor((v1 + v2) / 2);
      const sub = v.toString(16).toUpperCase();
      const padsub = ('0' + sub).slice(-2);
      c += padsub;
    }

    return c
  }

  let pseudoElement: SystemStyleObject = {}
  let pseudoElementBase: SystemStyleObject = {
    content: '""',
    position: "absolute",
    width: 0,
    height: 0,
  }

  switch (arrowPointing) {
    case ArrowPointing.Top:
      pseudoElement = {
        ...pseudoElementBase,
        bottom: "100%",
        left: "50%",
        borderBottom: `15px solid ${calculateColor("#ff36ad", "#FF165F")}`,
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        transform: "translateX(-50%)"
      }
      break;
    case ArrowPointing.Right:
      pseudoElement = {
        ...pseudoElementBase,
        left: "100%",
        top: "50%",
        borderLeft: "15px solid #FF36AD",
        borderTop: "10px solid transparent",
        borderBottom: "10px solid transparent",
        transform: "translateY(-50%)"
      }
      break;
    case ArrowPointing.Bottom:
      pseudoElement = {
        ...pseudoElementBase,
        top: "100%",
        left: "50%",
        borderTop: `15px solid ${calculateColor("#ff36ad", "#FF165F")}`,
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        transform: "translateX(-50%)"
      }
      break;
    case ArrowPointing.Left:
      pseudoElement = {
        ...pseudoElementBase,
        right: "100%",
        top: "50%",
        borderRight: "15px solid #FF36AD",
        borderTop: "10px solid transparent",
        borderBottom: "10px solid transparent",
        transform: "translateY(-50%)"
      }
      break;
    default:
      pseudoElement = {}
  }

  return (
    <Center
      position="relative"
      width="100%"
      height="100%"
      color="white"
      px={2}
      py={{base:2, md: 0}}
      bgGradient='linear(to-r, ciphers.persianRose, ciphers.errorBackground)'
      borderRadius={8}
      _after={pseudoElement}
    >
      {errorMessage}
    </Center>
  )
}
