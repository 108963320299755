import Concordium from '../../../../assets/b2b/about-us/ConcordiumCompany.png'
import ConcordiumLogo from '../../../../assets/b2b/shared/logos/Concordium-logo.png'
import ProxId from '../../../../assets/b2b/about-us/ProxIdCompany.png'
import ProxIdLogo from '../../../../assets/b2b/shared/logos/Proxid-logo.svg'
import RJE from '../../../../assets/b2b/about-us/RJECompany.jpg'
import RJELogo from '../../../../assets/b2b/shared/logos/RJE-logo.svg'
import { AspectRatio, Box, VStack } from '@chakra-ui/react'
import { Company } from '../../../../components/b2b/Company'
import { useTranslation } from 'react-i18next'

export const Companies = () => {
  const { t } = useTranslation()
  return (
    <VStack as="section" id="companies" spacing={20} mb={20} mx={{ base: 4, lg: 0 }}>
      <Company
        companyLogo={ProxIdLogo}
        company='PROxID'
        description={t("B2B.AboutUs.Companies.PROxIDDescription")}
        image={ProxId}
        companySite="https://proxid.nl/"
        aspectRatio={
          <AspectRatio
            display={{ base: "none", lg: "block" }}
            opacity=".5"
            zIndex={-1}
            width="100%"
            ratio={1 / 1}
            left="70%"
            top="40%"
            transform="translate(-50%, -50%)"
            position="absolute"
            filter="blur(30px)"
            _after={{
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              transform: "scale(1)",
              clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
              bgGradient: 'linear(to-br, #8721FF, #38FFEF)'
            }}
          >
            {/* Add empty Box because AspectRatio needs at least one child */}
            <Box></Box>
          </AspectRatio>
        }
      />
      <Company
        companyLogo={ConcordiumLogo}
        company='Concordium'
        description={t("B2B.AboutUs.Companies.ConcordiumDescription")}
        image={Concordium}
        companySite="https://concordium.com/"
        reverse
        aspectRatio={
          <AspectRatio
            display={{ base: "none", lg: "block" }}
            opacity=".5"
            zIndex={-1}
            width="100%"
            ratio={1 / 1}
            left="30%"
            top="40%"
            transform="translate(-50%, -50%)"
            position="absolute"
            filter="blur(30px)"
            _after={{
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              transform: "scale(1)",
              clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
              bgGradient: 'linear(to-r, #8721FF 70%, #38FFEF)'
            }}
          >
            {/* Add empty Box because AspectRatio needs at least one child */}
            <Box></Box>
          </AspectRatio>
        }
      />
      <Company
        companyLogo={RJELogo}
        company='Royal Joh. Enschedé'
        description={t("B2B.AboutUs.Companies.RJEDescription")}
        companySite="https://www.joh-enschede.nl/"
        image={RJE}
        aspectRatio={
          <AspectRatio
            display={{ base: "none", lg: "block" }}
            opacity=".4"
            zIndex={-1}
            width="100%"
            ratio={1 / 1}
            left="70%"
            top="40%"
            transform="translate(-50%, -50%)"
            position="absolute"
            filter="blur(30px)"
            _after={{
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              transform: "scale(1)",
              clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
              bgGradient: 'linear(to-br, #FF36AD, #FF36AD)'
            }}
          >
            {/* Add empty Box because AspectRatio needs at least one child */}
            <Box></Box>
          </AspectRatio>
        }
      />
    </VStack>
  )
}