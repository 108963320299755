import { AiOutlineBell } from 'react-icons/ai'
import { AutoWidthCenter } from '../../components/layout/AutoWidthCenter'
import { Box, Button, Container, Divider, Flex, HStack, Icon, Spinner, Stack, Tab, TabList, Tabs, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import { CgProfile } from 'react-icons/cg'
import { GetSimpleCustomerProfileQuery } from '../../api/queries/user/GetProfileQuery'
import { MdOutlineVisibility } from 'react-icons/md'
import { NavLink, useSearchParams } from 'react-router-dom'
import { ProfileForm } from '../../components/Profile/Settings/ProfileForm'
import { routePrefixCustomer } from '../../ApplicationRouter'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { VisibilityForm } from '../../components/Profile/Settings/VisibilityForm'

export const EditCustomer = () => {
  const { t } = useTranslation();
  const isDesktop = useBreakpointValue({ base: false, md: true })
  const backGroundColor = useColorModeValue("bgSecondaryWhite", "bgSecondaryBlack");
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab");
  const { data } = useQuery(['profile', 'customer-simple'], GetSimpleCustomerProfileQuery)
  const tabColor = useColorModeValue("bgPrimaryBlack", "bgPrimaryWhite");

  const renderSwitch = () => {
    switch (selectedTab) {
      case 'profile':
        return (
          <ProfileForm customer={data} />
        );
      case 'notifications':
        return (
          <>Notifications form</> //TODO: https://github.com/auxilium/KJE-NFT/issues/122
        )
      case 'visibility':
        return (
          <VisibilityForm customer={data} />
        )
      default:
        return (
          <ProfileForm customer={data} />
        );
    }
  }

  return (
    <AutoWidthCenter bg={backGroundColor}>
      <Flex direction={isDesktop ? "row" : "column"} as="section" mt={isDesktop ? "10" : undefined} bgColor={useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack")} minH="50vh" height="auto" borderRadius={isDesktop ? "14px 14px 0px 0px" : undefined} >
        {isDesktop ?
          <>
            <Box>
              <Flex
                flex="1"
                bg="bg-accent"
                color="on-accent"
                maxW={{ base: 'full', sm: 'xs' }}
                py={{ base: '6', sm: '8' }}
                px={{ base: '4', sm: '6' }}
              >
                <Stack justify="space-between" spacing="1">
                  <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
                    <Text as="h3">{t("Settings.Settings").toUpperCase()}</Text>
                    <Stack spacing="1">
                      <Button as={NavLink} to={`${routePrefixCustomer}/edit?tab=profile`} variant="ghost-on-accent" justifyContent="start">
                        <HStack spacing="3">
                          <Icon as={CgProfile} boxSize="6" color="on-accent-subtle" />
                          <Text color="on-accent-subtle">{t("Customer.Profile.Profile")}</Text>
                        </HStack>
                      </Button>
                      <Button as={NavLink} to={`${routePrefixCustomer}/edit?tab=notifications`} variant="ghost-on-accent" justifyContent="start">
                        <HStack spacing="3">
                          <Icon as={AiOutlineBell} boxSize="6" color="on-accent-subtle" />
                          <Text color="on-accent-subtle">{t("Customer.Notifications")}</Text>
                        </HStack>
                      </Button>
                      <Button as={NavLink} to={`${routePrefixCustomer}/edit?tab=visibility`} variant="ghost-on-accent" justifyContent="start">
                        <HStack spacing="3">
                          <Icon as={MdOutlineVisibility} boxSize="6" color="on-accent-subtle" />
                          <Text color="on-accent-subtle">{t("Customer.Visibility")}</Text>
                        </HStack>
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Flex>
            </Box>
            <Divider orientation="vertical" />
          </>
          :
          <Box as="section" bg="bg-surface">
            <Container py={{ base: '4', md: '8' }}>
              <Stack>
                <Tabs align='center' colorScheme={tabColor}>
                  <TabList>
                    <Tab color={tabColor} as={NavLink} to={`${routePrefixCustomer}/edit?tab=profile`}>{t("Customer.Profile.Profile")}</Tab>
                    <Tab color={tabColor} as={NavLink} to={`${routePrefixCustomer}/edit?tab=notifications`}>{t("Customer.Notifications")}</Tab>
                    <Tab color={tabColor} as={NavLink} to={`${routePrefixCustomer}/edit?tab=visibility`}>{t("Customer.Visibility")}</Tab>
                  </TabList>
                </Tabs>
              </Stack>
            </Container>
          </Box>
        }
        <Box
          bgColor={useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack")}
          flex="1"
          p="6"
          borderRadius="0px 14px 0px 0px"
          textAlign={data ? undefined : "center"}
          alignSelf={data ? undefined : "center"}
        >
          {data ? renderSwitch() : <Spinner />}
        </Box>
      </Flex>
    </AutoWidthCenter>
  )
}