import { Avatar, AvatarProps, Box, Flex, FlexProps, useColorModeValue } from '@chakra-ui/react'

interface CardWithAvatarProps extends FlexProps {
  avatarProps: AvatarProps
  action?: React.ReactNode
}

export const CardWithAvatar = (props: CardWithAvatarProps) => {

  const { action, avatarProps, children, ...rest } = props

  return (
    <Flex
      position="relative"
      direction="column"
      align={{ sm: 'center' }}
      mx="auto"
      bg={useColorModeValue('bgPrimaryWhite', 'bgPrimaryBlack')}
      shadow={{ sm: 'base' }}
      borderRadius={{ md: "14px 14px 0px 0px" }}
      px={{ base: '6', md: '8' }}
      pb={{ base: '4', md: '4' }}
      {...rest}
    >
      <Avatar
        mt="-10"
        borderWidth="6px"
        borderColor={useColorModeValue('bgSecondaryWhite', 'bgSecondaryBlack')}
        size={{ base: "xl", md: "2xl" }}
        {...avatarProps}
      />
      <Box position="absolute" top="4" insetEnd={{ base: '6', md: '8' }}>
        {action}
      </Box>
      {children}
    </Flex>
  )
}