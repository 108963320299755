import { Badge, LightMode } from '@chakra-ui/react'
import { ILinkGroup } from '../../../models/routes/ILinkGroup'

export const links: ILinkGroup[] = [
  {
    title: 'By Company',
    links: [
      {
        label: 'Poste Italiane',
        href: '#',
        badge: (
          <LightMode>
            <Badge colorScheme="blue" fontSize="0.625rem">
              NEW!
            </Badge>
          </LightMode>
        ),
      },
      { label: 'Posti Finland', href: '#' },
      { label: 'United States Postal Service', href: '#' },
      { label: 'Deutsche Post', href: '#' },
      { label: 'Congolese Post Company', href: '#' },
    ],
  },
  // {
  //   title: 'By Category',
  //   links: [
  //     { label: 'Stamps', href: '#' },
  //     { label: 'Banknotes', href: '#' },
  //     { label: 'Tickets', href: '#' },
  //   ],
  // },
]