import { AboutUsB2B } from '../../pages/b2b/aboutUs/AboutUsB2B'
import { ClaimCode } from '../../pages/b2b/claim/ClaimCode'
import { CollectorsB2B } from '../../pages/b2b/collectors/CollectorsB2B'
import { Contact } from '../../pages/b2b/contact/Contact'
import { HomeB2BNew } from '../../pages/b2b/home/HomeB2BNew'
import { IRoute } from '../../models/routes/IRoute'
import { PostalAction } from '../../pages/b2b/postalAction/PostalAction'
import { PrivacyPolicy } from '../../pages/b2b/privacyPolicy/PrivacyPolicy'

// Define as const for type safety
export const RoutesB2BConst = [
  {
    element: <HomeB2BNew />,
    path: "/",
    index: true,
    title: "Home"
  },
  {
    element: <AboutUsB2B />,
    path: "/about-us",
    title: "About Us"
  },
  {
    element: <CollectorsB2B />,
    path: "/collectors",
    title: "Collectors"
  },
  {
    element: <HomeB2BNew />,
    path: "/postal-organisations",
    title: "Postal Organisations"
  },
  {
    element: <Contact />,
    path: "/contact",
    title: "Contact"
  },
  {
    element: <Contact />,
    path: "/contact/:hideButton",
    title: "Contact"
  },
  {
    element: <PostalAction />,
    path: "/postal-action/:claimCode",
    title: "Postal action"
  },
  {
    element: <PostalAction />,
    path: "/postal-action/",
    title: "Postal action"
  },
  {
    element: <ClaimCode />,
    path: "/claim",
    title: "Claim code"
  },
  {
    element: <PrivacyPolicy />,
    path: "/privacy-policy",
    title: "Privacy policy"
  }
] as const;

export const RoutesB2B: IRoute[] = (RoutesB2BConst as any);