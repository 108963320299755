import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, Spinner, useColorModeValue } from '@chakra-ui/react'
import { FaqQuestionTable } from '../../../../components/secure/dashboard/faq/FaqQuestionTable'
import { GetFaqCategoryQuery } from '../../../../api/queries/faq/FaqQueries'
import { NavLink, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

export const FaqQuestions = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(['faq/category', id], () => GetFaqCategoryQuery(id!), { enabled: !!id });

  return (
    <AutoWidthCenter bg={useColorModeValue("bgTertiaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Faq_Question.Faq_Question')} {data?.translation.name}</Heading>
          <Button as={NavLink} to="new" >{t('Faq_Question.Create')}</Button>
        </HStack>
        <Divider />
        {isLoading ? <Spinner /> : (
          (data === undefined || data?.questions === undefined)
            ? <Heading as="h1">{t("Dataview.Default_Empty_Message")}</Heading>
            : <FaqQuestionTable faqQuestions={data.questions} />
        )}
      </Box>
    </AutoWidthCenter >
  )
}