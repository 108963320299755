import { apiClient } from '../../base/ApiClients'
import { useMutation } from 'react-query'
import { UserRegistrationFormValues } from '../../../models/user/IUserRegistration'

export async function customerRegister(values: UserRegistrationFormValues) {
  return await apiClient.post('/customer', values);
}

export const useCustomerRegisterMutation = () => useMutation(customerRegister, {

});