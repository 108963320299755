import { CreateSerie } from '../../pages/secure/dashboard/serie/CreateSerie'
import { EditSerie } from '../../pages/secure/dashboard/serie/EditSerie'
import { IRoute } from '../../models/routes/IRoute'
import { Roles } from '../../enums/Roles'
import { Series } from '../../pages/secure/dashboard/serie/Series'

export const SerieRoutes: IRoute[] = [
  {
    element: <Series />,
    path: "serie/:id",
    permission: [Roles.ADMIN, Roles.ORGANIZATIONMANAGER],
    title: "Series"
  },
  {
    element: <Series />,
    path: "serie",
    permission: [Roles.ADMIN, Roles.ORGANIZATIONMANAGER],
    showInMenu: true,
    title: "Series"
  },
  {
    element: <CreateSerie />,
    path: "serie/new",
    permission: [Roles.ADMIN],
    title: "Series"
  },
  {
    element: <CreateSerie />,
    path: "serie/:id/new",
    permission: [Roles.ADMIN],
    title: "Series"
  },
  {
    element: <EditSerie />,
    path: "serie/edit/:id",
    permission: [Roles.ADMIN],
    title: "Series"
  },
]