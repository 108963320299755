export enum ESerieType {
  PostalStamp = 1,
}

export interface ISerieTypeEnum {
  serieType: ESerieType,
  name: string
}

const SerieTypeItem = (serieType : ESerieType, name: string) : ISerieTypeEnum => ({
  serieType,
  name
})

export const SerieTypeEnums = (): ISerieTypeEnum[] => {
  let list: ISerieTypeEnum[] = [];
  list.push(SerieTypeItem(ESerieType.PostalStamp, ESerieType[ESerieType.PostalStamp]))

  return list;
}