import FileControl from '../../../common/file-upload/FileControl'
import { Box, Button, Flex, useColorModeValue, useToast, VStack } from '@chakra-ui/react'
import { DashboardSerieFormValues, IDashboardSerie } from '../../../../models/Serie/ISerie'
import { DropdownControl, InputControl } from '../../../common/formik-chakra'
import { Form, Formik } from 'formik'
import { GetDashboardOrganizationsQuery } from '../../../../api/queries/organization/OrganizationQueries'
import { history } from '../../../../ApplicationRouter'
import { SerieTypeEnums } from '../../../../enums/ESerieType'
import { useCreateSerieMutation, useEditSerie } from '../../../../api/queries/serie/SerieQueries'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { validationSchema } from './Serie.Validation'

interface IProps {
  serie?: IDashboardSerie;
  organizationId?: string;
}

export const SerieForm = (props: IProps) => {
  const { t } = useTranslation();
  const boxBackground = useColorModeValue("bgSecondaryWhite", "bgSecondaryBlack");
  const [formValues, setFormValues] = useState(new DashboardSerieFormValues());
  const [serieTypeOptions, setSerieTypeOptions] = useState<any[]>([]);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<any[]>([]);
  const handleCreateSerie = useCreateSerieMutation();
  const handleEditSerie = useEditSerie();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data: organizations, error, isError, isLoading } = useQuery(['organizations'], GetDashboardOrganizationsQuery);

  useEffect(() => {
    if (props.serie) setFormValues(new DashboardSerieFormValues(props.serie));
    setSerieTypeOptions(SerieTypeEnums());
    if (organizations) {
      const options: any = organizations?.map((organization) => {
        return { key: organization.id, value: organization.id, name: organization.name }
      })
      setOrganizationTypeOptions(options);
    }
  }, [organizations])

  const handleSubmit = async (values: any) => {
    if (props.organizationId)
      values.organizationId = props.organizationId;

    if (props.serie) {
      handleEditSerie.mutate({ id: props.serie.id, formValues: values }, {
        onSuccess() {
          queryClient.invalidateQueries(['series'])
          history.back();
          toast({
            title: t("Series.Series"),
            description: t("Series.Toast.Edit_Success"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Series.Series"),
            description: t("Series.Toast.Edit_Error"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    } else {
      handleCreateSerie.mutate(values, {
        onSuccess() {
          queryClient.invalidateQueries(['series'])
          history.back();
          toast({
            title: t("Series.Series"),
            description: t("Series.Toast.Create_Success"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Series.Series"),
            description: t("Series.Toast.Create_Error"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }

  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Box bgColor={boxBackground} rounded="lg" p="10" m="6" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                {!props.organizationId && !props.serie &&
                  <>
                    <DropdownControl
                      name="organizationId"
                      label="Organizations"
                      selectProps={{ placeholder: t("Dropdown.Select_Organization") }}
                      options={organizationTypeOptions?.map((organization: any) => {
                        return {
                          key: organization.key,
                          value: organization.value,
                          name: organization.name
                        }
                      })} />
                  </>
                }
                <InputControl name="translation.title" label="Title" />
                <InputControl name="translation.description" label="Description" />
                <DropdownControl
                  name="serieType"
                  label="SerieType"
                  selectProps={{ placeholder: t("Dropdown.Select_Serie_Type") }}
                  options={serieTypeOptions.map((serieType) => {
                    return {
                      key: serieType.serieType,
                      value: serieType.serieType,
                      name: serieType.name
                    }
                  })} />
                <FileControl name="bannerImage" label="bannerImage" />
                <FileControl name="smallImage" label="smallImage" />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">{t("Submit")}</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex >
  )
}