import React from 'react'
import { Action } from '../../../../components/b2b/Action'
import { Box, Center, Heading, Icon, useBreakpointValue } from '@chakra-ui/react'
import { ConversionExchange, CryptoCurrency, EmailStamp, OpenBookCursorSquare } from '../../../../assets/icons/components'
import { useTranslation } from 'react-i18next'

export const Actions = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Center as='section' id='actions'>
      <Box width={boxWidth}>
        <Heading as="h2" fontSize="2xl" mb={8} color="#6A77FF" textAlign={{ base: "center", md: "left" }}>{t("B2B.Home.Actions.Title")}</Heading>
        <Box display="flex" flexFlow="row wrap" justifyContent="space-between">
          <Action
            icon={<Icon as={EmailStamp} color="ciphers.electricViolet" w={14} h={14} mr="auto" mb={{base: 4, md: 10, lg: 20}} />}
            header={t("B2B.Home.Actions.1.Title")}
            text={t("B2B.Home.Actions.1.Description")}
            flexBasis={{ base: "100%", md: "48%", lg: "23%" }}
          />
          <Action
            icon={<Icon as={CryptoCurrency} color="ciphers.cornflowerBlue" w={14} h={14} mr="auto" mb={{base: 4, md: 10, lg: 20}} />}
            header={t("B2B.Home.Actions.2.Title")}
            text={t("B2B.Home.Actions.2.Description")}
            flexBasis={{ base: "100%", md: "48%", lg: "23%" }}
          />
          <Action
            icon={<Icon as={OpenBookCursorSquare} color="ciphers.frenchPass" w={14} h={14} mr="auto" mb={{base: 4, md: 10, lg: 20}} />}
            header={t("B2B.Home.Actions.3.Title")}
            text={t("B2B.Home.Actions.3.Description")}
            flexBasis={{ base: "100%", md: "48%", lg: "23%" }}
          />
          <Action
            icon={<Icon as={ConversionExchange} color="ciphers.main.green" w={14} h={14} mr="auto" mb={{base: 4, md: 10, lg: 20}} />}
            header={t("B2B.Home.Actions.4.Title")}
            text={t("B2B.Home.Actions.4.Description")}
            flexBasis={{ base: "100%", md: "48%", lg: "23%" }}
          />
        </Box>
      </Box>
    </Center>
  )
}
