import React from 'react'
import { ArrowPointing, ErrorMessage } from './ErrorMessage'
import { Box, Flex, Input, Text, useBreakpointValue } from '@chakra-ui/react'
import { FieldProps, getIn } from 'formik'
import { useTranslation } from 'react-i18next'

type Props = {
  label: string
  errorMessage?: string;
} & FieldProps<string, HTMLInputElement>

export const InputField = ({ field, label, form, errorMessage }: Props) => {
  const { t } = useTranslation()
  const { errors, touched } = form
  const hasError = getIn(touched, field.name) && getIn(errors, field.name)
  const arrowDirection = useBreakpointValue({ base: ArrowPointing.Top, md: ArrowPointing.Left })

  return (
    <Flex flexFlow="row wrap" justifyContent="space-between">
      <Box px={2} py={1} borderRadius={8} backgroundColor="white" flexBasis={{ base: "100%", md: "67%" }} border={hasError ? "2px solid #E60066" : "none"} >
        <Text as="label" fontSize="sm" htmlFor={field.name} color={hasError ? "#E60066" : "cornflowerBlue"}>{label}</Text>
        <Input size="sm" color="black" border="none" {...field} padding={0} _focusVisible={{ border: "none" }} />
      </Box>
      {hasError && (
        <Box my={{ base: 5, md: 0 }} flexBasis={{ base: "100%", md: "30%" }}>
          <ErrorMessage errorMessage={errorMessage || t(getIn(errors, field.name))} arrowPointing={arrowDirection} />
        </Box>
      )}
    </Flex>
  )
}