const serviceEvents = {
  downloadFinished: "BaseApiService:downloadFinished",
  getAvailableRoles: "RoleService:getAvailableRoles",
  newRoleSelected: "RoleService:selectRole",
  sessionLogout: "SessionGuard:sessionLogout",
  sessionWarning: "SessionGuard:sessionWarning",
  sessionStarted: "SessionGuard:sessionStarted",
};

export { serviceEvents as ServiceEvents };
