import * as Yup from 'yup'
import { PasswordValidation } from '../../utils/Validation'

export const RegistrationValidationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  emailRepeat: Yup.string().required().oneOf([Yup.ref("email"), null], "Registration.Error.Repeat_Email"),
  userName: Yup.string().required(),
  password: Yup.object().shape({
    password: Yup.string().required().matches(PasswordValidation, "Password.Rules"),
    confirmPassword: Yup.string().required().oneOf([Yup.ref("password"), null], "Registration.Error.Repeat_Password"),
  }),
  termsAndConditions: Yup.boolean().required().equals([true], "Registration.ToS.Required"),
})