import React from 'react'
import { Box, Button, Heading, Stack } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IProps {
  text: string | JSX.Element
  button1Url?: string
}

export const ContactSection = (props: IProps) => {
  const { t } = useTranslation();
  return (
    <Box as="section" id="contact" position="relative" maxW={{ base: "xl", md: "5xl" }} mx="auto" px={{ base: "6", md: "8" }} mt="44" zIndex={1}>
      <Box textAlign="center" mb={20}>
        <Heading as="h2" size="2xl" maxW="48rem" mx="auto" lineHeight="1.2" letterSpacing="tight" color="white">
          {props.text}
        </Heading>
      </Box>

      <Stack justify="center" direction={{ base: "column", md: "row" }} spacing="4" mb={48}>
        <Button as={NavLink} to={props.button1Url || "/contact"} variant="b2bPrimary"
        >
          {t("B2B.Contact.ButtonSecondary").toUpperCase()}
        </Button>
      </Stack>
    </Box>
  );
}
