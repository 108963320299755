import { AiOutlineDelete } from 'react-icons/ai'
import { BiArchive } from 'react-icons/bi'
import { Button, Heading, IconButton, Spinner, useToast } from '@chakra-ui/react'
import { DataView, IDataViewColumn } from '../../../common/dataview/Dataview'
import { FiEdit2 } from 'react-icons/fi'
import { GetDashboardSeries, GetDashboardSeriesById, useDeleteSerie } from '../../../../api/queries/serie/SerieQueries'
import { IDashboardSerie } from '../../../../models/Serie/ISerie'
import { NavLink } from 'react-router-dom'
import { RenderGuard } from '../../../common/guards/RenderGuard'
import { Roles } from '../../../../enums/Roles'
import { routePrefixDashboard } from '../../../../ApplicationRouter'
import { useEffect, useState } from 'react'
import { useModal } from '../../../../context/ModalContext'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

interface IProps {
  id?: string | undefined;
}

export const SerieTable = (props: IProps) => {
  const { t } = useTranslation();
  const handleDeleteSerie = useDeleteSerie();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { dispatch } = useModal();
  const [columns] = useState<IDataViewColumn[]>([
    { headerName: t("Dataview.Headers.Serie_Title"), field: "translation.title", flex: 1, tooltipField: "translation.title" },
    { headerName: t("Dataview.Headers.Serie_Description"), field: "translation.description", flex: 1, tooltipField: "translation.description" },
    { headerName: t("Dataview.Headers.Serie_Items"), field: "serieItemCount", flex: 0.5 },
    { headerName: t("Dataview.Headers.Serie_Is_Active"), field: "isActive", cellRenderer: "checkmark", flex: 0.5 },
    { headerName: t("Dataview.Headers.Serie_Date_Visible"), field: "dateVisible", cellRenderer: "renderdatetime", flex: 1 },
    { headerName: t("Dataview.Headers.Actions"), cellRenderer: "actions", flex: 0.5 }
  ]);

  const handleDelete = (id: string) => {
    handleDeleteSerie.mutate(id, {
      onSuccess() {
        queryClient.invalidateQueries(['series-dashboard'])
        dispatch({ type: 'close' })
        toast({
          title: t("Series.Series"),
          description: t("Series.Toast.Delete_Success"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: t("Series.Series"),
          description: t("Series.Toast.Delete_Error"),
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const hasId = props.id ? true : false
  const { data, isLoading, refetch } = useQuery(['series-dashboard'], hasId ? () => GetDashboardSeriesById(props.id) : GetDashboardSeries)

  useEffect(() => {
    refetch()
  }, [props.id]);

  const renderCellAction = (tableData: any) => {
    const serie: IDashboardSerie = tableData.data;
    return (
      <>
        {serie.serieItemCount > 0 &&
          <IconButton
            icon={<BiArchive fontSize="1.25rem" />}
            variant='ghost'
            aria-label={t("Dataview.Headers.Serie_Items")}
            as={NavLink}
            to={`${routePrefixDashboard}/serie-items/${serie.id}`}
          />
        }
        {!serie.isActive &&
          <RenderGuard roles={Roles.ADMIN}>
            <IconButton
              icon={<FiEdit2 fontSize="1.25rem" />}
              variant="ghost"
              aria-label="Edit serie"
              as={NavLink}
              to={`${routePrefixDashboard}/serie/edit/${serie.id}`}
            />
            <IconButton
              icon={<AiOutlineDelete fontSize="1.25rem" />}
              variant="ghost"
              aria-label="Delete serie"
              onClick={() => dispatch({
                type: 'open', payload: {
                  title: t('Series.Delete'),
                  bodyContent: t('Series.Are_You_Sure_Delete_Serie'),
                  footerContent:
                    <>
                      <Button mr={3} variant='ghost' onClick={() => handleDelete(serie.id)}>{t('Series.Delete')}</Button>
                      <Button onClick={() => dispatch({ type: 'close' })}>
                        {t('Close')}
                      </Button>
                    </>
                }
              })}
            />
          </RenderGuard>
        }
      </>
    )
  }

  if (isLoading) return <Spinner />
  if (!isLoading && !data) {
    return (
      <Heading as="h1">{t("Dataview.Default_Empty_Message")}</Heading>
    )
  }

  return (
    <DataView
      actionsRenderer={renderCellAction}
      columns={columns}
      data={data}
    />
  )
}
