import { BaseProps, FormControl } from './FormControl'
import { Flex, Switch, SwitchProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { useField } from 'formik'

export type SwitchControlProps = BaseProps & { switchProps?: SwitchProps };

export const SwitchControl: React.FC<SwitchControlProps> = forwardRef(
  (props: SwitchControlProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { name, label, switchProps, ...rest } = props;
    const [field, { error, touched }] = useField(name);

    return (
      <FormControl
        name={name}
        label={label}
        as={Flex}
        alignItems="center"
        {...rest}
      >
        <Switch
          {...field}
          id={name}
          isInvalid={!!error && touched}
          isChecked={field.value}
          ref={ref}
          {...switchProps}
        />
      </FormControl>
    );
  }
);

export default SwitchControl;