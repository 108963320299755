import { AxiosError } from 'axios'
import { Center, Spinner, Text, useToast } from '@chakra-ui/react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidateMarketingCodeMutattion } from '../../../api/queries/b2b/useMarketingCode'
import { ValidateMarketingCodeFormValues } from '../../../models/b2b/MarketingCode'

export const ClaimCode = () => {
  const codeSubmit = useValidateMarketingCodeMutattion()
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation()
  let [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get("code")

  useEffect(() => {
    if (!code) return navigate("/")

    const valueToSubmit: ValidateMarketingCodeFormValues = {
      code: code,
    }

    codeSubmit.mutate(valueToSubmit, {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404) {
          toast({
            title: t("B2B.Home.Stamps.Error.Title"),
            description: t("B2B.Home.Stamps.Error.Description"),
            status: "error",
            colorScheme: "red",
            duration: 3 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      }
    })
  }, [code])

  if (codeSubmit.isLoading) {
    return (
      <Center backgroundColor="bgPrimary" height={"20rem"} flexDirection="column">
        <Spinner height="10rem" width={"10rem"} color="#4129ff" mb={12} />
        <Text fontSize="xl" color="whiteAlpha.900">We are validating your code...</Text>
      </Center>
    )
  }

  if (codeSubmit.isSuccess) {
    navigate(`/postal-action/${code}`)
  }

  const renderContent = () => {
    if (codeSubmit.isError) {
      return (
        <Center flexDirection="column" mt={{ base: "8rem", md: "12rem" }} px={8}>
          <Text as="p" fontSize="xl" color="whiteAlpha.900" textAlign="center" mb={4}>The provided code is not a valid code.</Text>
          <Text as="p" fontSize="xl" color="whiteAlpha.900" textAlign="center">If you want to receive a (new) code, please request on at <Link to="/contact">Contact</Link></Text>
        </Center>
      )
    } else {
      return (
        < Center backgroundColor="bgPrimary" height={"20rem"} flexDirection="column" >
          <Spinner height="10rem" width={"10rem"} color="#4129ff" mb={12} />
          <Text fontSize="xl" color="whiteAlpha.900">We are validating your code...</Text>
        </ Center>
      )
    }
  }

  return (
    renderContent()
  )
}
