import { ComponentSingleStyleConfig } from '@chakra-ui/react'

//The Toast component uses Alert under the hood. So this code below is for now only used for toasts.
export const alertStyles: ComponentSingleStyleConfig = {
  variants: {
    success: () => {
      return {
        container: {
          bg: "ciphers.main.green",
          color: "blackAlpha.800"
        },
      }
    }
  }
}
