import en_trans from './lang/en-GB.json'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { enGB } from 'date-fns/locale'
import { format as formatDate, isDate } from 'date-fns'
import { initReactI18next } from 'react-i18next'
import { setLocale } from 'yup'

const resources = { "en-GB": en_trans, "en": en_trans };

const locales: any = { enGB }; // Used to look up the required locale for date formatting

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: ["en-GB", "en"],
    supportedLngs: ["en-GB", "en"],
    cleanCode: true,
    nsSeparator: false,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      format: (value: any, format: any, lng: any) => {
        if (isDate(value)) {
          const locale = locales[lng];

          if (format === "date") return formatDate(value, "P", { locale })
          if (format === "dateTime") return formatDate(value, "Pp", { locale })

          return formatDate(value, format, { locale })
        } else {
          return value;
        }
      },
    }
  });

export const yupVal = {
  array: {
    max: (item: any) => i18n.t("yup.array.max", item),
    min: (item: any) => i18n.t("yup.array.min", item),
  },
  date: {
    max: (item: any) => i18n.t("yup.date.max", item),
    min: (item: any) => i18n.t("yup.date.min", item),
  },
  mixed: {
    default: (item: any) => i18n.t("yup.mixed.default", item),
    defined: (item: any) => i18n.t("yup.mixed.defined", item),
    notOneOf: (item: any) => i18n.t("yup.mixed.notOneOf", item),
    oneOf: (item: any) => i18n.t("yup.mixed.oneOf", item),
    required: (item: any) => i18n.t("yup.mixed.required", item),
  },
  number: {
    integer: (item: any) => i18n.t("yup.number.integer", item),
    lessThan: (item: any) => i18n.t("yup.number.lessThan", item),
    max: (item: any) => i18n.t("yup.number.max", item),
    min: (item: any) => i18n.t("yup.number.min", item),
    moreThan: (item: any) => i18n.t("yup.number.moreThan", item),
    negative: (item: any) => i18n.t("yup.number.negative", item),
    notEqual: (item: any) => i18n.t("yup.number.notEqual", item),
    positive: (item: any) => i18n.t("yup.number.positive", item),
  },
  object: {
    noUnknown: (item: any) => i18n.t("yup.object.noUnknown", item),
  },
  string: {
    email: (item: any) => i18n.t("yup.string.email", item),
    length: (item: any) => i18n.t("yup.string.length", item),
    lowercase: (item: any) => i18n.t("yup.string.lowercase", item),
    matches: (item: any) => i18n.t("yup.string.matches", item),
    max: (item: any) => i18n.t("yup.string.max", item),
    min: (item: any) => i18n.t("yup.string.min", item),
    trim: (item: any) => i18n.t("yup.string.trim", item),
    uppercase: (item: any) => i18n.t("yup.string.uppercase", item),
    url: (item: any) => i18n.t("yup.string.url", item),
  },
};

setLocale(yupVal);

export default i18n;