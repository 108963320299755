import { AiOutlineDelete } from 'react-icons/ai'
import { Button, Heading, IconButton, Spinner, useToast } from '@chakra-ui/react'
import { DataView, IDataViewColumn } from '../../../common/dataview/Dataview'
import { FaQuestionCircle } from 'react-icons/fa'
import { FiEdit2 } from 'react-icons/fi'
import { GetFaqCategoriesAndQuestions, useDeleteFaqCategoryMutation } from '../../../../api/queries/faq/FaqQueries'
import { IFaqCategory } from '../../../../models/faq/IFaq'
import { NavLink } from 'react-router-dom'
import { useModal } from '../../../../context/ModalContext'
import { useQuery, useQueryClient } from 'react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const FaqCategoryTable = () => {
  const handleDeleteFaqCategory = useDeleteFaqCategoryMutation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { dispatch } = useModal();
  const { t } = useTranslation();
  const [columns] = useState<IDataViewColumn[]>([
    { headerName: t("Dataview.Headers.Faq_Category_Name"), field: "translation.name", flex: 1, tooltipField: "translation.title" },
    { headerName: t("Dataview.Headers.Faq_Question_Count"), field: "questions.length", flex: 1 },
    { headerName: t("Dataview.Headers.Actions"), cellRenderer: "actions", flex: 0.5 }
  ])

  const handleDelete = (id: string) => {
    handleDeleteFaqCategory.mutate(id, {
      onSuccess() {
        queryClient.invalidateQueries(['faq/categories'])
        dispatch({ type: 'close' })
        toast({
          title: t("Faq_Category.Faq_Category"),
          description: t("Faq_Category.Toast.Delete_Success"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: t("Faq_Category.Faq_Category"),
          description: t("Faq_Category.Toast.Delete_Error"),
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const { data, isLoading } = useQuery(['faq/categories'], GetFaqCategoriesAndQuestions)

  const renderCellAction = (tableData: any) => {
    const faqCategory: IFaqCategory = tableData.data;
    return (
      <>
        <IconButton
          icon={<FaQuestionCircle fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Category questions"
          as={NavLink}
          to={`${faqCategory.id}/question`}
        />
        <IconButton
          icon={<FiEdit2 fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Edit category"
          as={NavLink}
          to={`category/edit/${faqCategory.id}`}
        />
        <IconButton
          icon={<AiOutlineDelete fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Delete faq category"
          onClick={() => dispatch({
            type: 'open', payload: {
              title: t('Faq_Category.Delete'),
              bodyContent: t('Faq_Category.Are_You_Sure_Delete_Serie'),
              footerContent:
                <>
                  <Button mr={3} variant='ghost' onClick={() => handleDelete(faqCategory.id)}>
                    {t('Faq_Category.Delete')}
                  </Button>
                  <Button onClick={() => dispatch({ type: 'close' })}>
                    {t('Close')}
                  </Button>
                </>
            }
          })}
        />
      </>
    )
  }

  if (isLoading) return <Spinner />
  if (!isLoading && !data) return <Heading as="h1">{t("Dataview.Default_Empty_Message")}</Heading>

  return (
    <DataView
      actionsRenderer={renderCellAction}
      columns={columns}
      data={data}
    />
  )
}