import React from 'react'
import { Actions } from './sections/Actions'
import { Benefits } from './sections/Benefits'
import { Box, VStack } from '@chakra-ui/react'
import { ContactSection } from '../../../components/b2b/ContactSection'
import { Examples } from './sections/Examples'
import { FeaturesSection } from '../../../components/b2b/FeaturesSection'
import { Hero } from './sections/Hero'
import { Organisations } from './sections/Organisations'
import { PromoVideo } from './sections/PromoVideo'
import { Questions } from './sections/Questions'
import { Roadmap } from './sections/Roadmap'
import { Stamps } from './sections/Stamps'
import { Trans } from 'react-i18next'

export const HomeB2BNew = () => {
  return (
    <Box backgroundColor="ciphers.stratos">
      <Hero />
      <VStack spacing={28}>
        <Actions />
        <Benefits />
        <Organisations />
        <FeaturesSection />
        <PromoVideo />
        <Stamps />
        <Roadmap />
        <Questions />
        <Examples />
        <ContactSection
          text={
            <Trans i18nKey="B2B.Contact.Title">
              Placeholder <span className='magic'></span>
            </Trans>
          } />
      </VStack >
    </Box>
  )
}
