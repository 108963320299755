import CryptoImage1 from '../../../../assets/b2b/home/example1.webp'
import CryptoImage1Mobile from '../../../../assets/b2b/home/example1-mobile.webp'
import CryptoImage2 from '../../../../assets/b2b/home/example2.webp'
import CryptoImage2Mobile from '../../../../assets/b2b/home/example2-mobile.webp'
import CryptoImage3 from '../../../../assets/b2b/home/example3.webp'
import CryptoImage3Mobile from '../../../../assets/b2b/home/example3-mobile.webp'
import CryptoImage4 from '../../../../assets/b2b/home/example4.webp'
import CryptoImage4Mobile from '../../../../assets/b2b/home/example4-mobile.webp'
import CryptoImage5 from '../../../../assets/b2b/home/example5.webp'
import CryptoImage5Mobile from '../../../../assets/b2b/home/example5-mobile.webp'
import CryptoImage6 from '../../../../assets/b2b/home/example6.webp'
import CryptoImage6Mobile from '../../../../assets/b2b/home/example6-mobile.webp'
import React from 'react'
import { Box, Text, useBreakpointValue } from '@chakra-ui/react'
import { Gallery } from '../../../../components/Gallery/Gallery'


type imageSrc = {
  desktop: string
  mobile: string
}

export const Examples = () => {
  const boxWidth = useBreakpointValue({ base: "100%%", md: "723px", lg: "933px", xl: "1127px" });
  const images: imageSrc[] = [
    {
      desktop: CryptoImage1,
      mobile: CryptoImage1Mobile
    },
    {
      desktop: CryptoImage2,
      mobile: CryptoImage2Mobile
    },
    {
      desktop: CryptoImage3,
      mobile: CryptoImage3Mobile
    },
    {
      desktop: CryptoImage4,
      mobile: CryptoImage4Mobile
    },
    {
      desktop: CryptoImage5,
      mobile: CryptoImage5Mobile
    },
    {
      desktop: CryptoImage6,
      mobile: CryptoImage6Mobile
    },
  ]
  // [CryptoImage1, CryptoImage2, CryptoImage3, CryptoImage4, CryptoImage5, CryptoImage6]

  return (
    <Box as='section' id='examples' maxWidth="100%">
      <Box width={boxWidth} mx="auto" position="relative">
        <Box
          width={{ base: "300px", md: "40rem" }}
          mb={12}
          sx={{
            '.circles': {
              display: "none"
            }
          }}
        >
          <Gallery
            aspectRatio={16 / 9}
            items={images.map((image, index) => (
              <picture>
                <source
                  srcSet={image.desktop}
                  //medium breakpoint see _breakpoints.ts
                  media="(min-width: 723px)"
                />
                <img
                  src={image.mobile}
                  alt={`Example of stamp ${index}`}
                />
              </picture>
            ))}
          />
          <Text position="absolute" bottom="-10" left="0" as="span" color="white">Examples of stamps we have created</Text>
        </Box>
      </Box>
    </Box >
  )
}
