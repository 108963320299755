import { Box, Spinner, useColorModeValue } from '@chakra-ui/react'
import { GetSerieAndNftsForCustomerQuery } from '../../../../api/queries/user/GetProfileQuery'
import { ProfileSerie } from './ProfileSerie'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

interface IProps {
  username: string;
}

export const ProfileSeries = (props: IProps) => {
  const { t } = useTranslation();
  const backGround = useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack");

  const { data, isError, isLoading } = useQuery(["customer", props.username], () => GetSerieAndNftsForCustomerQuery(props.username));

  if (isLoading) {
    return <Spinner />
  }
  if (isError || !data) {
    return <h1>{t("Profile.No_Series_For_Username")}</h1>
  }

  return (
    <Box px={{ base: 6, md: 8 }} w="100%" as="section" pb={{ base: '4', md: '8' }} bgColor={backGround}>
      {data.map((serie) => (
        <ProfileSerie key={serie.simpleSerie.id} username={props.username} customerSerieAndNft={serie} serieItemRowCount={data.length > 3 ? 1 : 2} />
      ))}
    </Box>
  )
}