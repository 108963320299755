import { ESerieHighlightSpot } from '../../enums/ESerieHighlightSpot'
import { ESerieType } from '../../enums/ESerieType'
import { IOrganization } from '../organization/IOrganization'
import { ISerieItem } from '../SerieItems/ISerieItem'

export interface ISerieDto {
  id: string;
  translation: ISerieTranslationDto;
  organization: IOrganization;
  order: number;
  bannerImage: ISerieImage;
  smallImage: ISerieImage;
  serieItems: ISerieItem[];
}

export interface ISerieTranslationDto {
  id: string;
  title: string;
  description: string;
}

export interface IFeaturedSerie {
  serieId: string;
  title: string;
  description: string;
  highlightSpot: ESerieHighlightSpot;
  smallImage: ISerieImage;
  bannerImage: ISerieImage;
  serieItems: ISerieItem[];
}

export interface ISerieCollection {
  id: string;
  order: number;
  organization?: IOrganization;
  serieItems?: ISerieItem[];
  dateActivated: Date;
  translation: ISerieTranslationDto;
  bannerImage: ISerieImage;
  smallImage: ISerieImage;
}

export interface IDashboardSerie {
  id: string;
  translation: ISerieTranslationDto;
  bannerImage: ISerieImage;
  smallImage: ISerieImage;
  serieType: ESerieType;
  serieItemCount: number;
  organizationId: string;
  isActive: boolean;
  dateVisible: Date | undefined;
}

export interface ISerieImage {
  id: string;
  name: string;
  path: string;
}

export interface ISerieSimple {
  id: string;
  order: number;
  translation: ISerieTranslationDto;
}

export class DashboardSerieFormValues {
  translation: SerieTranslationFormvalues = new SerieTranslationFormvalues();
  bannerImage: any = "";
  smallImage: any = "";
  serieType: ESerieType = 0;
  organizationId: string = "";

  constructor(init?: IDashboardSerie) {
    Object.assign(this, init);
  }
}

export class SerieTranslationFormvalues {
  title: string = "";
  description: string = "";
}

export type DashboardSerieFormValuesWithId = {
  id: string;
  formValues: DashboardSerieFormValues;
}