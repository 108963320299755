import { Box, SimpleGrid, Stack, Text, useColorMode } from '@chakra-ui/react'
import { GetFeaturedSeriesQuery } from '../../../api/queries/serie/FeaturedSerieQueries'
import { ILinkItem } from '../../../models/routes/ILinkGroup'
import { links } from './_data'
import { SeriePreview } from '../../serie/SeriePreview'
import { useQuery } from 'react-query'

export const SeriesNavMenuDesktop = () => {
  const { colorMode } = useColorMode();
  const { data, isLoading: isLoadingFeatured } = useQuery(['api/serie-featured'], () => GetFeaturedSeriesQuery());

  return (
    <Box
      px="10"
      pt="6"
      pb="8"
      bg={colorMode === "light" ? "bgPrimaryWhite" : "bgSecondaryBlack"}
      display={{ base: 'none', lg: 'block' }}
    >
      <Box>
        <Stack direction="row" spacing="16" justify="space-between">
          <Stack direction="row" spacing="6" flexShrink={0}>
            {links.map((group, idx) => (
              <Box key={idx}>
                <Text fontWeight="bold" mb="4">
                  {group.title}
                </Text>
                <Stack as="ul" listStyleType="none">
                  {group.links.map((link: ILinkItem, idx: number) => (
                    <Box as="li" key={idx}>
                      <Box as="a" href={link.href} _hover={{ textDecor: 'underline' }} color={colorMode === "dark" ? "whiteAlpha.700" : "blackAlpha.700"}>
                        {link.label}
                        {link.badge && (
                          <Box as="span" marginStart="2">
                            {link.badge}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
          <SimpleGrid
            spacing="6"
            columns={{ base: 2, lg: Math.min(data?.length ?? 0, 3) }}
            alignContent="flex-start"
            maxW={{ base: '320px', lg: '320px' }}
          >
            {
              !isLoadingFeatured && data && data.map((serieItem, index) => (
                <SeriePreview {...serieItem} key={index} />
              ))
            }
          </SimpleGrid>
        </Stack>
      </Box >
    </Box >
  )
}