import { Box, Spinner, Stack, Text } from '@chakra-ui/react'

interface IProps {
  isLoading: boolean,
  label: string,
  value: number | undefined
  bgColor: any; // Heeft any type omdat bgcolor ingevuld wordt met useColorModeValue
}

export const StatisticsCard = (props: IProps) => {
  return (
    <Box
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
      bg="bg-surface"
      borderRadius="lg"
      backgroundColor={props.bgColor ? props.bgColor : null}
    >
      <Stack m="2">
        {props.isLoading ? <Spinner /> :
          <>
            <Text fontSize="md" color="muted">
              {props.label}
            </Text>
            {/* <Heading size={useBreakpointValue({ base: 'sm', md: 'md' })}>{props.value}</Heading> TODO: Font kopen */}
            {/* TODO: Onderstaande text vervangen met de heading zodra font is gekocht */}
            <Text fontSize="5xl">
              {props.value}
            </Text>
          </>
        }
      </Stack>
    </Box>
  )
}