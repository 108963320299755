import React from 'react'
import { Box, Center, Flex, Heading, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { contentItemsPostalActionsPage } from '../../postalOrganisations/_data'
import { useTranslation } from 'react-i18next'

export const PilotOfferings = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Center as='section' id='pilotOfferings' mt={{ base: "24", lg: "18" }}>
      <Box width={boxWidth}>
        <Heading as="h2" fontSize="2xl" mb={10} color="white" textAlign="left">
          {t("B2B.PostalAction.PilotOffering.Title")}
        </Heading>
        <Flex flexFlow="row wrap" justifyContent="space-between">
          {contentItemsPostalActionsPage.map(ci => (
            <Flex flexDirection="column" flexBasis={{ base: "100%", md: "31%" }} mb={16}>
              <VStack alignItems="flex-start" spacing={3}>
                {ci.icon}
                <Heading as="h4" fontSize="2xl" mb={2} color="white">{t(`${ci.title}`)}</Heading>
                <Text color="ciphers.main.textSecondary">{t(`${ci.content}`)}</Text>
              </VStack>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Center >
  )
}
