import { IJoyrideContainer } from '../models/joyride/IJoyride'
import { JoyrideBaseComponent } from '../components/joyride/JoyrideBaseComponent'
import { JoyrideHeaderComponent } from '../components/joyride/JoyrideHeaderComponent'

export type JoyrideKey =
  | "HOME"

export const joyrideTutorials: IJoyrideContainer = {
  tutorials: [{
    key: "HOME",
    continuous: true,
    // hideSkip: true,
    steps: [{
      title: <JoyrideHeaderComponent heading='Joyride.Home.Heading' />,
      target: '#home',
      content: <JoyrideBaseComponent descriptions={["Joyride.Home.Step_1_Description"]} />,
    },
    {
      title: <JoyrideHeaderComponent heading='Joyride.Home.Heading' />,
      target: '#myCollections',
      disableBeacon: true,
      content: <JoyrideBaseComponent descriptions={["Joyride.Home.Step_2_Description"]} />,
    },
    {
      title: <JoyrideHeaderComponent heading='Joyride.Home.Heading' />,
      target: '#browseSerieItems',
      disableBeacon: true,
      content: <JoyrideBaseComponent descriptions={["Joyride.Home.Step_3_Description"]} />,
    },
    {
      title: <JoyrideHeaderComponent heading='Joyride.Home.Heading' />,
      target: '#exampleForm',
      redirect: '/example/form',
      redirectBack: '/',
      content: <JoyrideBaseComponent descriptions={["Joyride.Home.Step_4_Description"]} />,
      disableBeacon: true
    },
    {
      title: <JoyrideHeaderComponent heading='Joyride.Home.Heading' />,
      target: '#myCollections',
      redirect: '/',
      redirectBack: '/example/form',
      content: <JoyrideBaseComponent descriptions={["Joyride.Home.Step_5_Description"]} />,
      disableBeacon: true
    }
    ]
  }]
}