import { Box, Button, Flex, useColorModeValue, useToast, VStack } from '@chakra-ui/react'
import { FaqCategoryFormValues, IFaqCategory } from '../../../../models/faq/IFaq'
import { Form, Formik } from 'formik'
import { history } from '../../../../ApplicationRouter'
import { InputControl } from '../../../common/formik-chakra'
import { useCreateFaqCategoryMutation, useEditFaqCategoryQueryMutation } from '../../../../api/queries/faq/FaqQueries'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { validationSchema } from './FaqCategory.Validation'

interface IProps {
  faqCategory?: IFaqCategory;
}

export const FaqCategoryForm = (props: IProps) => {
  const [formValues, setFormValues] = useState(new FaqCategoryFormValues())
  const boxBackground = useColorModeValue("bgSecondaryWhite", "bgSecondaryBlack");
  const handleCreateFaqCategory = useCreateFaqCategoryMutation();
  const handleEditFaqCategory = useEditFaqCategoryQueryMutation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.faqCategory) setFormValues(new FaqCategoryFormValues(props.faqCategory))
  }, [props.faqCategory])

  const handleSubmit = async (values: FaqCategoryFormValues) => {
    if (props.faqCategory) {
      handleEditFaqCategory.mutate({ id: props.faqCategory.id, formValues: values }, {
        onSuccess() {
          queryClient.invalidateQueries(['faq/categories'])
          history.back();
          toast({
            title: t("Faq_Category.Faq_Category"),
            description: t("Faq_Category.Toast.Edit_Success"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Faq_Category.Faq_Category"),
            description: t("Faq_Category.Toast.Edit_Error"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    } else {
      handleCreateFaqCategory.mutate(values, {
        onSuccess() {
          queryClient.invalidateQueries(['faq/categories'])
          history.back();
          toast({
            title: t("Faq_Category.Faq_Category"),
            description: t("Faq_Category.Toast.Create_Success"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Faq_Category.Faq_Category"),
            description: t("Faq_Category.Toast.Create_Error"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Box bgColor={boxBackground} p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="translation.name" label="Name" isRequired />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">{t("Submit")}</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  );
}