import { AutoWidthCenter } from '../../../layout/AutoWidthCenter'
import { Box, Button, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import { CardWithAvatar } from './CardWithAvatar'
import { ECustomerType } from '../../../../enums/ECustomerType'
import { formatDateTime, imageOrPlaceholder } from '../../../../utils/General'
import { HiPencilAlt } from 'react-icons/hi'
import { ICustomerProfile } from '../../../../models/user/ICustomerProfile'
import { ImageResizerMode } from '../../../../models/general/IImageResizerOptions'
import { NavLink } from 'react-router-dom'
import { routePrefixCustomer } from '../../../../ApplicationRouter'
import { UserInfo } from './UserInfo'
import { useTranslation } from 'react-i18next'

export interface ProfileBannerProps {
  userInfo: ICustomerProfile
}

export const ProfileBanner = (props: ProfileBannerProps) => {
  const userInfo = props.userInfo;
  const { t } = useTranslation();

  return (
    <Box as="section" pt="20" position="relative">
      <AutoWidthCenter bg={useColorModeValue("bgSecondaryWhite", "bgSecondaryBlack")}>
        <Box
          position="absolute"
          inset="0"
          height={{ base: "20", md: "230px" }}
          backgroundImage={imageOrPlaceholder(userInfo.bannerImage?.path, { mode: ImageResizerMode.scale, height: 500, width: 1800 })}
          bgSize="cover"
          bgPos="center"
        />
        <CardWithAvatar
          w="100%"
          minW="100%"
          shadow={{ base: "none", md: "none" }}
          avatarProps={{
            src: imageOrPlaceholder(userInfo.profileImage?.path, { mode: ImageResizerMode.scale, height: 100, width: 100 }),
            name: userInfo.userName,
          }}
          action={
            <Button as={NavLink} to={`${routePrefixCustomer}/edit`} size="sm" leftIcon={<HiPencilAlt />}>
              {t("Settings.Edit_Settings")}
            </Button>
          }
        >
          <Box textAlign={{ sm: 'left' }} pt="2" w="100%">
            <Heading size="lg" fontWeight="extrabold" letterSpacing="tight">
              {/* Show the firstname and or lastname, as well as the username */}
              {userInfo.firstName.length > 0 && userInfo.firstName}{userInfo.firstName && " "}{userInfo.lastName.length > 0 && userInfo.lastName}
              {(userInfo.firstName || userInfo.lastName) && <br />}
              {userInfo.userName}
            </Heading>
            <Text>
              {t(`Customer_Type.${ECustomerType[userInfo.customerType]}`)}
            </Text>
            <UserInfo
              privacySetting={userInfo.privacySetting}
              stats={userInfo.nftsInSeries}
              memberSince={formatDateTime(userInfo.registeredAt, "i18n.Formatted.Date")}
            />
          </Box>
        </CardWithAvatar>
      </AutoWidthCenter>
    </Box>
  )
}