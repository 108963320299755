import { alertStyles } from './_alertStyles'
import { breakpoints } from './_breakpoints'
import { buttonStyles } from './_buttonStyles'
import { colors } from './_colors'
import { globalStyles } from './_globalStyles'
import { headingStyles } from './_headingStyles'
import { sizes } from './_sizes'
import { spacing } from './_spacing'
import { textStyles } from './_textStyles'
import { themeConfig } from './_config'

export const theme = {
  breakpoints,
  colors,
  components: {
    Button: buttonStyles,
    Heading: headingStyles,
    Text: textStyles,
    Alert: alertStyles
  },
  config: themeConfig,
  sizes: { ...sizes },
  space: { ...spacing },
  styles: globalStyles
}