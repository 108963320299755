import React from 'react'
import { Box, Center, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const Partnership = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();
  return (

    <Center as='section' id='partnership'>
      <Flex justifyContent="space-between" flexFlow="row wrap" width={boxWidth}>
        <Box flexBasis={{ base: "100%", md: "45%" }} flexFlow="row wrap" mb={{base: 8, lg: 0}}>
          <Heading as="h2" mb={{base: 4, lg: 8}} color="white">{t("B2B.PostalAction.Partnership.Title1")}</Heading>
          <Text as="p" color="white">
            {t("B2B.PostalAction.Partnership.Description1")}
          </Text>
        </Box>
        <Box flexBasis={{ base: "100%", md: "45%" }}>
          <Heading as="h2" mb={{base: 4, lg: 8}} color="white">{t("B2B.PostalAction.Partnership.Title2")}</Heading>
          <Text as="p" color="white">
            {t("B2B.PostalAction.Partnership.Description2")}
          </Text>
        </Box>
      </Flex>
    </Center>

  )
}
