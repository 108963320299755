import CiphersLogoSVG from '../../assets/b2b/shared/logos/Ciphers-logo.svg'
import { Flex, Heading, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const CiphersLogo = () => {
  const { t } = useTranslation()
  return (
    <Link to="/">
      <Flex alignItems="center">
        <Image src={CiphersLogoSVG} htmlHeight="20" htmlWidth="20" alt="Logo Ciphers.me" height={{ base: "28px", md: "40px" }} width="auto" mr={{ base: 3, md: 4 }} />
        <Heading as="h1" variant="href" fontSize={{ base: "xl", md: "3xl" }} color="white" title={`Version: ${process.env.REACT_APP_VERSION}`}>{t("ApplicationName")}</Heading>
      </Flex>
    </Link>
  )
}
