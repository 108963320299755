import { Box, Button, useToast } from '@chakra-ui/react'
import { CustomerVisibilitySettingsFormValues, ICustomerSimple } from '../../../models/user/ICustomerProfile'
import { Form, Formik } from 'formik'
import { SwitchControl } from '../../common/formik-chakra'
import { useEditVisibilitySettings } from '../../../api/queries/user/EditProfileQuery'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  customer?: ICustomerSimple;
}

export const VisibilityForm = (props: IProps) => {
  const [formValues, setFormValues] = useState(new CustomerVisibilitySettingsFormValues());
  const handleEditCustomerVisibilitySetings = useEditVisibilitySettings();
  const toast = useToast();
  const { customer } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (customer) setFormValues(new CustomerVisibilitySettingsFormValues(customer))
  }, [])

  const handleSubmit = (values: CustomerVisibilitySettingsFormValues) => {
    handleEditCustomerVisibilitySetings.mutate(values, {
      onSuccess() {
        toast({
          title: t("Customer.Profile.Visibility"),
          description: t("Customer.Toast.Visibility_Edit_Success"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: t("Customer.Profile.Visibility"),
          description: t("Customer.Toast.Visibility_Edit_Error"),
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      }
    })
  }

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <SwitchControl name="isPublic" label={t("Profile.Public_Profile")} />

          <Box width="full" pt="3">
            <Button type="submit">{t("Submit")}</Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}