export interface IImageResizerOptions {
  width?: number;
  height?: number;
  centerX?: number;
  centerY?: number;
  mode?: ImageResizerMode
}

export enum ImageShape {
  circle = "circle",
  rectangle = "rectangle"
}

export enum ImageResizerMode {
  crop = "crop",
  scale = "scale"
}

export class ImageResizerOptions implements IImageResizerOptions {
  constructor(init?: IImageResizerOptions) {
    if (init) {
      Object.assign(this, init)
    } else {
      this.mode = ImageResizerMode.scale;
    }
  }

  width?: number;
  height?: number;
  centerX?: number;
  centerY?: number;
  mode?: ImageResizerMode;

  toQueryStringValues(startingCharacter: '?' | '&' | undefined): string {
    let result = [];
    if (this.width) {
      result.push(`width=${this.width}`);
    }
    if (this.height) {
      result.push(`height=${this.height}`)
    }
    if (this.centerX) {
      result.push(`centerx=${this.centerX}`)
    }
    if (this.centerY) {
      result.push(`centerY=${this.centerY}`)
    }
    if (!this.mode) {
      this.mode = ImageResizerMode.scale
    }
    result.push(`mode=${this.mode}`)

    if (!result.length) {
      return '';
    }

    return `${startingCharacter}${result.join('&')}`;
  }
}