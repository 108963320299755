import { ECustomerType } from '../../enums/ECustomerType'
import { EProfilePrivacy } from '../../enums/EProfilePrivacy'

export interface ICustomerProfile {
  userName: string;
  firstName: string;
  lastName: string;
  customerType: ECustomerType;
  privacySetting: EProfilePrivacy;
  nftsInSeries: INftOverSerie;
  registeredAt: Date;
  bannerImage: IProfileImage;
  profileImage: IProfileImage;
}

export interface IPublicProfile {
  userName: string;
  firstName: string;
  lastName: string;
  nftsInSeries: INftOverSerie;
  registeredAt: Date;
  bannerImage: IProfileImage;
  profileImage: IProfileImage;
  isPublic: boolean;
}

export interface INftOverSerie {
  ownedNfts: number;
  nftSeries: number;
}

export interface ICustomerSimple {
  userName: string;
  firstName: string;
  lastName: string;
  lastNamePrefix: string;
  phone: string;
  bannerImage: IProfileImage;
  profileImage: IProfileImage;
  id: string;
  lastActive: Date;
  isPublic: boolean;
}

export interface IProfileImage {
  id: string;
  name: string;
  path: string;
}

export class CustomerProfileSettingsFormValues {
  userName: string = "";
  firstName: string = "";
  lastName: string = "";
  lastNamePrefix: string = "";
  phone: string = "";
  bannerImage: any = "";
  profileImage: any = "";

  constructor(init?: ICustomerSimple) {
    Object.assign(this, init);
  }
}

export class CustomerVisibilitySettingsFormValues {
  isPublic: boolean = false;

  constructor(init?: ICustomerSimple) {
    Object.assign(this, init);
  }
}
