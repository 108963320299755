import { AiOutlineDelete } from 'react-icons/ai'
import { Button, IconButton, useToast } from '@chakra-ui/react'
import { DataView, IDataViewColumn } from '../../../common/dataview/Dataview'
import { FiEdit2 } from 'react-icons/fi'
import { IFaqQuestion } from '../../../../models/faq/IFaq'
import { NavLink } from 'react-router-dom'
import { useDeleteFaqQuestionMutation } from '../../../../api/queries/faq/FaqQueries'
import { useModal } from '../../../../context/ModalContext'
import { useQueryClient } from 'react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  faqQuestions: IFaqQuestion[];
}

export const FaqQuestionTable = ({ faqQuestions }: IProps) => {
  const handleDeleteFaqQuestion = useDeleteFaqQuestionMutation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { dispatch } = useModal();
  const { t } = useTranslation();
  const [columns] = useState<IDataViewColumn[]>([
    { headerName: t("Dataview.Headers.Faq_Question"), field: "translation.question", flex: 1, tooltipField: "translation.question" },
    { headerName: t("Dataview.Headers.Faq_Answer"), field: "translation.answer", flex: 1, tooltipField: "translation.answer" },
    { headerName: t("Dataview.Headers.Actions"), cellRenderer: "actions", flex: 0.5 }
  ]);

  const handleDelete = (id: string) => {
    handleDeleteFaqQuestion.mutate(id, {
      onSuccess() {
        queryClient.invalidateQueries(['faq/questions'])
        dispatch({ type: 'close' })
        toast({
          title: t("Faq_Question"),
          description: t("Faq_Question.Toast.Delete_Success"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: t("Faq_Question"),
          description: t("Faq_Question.Toast.Delete_Error"),
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const renderCellAction = (tableData: any) => {
    const faqQuestion: IFaqQuestion = tableData.data;
    return (
      <>
        <IconButton
          icon={<FiEdit2 fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Edit question"
          as={NavLink}
          to={`edit/${faqQuestion.id}`}
        />
        <IconButton
          icon={<AiOutlineDelete fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Delete faq question"
          onClick={() => dispatch({
            type: 'open', payload: {
              title: t('Faq_Question.Delete'),
              bodyContent: t('Faq_Question.Are_You_Sure_Delete_Serie'),
              footerContent:
                <>
                  <Button mr={3} variant='ghost' onClick={() => handleDelete(faqQuestion.id)}>{t('Faq_Question.Delete')}</Button>
                  <Button onClick={() => dispatch({ type: 'close' })}>
                    {t('Close')}
                  </Button>
                </>
            }
          })}
        />
      </>
    )
  }

  return (
    <DataView
      actionsRenderer={renderCellAction}
      columns={columns}
      data={faqQuestions}
    />
  )
}
