import customerRoutes from './routes/CustomerRoutesConfig'
import dashboardRoutes from './routes/DashboardRoutesConfig'
import { createBrowserHistory } from 'history'
import { CustomerLayout } from './globalstyles/layouts/CustomerLayout'
import { DashboardLayout } from './globalstyles/layouts/DashboardLayout'
import { GetAllowedRoutes } from './routes/RouteUtils'
import { IRoute } from './models/routes/IRoute'
import { JoyrideComponent } from './components/joyride/JoyrideComponent'
import { LayoutB2B } from './globalstyles/layouts/LayoutB2B'
import { Login } from './pages/login/Login'
import { MainLayout } from './globalstyles/layouts/MainLayout'
import { ModalContainer } from './components/common/modal/ModalContainer'
import { ModalProvider } from './context/ModalContext'
import { NotFound } from './pages/errorPages/NotFound'
import { PublicRoutes } from './routes/publicRoutes/PublicRoutes'
import { Route, RouteObject, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { RoutesB2B } from './routes/b2bSubRoutes/SubRoutesB2B'
import { ScrollToTop } from './components/common/scroll-to-top/ScrollToTop'

export const history = createBrowserHistory({ window });
export const b2bSubdomain = "b2b";
export const routePrefixDashboard = "/dashboard";
export const routePrefixCustomer = "/customer";
export const routePrefixRedirectLogin = "/login";

export const ApplicationRouter = () => {
  const allowedDashboardRoutes = GetAllowedRoutes(dashboardRoutes);
  const allowedCustomerRoutes = GetAllowedRoutes(customerRoutes);
  const isB2B = window.location.hostname.replaceAll("www.", "").startsWith(b2bSubdomain);

  const mapAllowedRoutes = (allowedRoutes: IRoute[]) => {
    return allowedRoutes.map((route: IRoute) => {
      const { dropdownRoutes, element, path } = route;
      return (
        // If the current route is of type dropdown, don't use it as a route.
        !dropdownRoutes?.dropdown
          ? <Route key={path} path={path} element={element} />
          : (
            // Route its childs instead
            dropdownRoutes?.childRoutes?.map((childRoute: IRoute) => {
              const { element, path } = childRoute;
              return (
                <Route key={path} path={path} element={element} />
              )
            })
          )
      )
    })
  }

  if (true) {
    return (
      <HistoryRouter history={history}>
        <ModalProvider>
          <ModalContainer />
          <ScrollToTop />
          <Routes>
            {/* B2B routes on B2B subdomain */}
            <Route element={<LayoutB2B />}>
              {RoutesB2B.map((route: RouteObject) => {
                return <Route key={route.path} path={route.path} element={route.element} index={route.index} />
              })}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
          <JoyrideComponent />
        </ModalProvider>
      </HistoryRouter>
    )
  }

  return (
    <HistoryRouter history={history}>
      <ScrollToTop />
      <ModalProvider>
        <ModalContainer />

        <Routes>
          {/* Route without a layout */}
          <Route path="/login" element={<Login />} />

          {/* Public routes */}
          <Route element={<MainLayout />}>
            {PublicRoutes.map((route: RouteObject) => {
              return <Route key={route.path} path={route.path} element={route.element} index={route.index} />
            })}
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Dashboard routes */}
          <Route path={routePrefixDashboard} element={<DashboardLayout />}>
            {mapAllowedRoutes(allowedDashboardRoutes)}
          </Route>

          {/* Customer routes */}
          <Route path={routePrefixCustomer} element={<CustomerLayout />}>
            {mapAllowedRoutes(allowedCustomerRoutes)}
          </Route>
        </Routes>

        <JoyrideComponent />
      </ModalProvider>
    </HistoryRouter>
  )
}
