import { b2bNavbarRoutes } from './_data'
import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, HStack, Link, List, ListItem, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { CiphersLogo } from '../Logo/CiphersLogo'
import { GetAllowedLinkRoutes } from '../../routes/RouteUtils'
import { Link as ReachLink, NavLink } from 'react-router-dom'
import { NavbarMobileB2B } from './NavbarMobileB2B'
import { NavbarMobileLayout } from './NavbarMobileLayout'
import { PossibleRoutesB2B, useGetCurrentRoutes } from '../../hooks/useCurrentPath'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const NavbarB2B = () => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const routeDict = useGetCurrentRoutes();
  const allowedAuthorizedNavbarRoutes = GetAllowedLinkRoutes(b2bNavbarRoutes);

  return isDesktop ? (
    <Box id="navbar" as="section" width="100%" zIndex={1000} backgroundColor="bgPrimary" height="layout.navbarLg">
      <Flex justify="space-between" alignItems="flex-end" height="100%" px={{ base: 6, md: 0 }} py={{ base: "3", lg: "4" }} width={{ xl: "1127px", lg: "933px", md: "95%", sm: "80%", base: "90%" }} color="white" mx="auto">
        {!isOpen && <CiphersLogo />}
        <List listStyleType="none" display="flex" alignItems="center">
          <HStack spacing={10}>
            <ListItem key="https://Ciphers.me" boxShadow="0px -55px 35px #003D73">
              <Link href="https://Ciphers.me" fontSize="16px" pb={5} _hover={{ textDecoration: "none" }}>
                For Collectors
              </Link>
            </ListItem>
            {allowedAuthorizedNavbarRoutes.map((route, i) => {
              const routeSelected = routeDict[route.href as PossibleRoutesB2B].isCurrentRoute;
              return (
                <ListItem key={`${route.href}-${i}`} boxShadow={routeSelected ? " 0px -55px 35px #003D73" : "none"} >
                  <Link as={ReachLink} to={route.href} fontSize="16px" pb={5} borderBottom={routeSelected ? "1px solid white" : "none"} _hover={{ textDecoration: "none" }}>
                    {t(route.label)}
                  </Link>
                </ListItem>
              );
            })}
            <Button as={NavLink} to="/contact" variant="b2bTertiary" color="white">
              Contact
            </Button>
          </HStack>
        </List>
      </Flex>
    </Box>
  ) : (
    // Mobile menu
    <Box id="navbar" as="section" width="100%" zIndex={1000} backgroundColor="bgPrimary" position="fixed" top="0" height="layout.navbarBase">
      <NavbarMobileLayout isMenuOpen={isOpen} onClickMenu={onOpen} />
      <Drawer placement="top" initialFocusRef={menuButtonRef} isOpen={isOpen} onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent background={"bgPrimary"}>
          <DrawerHeader padding="0">
            <NavbarMobileLayout isMenuOpen={isOpen} onClickMenu={onClose} menuButtonRef={menuButtonRef} />
          </DrawerHeader>
          <DrawerBody>
            <NavbarMobileB2B onClickMenu={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}