import header_image from '../../../../assets/b2b/shared/header.webp'
import { Button, Center, Grid, GridItem, Heading, Image, Text, useBreakpointValue } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

export const Hero = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const templateArea = useBreakpointValue({
    base: `
    "Title"
    "Image"
    "Text"
    `,
    md: `
      "Title Image"
      "Text Image"
      "Text Image"
  `})
  const { t } = useTranslation();
  return (
    <>
      <Center as='section' id='heading'>
        <Grid
          width={boxWidth}
          pt={{ base: 8, md: 16 }}
          templateAreas={templateArea}
          gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
        >
          <GridItem area="Title">
            <Heading as="h1" variant={"pageTitle"} mb={{ base: 8, md: 0 }} textAlign={{ base: "center", md: "left" }} fontSize={{ base: "4xl", md: "5xl" }}>
              <Trans i18nKey="B2B.PostalAction.Hero.Title">
                Placeholder <span className='magic'></span>
              </Trans>
            </Heading>
          </GridItem>

          <GridItem area="Text">
            <Heading as="h2" fontSize={"3xl"} color="white" mb={8} textAlign={{ base: "center", md: "left" }}>
              {t("B2B.PostalAction.Hero.Subtitle")}
            </Heading>
            <Text as="p" color="whiteAlpha.800" mb={8}>{t("B2B.PostalAction.Hero.Paragraph1")}</Text>
            <Button variant="b2bPrimary" width={{ base: "100%", md: "fit-content" }} as={NavLink} to="/contact/1">{t("B2B.PostalAction.Hero.Button").toUpperCase()}</Button>
          </GridItem>

          <GridItem
            area="Image"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={{ base: 8, lg: 0 }}
          >
            <Image src={header_image} width="100%" height="auto" />
          </GridItem>
        </Grid>
      </Center>
    </>
  )
}
