import React from 'react'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Center, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { accordionItems } from '../../postalOrganisations/_data'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Questions = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Center as='section' id='questions'>
      <Box width={boxWidth}>
        <Box as='section' id='questions' display="flex" flexFlow="row wrap" justifyContent="space-between" >
          <Box flexBasis={{ base: "100%", md: "80%", lg: "60%" }} mb={28}>
            <Heading as="h2" fontSize="4xl" mb={7} color="white" textAlign={{ base: "center", md: "left" }}>
              {t("B2B.Home.Questions.Title")}
            </Heading>
            <Text mb={7} color="white" textAlign={{ base: "center", md: "left" }}>
              {t("B2B.Home.Questions.Description")}
            </Text>
            <Button as={NavLink} to="/contact" variant="b2bPrimary" width={{ base: "100%", md: "fit-content" }}>
              {t("B2B.Home.Questions.Button").toUpperCase()}
            </Button>
          </Box>
          <Box flexBasis={{ base: "100%", lg: "80%" }}>
            <Accordion allowMultiple>
              {accordionItems.map(ai => (
                <AccordionItem border="none" backgroundColor="#010227" px={2} py={4} borderRadius={4} mb={4}>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton>
                          <Box flex='1' textAlign='left' >
                            <Text as="span" fontSize="2xl" color="white">
                              {t(`${ai.title}`)}
                            </Text>
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize='12px' color="white" />
                          ) : (
                            <AddIcon fontSize='12px' color="white" />
                          )}
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} color="whiteAlpha.600">
                        {t(`${ai.content}`)}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Center>
  )
}
