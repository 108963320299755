import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, useColorModeValue } from '@chakra-ui/react'
import { FaqCategoryForm } from '../../../../components/secure/dashboard/faq/FaqCategoryForm'
import { GetFaqCategoryQuery } from '../../../../api/queries/faq/FaqQueries'
import { history } from '../../../../ApplicationRouter'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

export const EditFaqCategory = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  const { data } = useQuery(['faq/category', id], () => GetFaqCategoryQuery(id!), { enabled: !!id });

  return (
    <AutoWidthCenter bg={useColorModeValue("bgTertiaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Faq_Category.Edit')}</Heading>
          <Button onClick={() => history.back()}>{t('Return')}</Button>
        </HStack>
        <Divider />
        {data &&
          <FaqCategoryForm faqCategory={data} />
        }
      </Box>
    </AutoWidthCenter >
  )
}