export interface IFaqCategory {
  id: string;
  order: number;
  translation: IFaqCategoryTranslation;
  questions: IFaqQuestion[];
}

export interface IFaqCategoryTranslation {
  id: number;
  name: string;
}

export class FaqCategoryFormValues {
  translation: FaqCategoryTranslationFormValues = new FaqCategoryTranslationFormValues();

  constructor(init?: IFaqCategory) {
    Object.assign(this, init);
  }
}

export class FaqCategoryTranslationFormValues {
  name: string = "";
}

export type FaqCategoryFormValuesWithId = {
  id: string;
  formValues: FaqCategoryFormValues;
}
export interface IFaqQuestion {
  id: string;
  order: number;
  categoryId: string;
  translation: IFaqQuestionTranslation;
}

export interface IFaqQuestionTranslation {
  id: string;
  question: string;
  answer: string;
}

export class FaqQuestionFormValues {
  translation: FaqQuestionTranslationFormValues = new FaqQuestionTranslationFormValues();
  categoryId: string = "";

  constructor(init?: IFaqQuestion) {
    Object.assign(this, init);
  }
}

export class FaqQuestionTranslationFormValues {
  question: string = "";
  answer: string = "";
}

export type FaqQuestionFormValuesWithId = {
  id: string;
  formValues: FaqQuestionFormValues;
}