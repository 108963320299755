import Joyride, { ACTIONS, CallBackProps, EVENTS, LIFECYCLE, Styles as JoyrideStyles } from 'react-joyride'
import { colors } from '../../styles/Theme/_colors'
import { history } from '../../ApplicationRouter'
import { JoyrideContext } from '../../hooks/useJoyride'
import { useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const JoyrideComponent = () => {
  const { t } = useTranslation();
  const joyrideContext = useContext(JoyrideContext);
  const steps = joyrideContext.getSteps;
  const navigate = useNavigate();

  history.listen(({ action, location }) => {
    if (steps === undefined) return;

    // Redirect listener to ensure the beacon is hidden if the user redirects to another page, which isn't a target for the current joyride tutorial
    if (action === "PUSH" && location.pathname !== "") {
      const redirects = steps.flatMap(step => { return step.redirect === undefined ? [] : step.redirect.toUpperCase() });
      const redirectBacks = steps.flatMap(step => { return step.redirectBack === undefined ? [] : step.redirectBack.toUpperCase() });

      if (!redirects.includes(location.pathname.toUpperCase()) && !redirectBacks.includes(location.pathname.toUpperCase())) {
        joyrideContext.resetShouldRun();
      }
    }
  });

  const handleCallback = (data: CallBackProps) => {
    if (steps === undefined) return;
    const { action, index, lifecycle, type } = data;

    if ([EVENTS.STEP_AFTER.toString(), EVENTS.TARGET_NOT_FOUND.toString()].includes(type)) {
      if (action === ACTIONS.NEXT) {
        const step = steps[index + 1];
        if (step !== undefined && step.redirect !== undefined && step.redirect !== "") {
          navigate(step.redirect);
        }
        joyrideContext.nextStepIndex();
      } else if (action === ACTIONS.PREV) {
        const step = steps[index];
        if (step !== undefined && step.redirectBack !== undefined && step.redirectBack !== "") {
          navigate(step.redirectBack);
        }
        joyrideContext.previousStepIndex();
      }
      return;
    } else if ([LIFECYCLE.COMPLETE.toString()].includes(lifecycle)) {
      joyrideContext.skip();
      return;
    } else if ([EVENTS.TOUR_END.toString()].includes(type)) {
      joyrideContext.skip();
      return;
    }

    if (action === ACTIONS.SKIP) {
      joyrideContext.skip();
      return;
    }

    if (action === ACTIONS.CLOSE && !joyrideContext.continuous) {
      joyrideContext.skip();
    }
  }

  const scrollOffset = useBreakpointValue({ base: 64, xl: 148 });

  const defaultStyles: JoyrideStyles = {
    options: {
      primaryColor: useColorModeValue(colors.primary_DM, colors.primary),
    }
  }

  return steps === undefined ? null : (
    <Joyride
      callback={handleCallback}
      continuous={joyrideContext.continuous}
      run={joyrideContext.getShouldRun}
      steps={steps}
      scrollOffset={scrollOffset}
      stepIndex={joyrideContext.stepIndex}
      styles={defaultStyles}
      locale={{
        back: t("Joyride.Locale.Back"),
        close: t("Joyride.Locale.Close"),
        last: t("Joyride.Locale.Last"),
        next: t("Joyride.Locale.Next"),
        open: t("Joyride.Locale.Open"),
        skip: t("Joyride.Locale.Skip"),
      }}
      showProgress
      showSkipButton={joyrideContext.showSkip}
    />
  )
}