import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, useColorModeValue } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { OrganizationTable } from '../../../../components/secure/dashboard/organization/OrganizationTable'
import { useTranslation } from 'react-i18next'

export const Organizations = () => {
  const { t } = useTranslation();

  return (
    <AutoWidthCenter bg={useColorModeValue("bgTertiaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Organization.Organizations')}</Heading>
          <Button as={NavLink} to="new" >{t('Organization.Create')}</Button>
        </HStack>
        <Divider />
        <OrganizationTable />
      </Box>
    </AutoWidthCenter >
  )
}