import { ChakraMotionDiv } from '../Framer/ChakraMotionDiv'
import { useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

interface IProps {
  children: React.ReactNode;
}
export const FadeIn = (props: IProps) => {

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("show");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const item = {
    hidden: {
      opacity: 0,
      y: 50,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] }
    },
    show: {
      opacity: 1,
      y: 0,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] }
    }
  };

  return (
    <ChakraMotionDiv variants={item} initial="hidden" animate={controls} ref={ref}>
      {props.children}
    </ChakraMotionDiv>
  )
}