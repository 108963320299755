import { authTokenKey, parsePayload, splitToken } from '../hooks/useAuth'
import { sendCustomEvent } from '../utils/Events'
import { ServiceEvents } from './ServiceEvents'

enum UpdateReason {
  "apiCall",
  "logOut",
  "storageEvent",
}

export function storageEventHandlerForAuthTokenKey(event: any) {
  // if authtoken was modified  || storage was cleared
  if (event.key === authTokenKey || (event.key === null && !event.storageArea[authTokenKey])) {
    // Wait a little before resetting the timers, and possibly showing a popup as a result. The storage event may have happened
    // because of a Logout, and that is usually followed by a Redirect to somewhere else. If that is the case && we don't wait,
    // then the LogOut popup will flash by, which is unneeded and messy.
    setTimeout(() => resetSessionTimers(UpdateReason.storageEvent), 50);
  }
}

export function resetSessionTimers(reason: UpdateReason) {
  // To see if this function gets triggered + if so, what triggered it:
  // console.log("resetSessionTimers(" + reason.key + ")");
  clearTimeout((window as any).warningTimeout);
  clearTimeout((window as any).logoutTimeout);

  const { iat: issuedAt, exp: expires } = parsePayload(splitToken(localStorage.getItem("KJE_NFT-authtoken") ?? ""));

  if (expires > 0) {
    const warningDuration = 120; // Seconds to show the warning popup
    const timeUntilWarning = expires - (Date.now() / 1000) - warningDuration;
    const timeUntilLogout = expires - (Date.now() / 1000);

    // const timeoutSeconds = expires - issuedAt - 1; // Values are in seconds. The "- 1" is there because the token has aged a little bit already when we process it.
    // const warningSeconds = timeoutSeconds >= 180 ? 55 : timeoutSeconds / 2; // If token is valid for >= 180 sec then warn 120 sec before token expires (normal behaviour). For debugging we support shorter times, e.g. 20 sec validity -> 10 sec warning timeout.
    (window as any).warningTimeout = setTimeout(() => sendCustomEvent(ServiceEvents.sessionWarning), timeUntilWarning * 1000); // "Warning" dialog timer. If you change the 120 value, you also need to change the "2 minutes" in the warning text.
    (window as any).logoutTimeout = setTimeout(() => sendCustomEvent(ServiceEvents.sessionLogout, { expires }), timeUntilLogout * 1000); // "Expired" dialog timer.
    sendCustomEvent(ServiceEvents.sessionStarted);
  } else if (expires === 0 && reason === UpdateReason.storageEvent) {
    console.log("Expired")
    sendCustomEvent(ServiceEvents.sessionLogout, { expires });
  }
}

export function ensureStorageTokenEventHandler() {
  if ((window as any).hasStorageEventHandlerForAuthTokenKey) return;

  // We could do (and have tried) this, and it works for most cases:
  //   window.addEventListener('storage', this.storageEventHandlerForAuthTokenKey);
  // ...except that no Event occurs for storage changes done by the tab/window itself <- which is official and by design.
  // This ALSO applies if the user does "Dev Tools -> Clear Data": the very tab/window from which it happens does NOT receive an
  // Event, only OTHER tabs/windows get informed.
  // We use the following workaround: we create a dummy iframe on the page. An iframe has its own separate window object, which
  // will receive Events for storage changes done by its parent window. And then we link the iframe window to our Event Handler.
  const iframe = document.createElement("iframe");
  iframe.className = "storage-iframe";
  document.body.appendChild(iframe);
  iframe.contentWindow!.addEventListener("storage", storageEventHandlerForAuthTokenKey);
  (window as any).hasStorageEventHandlerForAuthTokenKey = true;
}