import React from 'react'
import { AspectRatio, Box, Center, Flex, Heading, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { contentItems } from '../../pages/b2b/postalOrganisations/_data'
import { Trans, useTranslation } from 'react-i18next'

export const FeaturesSection = () => {
  const translation = useBreakpointValue({ base: "B2B.Features.TitleMobile", lg: "B2B.Features.Title" })
  const { t } = useTranslation()

  return (
    <Center as='section' id='features' mt={{ base: "24", lg: "18" }}>
      <Box width={{ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" }} position="relative" >
        <Heading as="h2" fontSize="4xl" mb={12} color="white" textAlign="left" position="relative" zIndex={1}>
          <Trans i18nKey={translation}>
          Placeholder <span className='magic'></span>
          </Trans>
        </Heading>
        <Flex flexFlow="row wrap" justifyContent="space-between">
          {contentItems.map(ci => (
            <Flex flexDirection="column" flexBasis={{ base: "100%", md: "48%", lg: "23%" }} mb={8} zIndex={1}>
              <VStack alignItems="flex-start" spacing={3}>
                {ci.icon}
                <Heading as="h3" fontSize="2xl" color="white">{t(`${ci.title}`)}</Heading>
                <Text color="ciphers.main.textSecondary">{t(`${ci.content}`)}</Text>
              </VStack>
            </Flex>
          ))}
        </Flex>
        <AspectRatio
          display={{ base: "none", lg: "block" }}
          width="100%"
          ratio={1 / 1}
          position="absolute"
          left="-50%"
          top="-20%"
          transform="rotateZ(0deg)"
          _after={{
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -1,
            transform: "scale(0.8)",
            clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
            bgGradient: 'linear(to-tr, transparent, transparent, #11095D )'
          }}
        >
          {/* Add empty Box because AspectRatio needs at least one child */}
          <Box></Box>
        </AspectRatio>
      </Box>
    </Center >
  )
}
