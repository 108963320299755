import { Center, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { ICustomerSerieItemAndNfts } from '../../models/user/ICustomerSerieAndNfts'
import { imageOrPlaceholder } from '../../utils/General'
import { useMemo } from 'react'
import './SerieItem.css'

interface IProps {
  customerSerieItem: ICustomerSerieItemAndNfts;
  className?: string;
}

export const SerieItem = (props: IProps) => {
  const { customerSerieItem, className } = props;
  const imgSource = useMemo(() => imageOrPlaceholder(customerSerieItem.serieItem.itemFile?.path), [customerSerieItem.serieItem.itemFile?.path])

  const width = "180px";

  return (
    <Stack opacity={customerSerieItem.ownedNfts.length > 0 ? "1" : "0.5"} gap={0} spacing={0} w={width} style={{ aspectRatio: "3/4" }} boxShadow={"0px 11px 20px 0px rgba(0,0,0,0.06)"} className={className ? className : ""}>
      <Center flex="1" bgColor="gray.700" borderRadius="7px 7px 0px 0px" overflow="hidden" bgImage={imgSource} bgSize="cover" bgPos="center center" />
      <Stack p="4" h={{ md: "98px" }} bgColor={useColorModeValue("primaryBlack", "primaryWhite")} borderRadius="0px 0px 7px 7px" >
        <Text color={useColorModeValue("primaryWhite", "primaryBlack")} fontWeight={"bold"} fontFamily="Cocogoose">
          {customerSerieItem.serieItem.translation?.title}
        </Text>
      </Stack>
    </Stack>
  )
}