export interface IOrganization {
  id: string;
  name: string;
  description: string;
}

export interface IDashboardOrganization extends IOrganization {
  totalActiveSeries: number;
  totalSeries: number;
}

export type OrganizationFormValuesWithId = {
  id: string;
  values: OrganizationFormValues;
}

export class OrganizationFormValues {
  name: string = "";
  description: string = "";

  constructor(init?: IOrganization) {
    Object.assign(this, init);
  }
}