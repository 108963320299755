import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, useColorModeValue } from '@chakra-ui/react'
import { FaqCategoryTable } from '../../../../components/secure/dashboard/faq/FaqCategoryTable'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const FaqCategories = () => {
  const { t } = useTranslation();

  return (
    <AutoWidthCenter bg={useColorModeValue("bgTertiaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Faq_Category.Faq_Category')}</Heading>
          <Button as={NavLink} to="category/new" >{t('Faq_Category.Create')}</Button>
        </HStack>
        <Divider />
        <FaqCategoryTable />
      </Box>
    </AutoWidthCenter >
  )
}