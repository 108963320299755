import { BaseProps, FormControl } from './FormControl'
import { ForwardedRef, forwardRef } from 'react'
import { Input, InputProps } from '@chakra-ui/react'
import { useField } from 'formik'

export type InputControlProps = BaseProps & { inputProps?: InputProps };

export const InputControl: React.FC<InputControlProps> = forwardRef(
  (props: InputControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, label, inputProps, ...rest } = props;
    const [field] = useField(name);

    return (
      <FormControl name={name} label={label} {...rest}>
        <Input {...field} id={name} {...inputProps} ref={ref} />
      </FormControl>
    );
  }
);

export default InputControl;