import { Center, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { history } from '../../../ApplicationRouter'
import { imageOrPlaceholder } from '../../../utils/General'
import { IOwnedNft } from '../../../models/user/ICustomerSerieAndNfts'
import { ISerieItem } from '../../../models/SerieItems/ISerieItem'
import { useMemo } from 'react'
import './NFT.css'

interface IProps {
  nft: IOwnedNft;
  serieItem: ISerieItem | undefined;
  className?: string;
}

export const CustomerNFT = (props: IProps) => {

  const { nft, serieItem, className } = props;
  const imgSource = useMemo(() => imageOrPlaceholder(serieItem?.itemFile?.path), [serieItem?.itemFile?.path])

  const width = "180px";

  return (
    <Stack gap={0} spacing={0} w={width} style={{ aspectRatio: "3/4" }} boxShadow={"0px 11px 20px 0px rgba(0,0,0,0.06)"} className={className ? className : ""} onClick={() => history.push(`nft/${nft.id}`)}>
      <Center flex="1" bgColor="gray.700" borderRadius="7px 7px 0px 0px" overflow="hidden" bgImage={imgSource} bgSize="cover" bgPos="center center" />
      <Stack p="4" h={{ md: "98px" }} bgColor={useColorModeValue("primaryBlack", "primaryWhite")} borderRadius="0px 0px 7px 7px" >
        <Text color={useColorModeValue("primaryWhite", "primaryBlack")} fontWeight={"bold"} fontFamily="Cocogoose">
          {serieItem?.translation?.title}
        </Text>
      </Stack>
    </Stack>
  )
}