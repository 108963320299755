import { AutoWidthCenter } from '../../../components/layout/AutoWidthCenter'
import { GetCustomerProfileQuery } from '../../../api/queries/user/GetProfileQuery'
import { ProfileBanner } from '../../../components/secure/profile/banner/ProfileBanner'
import { SeriesList } from '../../../components/secure/profile/collection/SeriesList'
import { Spinner, useColorModeValue } from '@chakra-ui/react'
import { useQuery } from 'react-query'

export const PersonalPage = () => {
  const backGroundColor = useColorModeValue("bgSecondaryWhite", "bgSecondaryBlack");
  const { data, isError, isLoading } = useQuery(['profile', 'customer'], GetCustomerProfileQuery)

  if (isLoading) {
    return <Spinner />
  }

  if (isError || !data) {
    return (
      <h1>ERROR loading data</h1>
    )
  }

  return (
    <>
      <ProfileBanner userInfo={data} />
      <AutoWidthCenter bg={backGroundColor}>
        <SeriesList username={data.userName} />
      </AutoWidthCenter>
    </>
  )
}