import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, useColorModeValue } from '@chakra-ui/react'
import { history } from '../../../../ApplicationRouter'
import { SerieForm } from '../../../../components/secure/dashboard/serie/SerieForm'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const CreateSerie = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <AutoWidthCenter bg={useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Series.Create')}</Heading>
          <Button onClick={() => history.back()}>{t('Return')}</Button>
        </HStack>
        <Divider />
        <SerieForm organizationId={id} />
      </Box>
    </AutoWidthCenter >
  )
}