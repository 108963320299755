import { Box, Flex, HStack, Icon, IconButton, useColorMode } from '@chakra-ui/react'
import { CiphersLogo } from '../Logo/CiphersLogo'
import { FiMenu } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

interface IProps {
  isMenuOpen: boolean;
  onClickMenu?: VoidFunction;
  menuButtonRef?: React.RefObject<HTMLButtonElement>
}

export const NavbarMobileLayout = (props: IProps) => {
  const { isMenuOpen, onClickMenu, menuButtonRef } = props;
  const { t } = useTranslation();
  const { toggleColorMode } = useColorMode();

  const MenuIcon = isMenuOpen ? MdClose : FiMenu;

  return (
    <Flex height="16" align="center" justify="center" px="5" position="relative">
      <Box mx="auto">
        {!isMenuOpen && <CiphersLogo />}
      </Box>
      <HStack position="absolute" right={4}>
        <IconButton
          ref={menuButtonRef}
          variant="ghost"
          icon={<Icon as={MenuIcon} fontSize="2xl" />}
          aria-label="Open Menu"
          onClick={onClickMenu}
          color="white"
        />
      </HStack>
    </Flex>
  )
}