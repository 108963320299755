import { AutoWidthCenter } from '../../components/layout/AutoWidthCenter'
import { Box, Heading, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <AutoWidthCenter>
      <Box px={{base: 8,md: 0}}  py={{base: 4,md: 4}}>
        <Heading color="whiteAlpha.700">{t("Not_Found.Title")}</Heading>
        <Text as='b' paddingTop={{ base: "10px" }} color="whiteAlpha.700">{t("Not_Found.Requested_Page_Either_Transferred_Or_Unavailable")}</Text>
        <Text paddingTop={{ base: '10px' }} color="whiteAlpha.700">{t("Not_Found.Find_Through_Home_Page")}.</Text>
        <ul>
          <li>{t("Go_To")} <Link to="/">{t("Navbar.Authorized.Home")}</Link>.</li>
        </ul>
      </Box>
    </AutoWidthCenter>
  )
}
