import { Link as ChakraLink, Tooltip } from '@chakra-ui/react'
import { Link as ReachLink } from 'react-router-dom'

interface IProps {
  to: string
  toolTipText: string
  children: React.ReactNode
}

export const ToolTipRoutButton = (props: IProps) => {

  return (
    <ReachLink to={props.to}>
      <Tooltip label={props.toolTipText}>
        <ChakraLink>
          {props.children}
        </ChakraLink>
      </Tooltip>
    </ReachLink>
  );
}