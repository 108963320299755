import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react'
import { useModal } from '../../../context/ModalContext'

export const ModalContainer = () => {
  const { state, dispatch } = useModal();

  return (
    <>
      <Modal size="xl" isOpen={state.open} onClose={() => dispatch({ type: 'close' })}>
        <ModalOverlay />
        <ModalContent backgroundColor={useColorModeValue("bgSecondaryWhite", "bgTertiaryBlack")}>
          <ModalHeader>{state.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {state.bodyContent}
          </ModalBody>
          <ModalFooter>
            {state.footerContent}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}