import { CreateFaqCategory } from '../../pages/secure/dashboard/faq/CreateFaqCategory'
import { CreateFaqQuestion } from '../../pages/secure/dashboard/faq/CreateFaqQuestion'
import { EditFaqCategory } from '../../pages/secure/dashboard/faq/EditFaqCategory'
import { EditFaqQuestion } from '../../pages/secure/dashboard/faq/EditFaqQuestion'
import { FaqCategories } from '../../pages/secure/dashboard/faq/FaqCategories'
import { FaqQuestions } from '../../pages/secure/dashboard/faq/FaqQuestions'
import { IRoute } from '../../models/routes/IRoute'
import { Roles } from '../../enums/Roles'

export const FaqRoutes : IRoute[] = [
  {
    element: <FaqCategories />,
    path: "faq",
    permission: [Roles.ADMIN],
    showInMenu: true,
    title: "Faq"
  },
  {
    element: <CreateFaqCategory />,
    path: "faq/category/new",
    permission: [Roles.ADMIN],
    title: "Faq_Category"
  },
  {
    element: <EditFaqCategory />,
    path: "faq/category/edit/:id",
    permission: [Roles.ADMIN],
    title: "Faq_Category"
  },
  {
    element: <FaqQuestions />,
    path: "faq/:id/question",
    permission: [Roles.ADMIN],
    title: "Faq_Question"
  },
  {
    element: <CreateFaqQuestion />,
    path: "faq/:id/question/new",
    permission: [Roles.ADMIN],
    title: "Faq_Category"
  },
  {
    element: <EditFaqQuestion />,
    path: "faq/:categoryId/question/edit/:questionId",
    permission: [Roles.ADMIN],
    title: "Faq_Category"
  },
]