import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { AnimatePresence, motion } from 'framer-motion'
import { Box, Button, Divider, Flex, HStack, IconButton, Stack, Text, Tooltip, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react'
import { ChakraMotionDiv } from '../../../Framer/ChakraMotionDiv'
import { ChakraSerieItem } from '../../../serieItem/ChakraSerieItem'
import { CustomerChakraNFT } from '../../nft/CustomerChakraNFT'
import { history } from '../../../../ApplicationRouter'
import { HScroll } from '../../../HorizontalScroll/HScroll'
import { ICustomerSerieAndNfts, ICustomerSerieItemAndNfts } from '../../../../models/user/ICustomerSerieAndNfts'
import { ISerieItem } from '../../../../models/SerieItems/ISerieItem'
import { MotionSimpleGrid } from '../../../Framer/FramerMotionGrid'
import { useQueryClient } from 'react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdatePrivateSerieMutation } from '../../../../api/queries/serie/SerieQueries'

interface IProps {
  customerSerieAndNft: ICustomerSerieAndNfts;
  username: string;
  serieItemRowCount: number;
}

export const ProfileSerie = (props: IProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const serie = props.customerSerieAndNft;
  const { colorMode } = useColorMode();
  const [nftStackOpen, setNftStackOpen] = useState<string>();
  const [selectedSerieItem, setSelectedSerieItem] = useState<ISerieItem>();
  const useUpdatePrivateSerie = useUpdatePrivateSerieMutation();
  const queryClient = useQueryClient();
  const toast = useToast();

  const variants = {
    closed: { height: props.serieItemRowCount === 1 ? "250px" : "fit-content", maxH: props.serieItemRowCount === 1 ? "" : "500", overflowY: "hidden" },
    open: { height: "fit-content", overflowY: "hidden" },
  }

  const isPrivateSerie = serie.privateSerieIds.includes(serie.simpleSerie.id);

  const handleUpdatePrivateSerie = () => {
    useUpdatePrivateSerie.mutate(serie.simpleSerie.id, {
      onSuccess() {
        queryClient.invalidateQueries(["customer", props.username])
        toast({
          title: t("Customer.Series"),
          description: t("Customer.Toast.Private_Serie_Update_Success"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: t("Customer.Series"),
          description: t("Customer.Toast.Private_Serie_Update_Error"),
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const cardOnClick = (serieItem: ICustomerSerieItemAndNfts) => {
    if (serieItem.ownedNfts.length > 1) {
      setSelectedSerieItem(serieItem.serieItem);
      setNftStackOpen(serieItem.serieItem.id);
    } else if (serieItem.ownedNfts.length === 1) {
      history.push(`nft/${serieItem.ownedNfts[0].id}`);
    } else {
      history.push(`serie-item/${serieItem.serieItem.id}`);
    }
  }

  return (
    <Box w="100%" as="section" pb={{ base: '4', md: '8' }} bgColor={useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack")}>
      <Stack spacing="5">
        <Box display="flex" flex="1" justifyContent="space-between" alignItems="center">
          <span>
            <Text fontSize="lg" fontWeight="medium">
              {serie.simpleSerie.translation.title}
            </Text>
            <Text color="muted" fontSize="sm">
              {serie.simpleSerie.translation.description}
            </Text>
          </span>
          <HStack pr='4' >
            <Text>{`${serie.customerSerieItems.filter(x => x.ownedNfts.length > 0).length ?? 0}/${serie.customerSerieItems.length ?? 0} ${t("Series.Owned")}`}</Text>
            {isPrivateSerie ?
              <Tooltip hasArrow label={t('Customer.Serie_Public')}>
                <IconButton
                  variant="ghost"
                  icon={<AiOutlineEye size={25} onClick={() => handleUpdatePrivateSerie()} />}
                  aria-label="Open Menu"
                />
              </Tooltip>
              :
              <Tooltip hasArrow label={t('Customer.Serie_Private')}>
                <IconButton
                  variant="ghost"
                  icon={<AiOutlineEyeInvisible size={25} onClick={() => handleUpdatePrivateSerie()} />}
                  aria-label="Open Menu"
                />
              </Tooltip>
            }
          </HStack>
        </Box>
        <Divider />
      </Stack>
      <Flex flexDir="column" alignItems="center">
        <AnimatePresence>
          {nftStackOpen !== undefined && (
            <ChakraMotionDiv layoutId={nftStackOpen} p="4" mt="5" borderRadius="14px" width={{ xl: "1027px", lg: "833px", md: "623px", sm: "90%", base: "90%" }} h="340px" bgColor={colorMode === "dark" ? "bgTertiaryBlack" : "bgTertiaryWhite"} opacity="1" pos="absolute" zIndex="2">
              <Flex direction="column" justifyContent="center" h="100%" alignItems="center">
                <HScroll>
                  {serie.customerSerieItems.find(x => x.serieItem.id === nftStackOpen)!.ownedNfts.map((nft) => {
                    return (
                      <CustomerChakraNFT key={`${nft.id}-hscrll`} zIndex={1} mr="4" mb="4" nft={nft} serieItem={selectedSerieItem} />
                    )
                  })}
                </HScroll>
                <Button variant="primary" onClick={() => setNftStackOpen(undefined)}> {t("Close")} </Button>
              </Flex>
            </ChakraMotionDiv>
          )}
        </AnimatePresence>
        <MotionSimpleGrid w="100%" columns={{ base: 2, md: 3, lg: 4, xl: 5 }} spacing={6} justifyItems="center" pb="4" mt="4" animate={isOpen ? "open" : "closed"} variants={variants} overflowX="clip">
          {serie.customerSerieItems.map((customerSerieItems, i) => {
            return (
              <motion.div layoutId={customerSerieItems.serieItem.id} onClick={() => cardOnClick(customerSerieItems)} key={`${customerSerieItems.serieItem.id}-mtndv`}>
                <ChakraSerieItem key={customerSerieItems.serieItem.id} zIndex={1} className={customerSerieItems.ownedNfts.length > 1 ? (colorMode === "dark" ? " serieItem-stack-dark" : "serieItem-stack") : ""} customerSerieItem={customerSerieItems} />
              </motion.div>
            )
          })}
        </MotionSimpleGrid>
        {((props.serieItemRowCount === 1 && serie.customerSerieItems.length > 5) || (props.serieItemRowCount === 2 && serie.customerSerieItems.length > 10)) &&
          <Button mt="4" onClick={() => setIsOpen(!isOpen)} variant="primary">{!isOpen ? t("Show_More") : t("Show_Less")}</Button>
        }
      </Flex>
    </Box>
  )
}