import { apiClient } from '../../base/ApiClients'
import { ICustomerProfile, IPublicProfile } from '../../../models/user/ICustomerProfile'
import { ICustomerSerieAndNfts } from '../../../models/user/ICustomerSerieAndNfts'
import { ICustomerSimple } from './../../../models/user/ICustomerProfile'

export const GetCustomerProfileQuery = async () => {
  const { data } = await apiClient.get<ICustomerProfile>("profile/customer");
  return data;
}

export const GetPublicProfile = async (userName: string) => {
  const { data } = await apiClient.get<IPublicProfile>(`profile/public/${userName}`)
  return data;
}

export const GetSerieAndNftsForCustomerQuery = async (userName: string) => {
  const { data } = await apiClient.get<ICustomerSerieAndNfts[]>(`customer/${userName}/series`);
  return data;
}

export const GetPublicSerieAndNftsForCustomerQuery = async (userName: string) => {
  const { data } = await apiClient.get<ICustomerSerieAndNfts[]>(`customer/${userName}/public/series`);
  return data;
}

export const GetSimpleCustomerProfileQuery = async () => {
  const { data } = await apiClient.get<ICustomerSimple>("profile/customer/simple");
  return data;
}