import { AutoWidthCenter } from '../../../components/layout/AutoWidthCenter'
import { Box, Divider, Heading, useColorModeValue } from '@chakra-ui/react'
import { DashboardStatistics } from './DashboardStatistics'
import { RenderGuard } from '../../../components/common/guards/RenderGuard'
import { Roles } from '../../../enums/Roles'

export const DashboardHome = () => {
  return (
    <Box bgColor={useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack")} pt="5">
      <AutoWidthCenter>
        <Heading as="h1">Dashboard</Heading>
        <Divider />
        <RenderGuard roles={Roles.ADMIN}>
          <DashboardStatistics />
        </RenderGuard>
        <RenderGuard roles={Roles.CUSTOMER}>
          <p>Customer Dashboard</p>
        </RenderGuard>
        <RenderGuard roles={Roles.ORGANIZATIONMANAGER}>
          <DashboardStatistics />
        </RenderGuard>
      </AutoWidthCenter>
    </Box>
  );
};