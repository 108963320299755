import { Blockchain, EmailStamp, Environment, IdLayer, Marketplace, Nft, Price, Royalties, Social, Unique, Utility } from '../../../assets/icons/components'
import { Icon } from '@chakra-ui/react'

export interface ContentItem {
  title: string,
  content: string,
  icon?: JSX.Element
}

export const contentItems: ContentItem[] = [
  {
    title: "B2B.Features.1.Title",
    content: "B2B.Features.1.Description",
    icon: <Icon as={Blockchain} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.Features.2.Title",
    content: "B2B.Features.2.Description",
    icon: <Icon as={Marketplace} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.Features.3.Title",
    content: "B2B.Features.3.Description",
    icon: <Icon as={Unique} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.Features.4.Title",
    content: "B2B.Features.4.Description",
    icon: <Icon as={Utility} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.Features.5.Title",
    content: "B2B.Features.5.Description",
    icon: <Icon as={Price} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.Features.6.Title",
    content: "B2B.Features.6.Description",
    icon: <Icon as={Royalties} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.Features.7.Title",
    content: "B2B.Features.7.Description",
    icon: <Icon as={Social} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.Features.8.Title",
    content: "B2B.Features.8.Description",
    icon: <Icon as={Environment} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
]

export const accordionItems: ContentItem[] = [
  {
    title: "B2B.Home.Questions.1.Title",
    content: "B2B.Home.Questions.1.Description"
  },
  {
    title: "B2B.Home.Questions.2.Title",
    content: "B2B.Home.Questions.2.Description"
  },
  {
    title: "B2B.Home.Questions.3.Title",
    content: "B2B.Home.Questions.3.Description"
  },
  {
    title: "B2B.Home.Questions.4.Title",
    content: "B2B.Home.Questions.4.Description"
  },
  {
    title: "B2B.Home.Questions.5.Title",
    content: "B2B.Home.Questions.5.Description"
  }
]

export const contentItemsPostalActionsPage: ContentItem[] = [
  {
    title: "B2B.PostalAction.PilotOffering.1.Title",
    content: "B2B.PostalAction.PilotOffering.1.Description",
    icon: <Icon as={Utility} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.PostalAction.PilotOffering.2.Title",
    content: "B2B.PostalAction.PilotOffering.2.Description",
    icon: <Icon as={EmailStamp} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.PostalAction.PilotOffering.3.Title",
    content: "B2B.PostalAction.PilotOffering.3.Description",
    icon: <Icon as={Nft} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.PostalAction.PilotOffering.4.Title",
    content: "B2B.PostalAction.PilotOffering.4.Description",
    icon: <Icon as={Unique} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.PostalAction.PilotOffering.5.Title",
    content: "B2B.PostalAction.PilotOffering.5.Description",
    icon: <Icon as={IdLayer} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
  {
    title: "B2B.PostalAction.PilotOffering.6.Title",
    content: "B2B.PostalAction.PilotOffering.6.Description",
    icon: <Icon as={Social} color="ciphers.main.green" w={8} h={8} mr="auto" mb={2} />
  },
]
