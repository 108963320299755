import { BaseProps, FormControl } from './FormControl'
import { forwardRef, useMemo } from 'react'
import { Select, SelectProps } from '@chakra-ui/react'
import { useField } from 'formik'

export interface IDropdownItem {
  name: string;
  value: string;
  key: string;
}

export type DropdownControlProps = BaseProps & {
  selectProps?: SelectProps;
  options: IDropdownItem[];
};

export const DropdownControl: React.FC<DropdownControlProps> = forwardRef(
  (props: DropdownControlProps, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const { name, label, selectProps, options, ...rest } = props;
    const [field] = useField(name);

    const content = useMemo(() => {
      return options.map((opt) => (
        <option key={opt.key} value={opt.value}>{opt.name}</option>
      ))
    }, [options])

    return (
      <FormControl name={name} label={label} {...rest}>
        <Select {...field} id={name} ref={ref} {...selectProps}>
          {content}
        </Select>
      </FormControl>
    );
  }
);

export default DropdownControl;