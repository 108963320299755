import { apiClient } from '../../api/base/ApiClients'
import { AuthContext } from '../../hooks/useAuth'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useContext, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

interface IProps {
  children: JSX.Element
}

//The configuration for the interceptors could be done in de ApiClients file since its Axios config and not React related.
//But making an extra component and configuring the interceptors in this place gives us access to hooks :)!
export const AxiosInterceptor = (props: IProps) => {
  const tokenContext = useContext(AuthContext);
  const toast = useToast();

  useEffect(() => {
    const reqInterceptor = (config: AxiosRequestConfig) => {
      if (tokenContext.token !== "" && config.headers) {
        config.headers["Authorization"] = `Bearer ${tokenContext.token}`;
      }
      return config;
    }

    const resInterceptor = (response: AxiosResponse) => {
      if (response) {
        const newToken = response.headers["set-authentication"];
        if (newToken !== undefined) {
          tokenContext.setToken(newToken);
        }
      }
      return response;
    }

    // TODO: deze error interceptor wordt momenteel voor elke entry aangeroepen.
    // Door React-query wordt standaard 3x de request uitgevoerd, wat resulteert in 3 popups
    const errInterceptor = (error: AxiosError) => {
      //algemene errors kunnen we hier zetten. Geen verbinding met api bijvoorbeeld.
      if (error.message === "Network Error")
        toast({
          title: "Netwerk error",
          description: "De api kan niet worden bereikt",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top"
        })
      return Promise.reject(error);
    }

    const requestInterceptor = apiClient.interceptors.request.use(reqInterceptor);
    const responseInterceptor = apiClient.interceptors.response.use(resInterceptor, errInterceptor);

    return () => {
      apiClient.interceptors.request.eject(requestInterceptor);
      apiClient.interceptors.response.eject(responseInterceptor);
    }
  }, [toast, tokenContext])
  return props.children
}
