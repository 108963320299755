import { AxiosError } from 'axios'
import { Box, Center, Spinner, useToast, VStack } from '@chakra-ui/react'
import { ContactSection } from '../../../components/b2b/ContactSection'
import { Hero } from './sections/Hero'
import { MarketingCodeFormValues } from '../../../models/b2b/MarketingCode'
import { Partnership } from './sections/Partnership'
import { PilotOfferings } from './sections/PilotOfferings'
import { Roadmap } from './sections/Roadmap'
import { SellingPoints } from './sections/SellingPoints'
import { StartFreePilot } from './sections/StartFreePilot'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useMarketingCodeMutation } from '../../../api/queries/b2b/useMarketingCode'
import { useNavigate, useParams } from 'react-router-dom'

type CodeParams = {
  claimCode: string
}

export const PostalAction = () => {
  const codeSubmit = useMarketingCodeMutation()
  const toast = useToast();
  const { t } = useTranslation()
  let { claimCode } = useParams<CodeParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!claimCode) return navigate("/")

    const valueToSubmit: MarketingCodeFormValues = {
      code: claimCode
    }

    codeSubmit.mutate(valueToSubmit, {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404) {
          navigate("/")
          toast({
            title: t("B2B.Home.Stamps.Error.Title"),
            description: t("B2B.Home.Stamps.Error.Description"),
            status: "error",
            colorScheme: "red",
            duration: 3 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      }
    })

  }, [claimCode])

  if (codeSubmit.isLoading) {
    return (
      <Center backgroundColor="bgPrimary" height={"20rem"}>
        <Spinner height="10rem" width={"10rem"} color="#4129ff" />
      </Center>
    )
  }

  if (codeSubmit.isSuccess) {
    return (
      <VStack spacing={16} backgroundColor="bgPrimary">
        <Hero />
        <SellingPoints />
        <PilotOfferings />
        <StartFreePilot />
        <Partnership />
        <Roadmap />
        <ContactSection
          button1Url='/contact/1'
          text={
            <Trans i18nKey="B2B.PostalAction.Contact.Title">
              Placeholder <span className='magic'></span>
            </Trans>
          }
        />
      </VStack >
    )
  }

  return (
    <Box backgroundColor="bgPrimary">
      <Spinner size='xl' />
    </Box>
  )
}
