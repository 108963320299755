import { Box, Flex, Grid, GridItem, Text, useColorMode, useInterval } from '@chakra-ui/react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { IFeaturedSerie } from '../../models/Serie/ISerie'
import { imageOrPlaceholder } from '../../utils/General'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import './Animation.css'

export const SeriePreview = (props: IFeaturedSerie) => {
  const [numbers, setNumbers] = useState<number[]>(Array.from(Array(Math.min(props.serieItems.length, 4)).keys()));
  const [activeStep, setActiveStep] = useState(0);
  const { colorMode } = useColorMode();

  useInterval(() => {
    const nums = new Set<number>();
    while (nums.size !== Math.min(props.serieItems.length, 4)) {
      nums.add(Math.floor(Math.random() * props.serieItems.length));
    }
    setActiveStep(activeStep + 1)
    setNumbers(Array.from(nums))
  }, 5000);

  return (
    <Flex w="145px" h="206px" flexDir="column" borderRadius="6">
      <Grid templateColumns={`repeat(2, 2fr)`} gap={1} flex="1" h="145px" borderRadius="7px">
        {numbers.map(i => (
          <GridItem w='100%' h='100%' overflow="hidden" key={i}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={`${activeStep}-trans-gemeente`}
                addEndListener={(node, done) => {
                  node.addEventListener("transitionend", done, false);
                }}
                classNames="opacity"
              >
                <Box w="100%" h="100%" bgImage={imageOrPlaceholder(props.serieItems[i].itemFile.path)} bgSize="cover" borderRadius="7px" />
              </CSSTransition>
            </SwitchTransition>
          </GridItem>
        ))}
      </Grid>
      <Box p="2" textAlign={"center"} pb="4" px="4" borderBottomRadius="6" h="61px">
        <Text as={Link} to={`./serie/${props.serieId}`} cursor="pointer" noOfLines={1} fontSize="sm" color={colorMode === "dark" ? "whiteAlpha.800" : "blackAlpha.800"} fontWeight="bold">{props.title}</Text>
        <Text fontSize="sm" color={colorMode === "dark" ? "whiteAlpha.800" : "blackAlpha.800"} noOfLines={1}>
          {props.description}
        </Text>
      </Box >
    </Flex >
  )
}