import { Box, Divider, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { ProfileSeries } from './ProfileSeries'
import { useTranslation } from 'react-i18next'

interface IProps {
  username: string;
}

export const SeriesList = (props: IProps) => {

  const { t } = useTranslation()

  return (
    <>
      <Box px={{ base: 6, md: 8 }} w="100%" as="section" pt={{ base: '4', md: '4' }} pb={{ base: '4', md: '4' }} bgColor={useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack")}>
        <Stack spacing="5">
          <Box>
            <Text fontSize="2xl" fontWeight="semibold">
              {t("Series.Owned_Series")}
            </Text>
          </Box>
          <Divider />
        </Stack>
      </Box>

      <ProfileSeries username={props.username} />
    </>
  )
}