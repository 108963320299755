import React from 'react'
import { Box, chakra, Flex, Heading, Text } from '@chakra-ui/react'

interface IProps {
  icon: any;
  header: string;
  text: string | JSX.Element;
}

export const Action = chakra(({ icon, header, text, ...props }: IProps) => {
  return (
    <Flex backgroundColor="#02023d" py={4} px={6} direction="column" mb={{ base: 4, lg: 0 }} {...props}>
      {icon}
      <Box mr="auto">
        <Heading color="white" as="h3" mb={2}>{header}</Heading>
        <Text color="ciphers.main.textSecondary">{text}</Text>
      </Box>
    </Flex>
  )
})
