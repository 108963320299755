import { matchPath, useLocation } from 'react-router-dom'
import { RoutesB2B, RoutesB2BConst } from '../routes/b2bSubRoutes/SubRoutesB2B'

/**A hook that returns a boolean indicating if the current path matches the given URL.
 * @param {string} url - The URL to check against the current path.
 * @returns {boolean} - A boolean indicating if the current path matches the given URL.*/
export const useCurrentPath = (url: string): boolean => {
  const location = useLocation()
  const currentPathObject = matchPath({ path: url, }, location.pathname)

  return url === currentPathObject?.pattern.path
}

type RouteDict = {
  [k in PossibleRoutesB2B]: { isCurrentRoute: boolean };
}

export type PossibleRoutesB2B = typeof RoutesB2BConst[number]['path'];

/**
 * useGetCurrentRoutes is a React Hook that returns an object containing the current routes with a boolean value indicating whether the route is currently active.
 *
 *The comments for this function are generated by chatGPT.
 * @returns {Object} An object with the routes and their corresponding isCurrentRoute boolean value.
 */
export const useGetCurrentRoutes = (): RouteDict => {
  // Get the current location from the `useLocation` Hook
  const location = useLocation();
  // Create an empty dictionary to store the routes and their isCurrentRoute value
  const dict: RouteDict = Object.assign({}, ...Object.keys(RoutesB2B).map(route => ({ [route]: { isCurrentRoute: false } })));
  // Iterate over the routes and check if the current path matches the route path
  RoutesB2B.map((route => {
    // Use `matchPath` to check if the current path matches the route path
    const currentPathObject = matchPath({ path: route.path! }, location.pathname);
    // Special case for the root path ("/")
    if (location.pathname === "/" && route.path === "/postal-organisations") {
      return dict[route.path as PossibleRoutesB2B] = { isCurrentRoute: true };
    } else if (location.pathname === "/contact/1" && route.path === "/contact") {
      return dict[route.path as PossibleRoutesB2B] = { isCurrentRoute: true };
    }
    // Add the route and its isCurrentRoute value to the dictionary
    return dict[route.path as PossibleRoutesB2B] = { isCurrentRoute: route.path === currentPathObject?.pattern.path };
  }));
  // Return the dictionary containing the routes and their isCurrentRoute value
  return dict;
}