import headerBackground from '../../../../assets/b2b/about-us/about-us-bg.png'
import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box } from '@chakra-ui/react'
import { Hero } from './Hero'

export const Header = () => {
  return (
    <Box as="section" id="about-us-header" bgImage={`url("${headerBackground}")`} bgRepeat="no-repeat" bgPos={{ base: "100% -10%", lg: "bottom right" }} bgSize={{ base: "125%", lg: "40%" }} mb={8}>
      <AutoWidthCenter>
        <Hero />
      </AutoWidthCenter>
    </Box >
  )
}