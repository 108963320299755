import { AutoWidthCenter } from '../../../components/layout/AutoWidthCenter'
import { Companies } from './Sections/Companies'
import { ContactSection } from '../../../components/b2b/ContactSection'
import { Header } from './Sections/Header'
import { JobListings } from './Sections/JobListings'
import { Trans } from 'react-i18next'

export const AboutUsB2B = () => {
  return (
    <>
      <Header />
      <AutoWidthCenter>
        <Companies />
        <JobListings />
        <ContactSection
          text={
            <Trans i18nKey="B2B.Contact.Title">
              Placeholder <span className='magic'></span>
            </Trans>
          } />
      </AutoWidthCenter>
    </>
  )
}