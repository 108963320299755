export enum ESerieHighlightSpot {
  None = 0,
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
}

export interface ISerieHighlightSpotEnum {
  highlightSpot: ESerieHighlightSpot;
  name: string;
}

const item = (highlightSpot: ESerieHighlightSpot, name: string): ISerieHighlightSpotEnum => ({ highlightSpot, name });

export const SerieHighlightSpotEnums = (includeNone: boolean = false): ISerieHighlightSpotEnum[] => {
  let list: ISerieHighlightSpotEnum[] = [];
  if (includeNone) {
    list.push(item(ESerieHighlightSpot.None, ESerieHighlightSpot[ESerieHighlightSpot.None]));
  }
  list.push(item(ESerieHighlightSpot.First, ESerieHighlightSpot[ESerieHighlightSpot.First]));
  list.push(item(ESerieHighlightSpot.Second, ESerieHighlightSpot[ESerieHighlightSpot.Second]));
  list.push(item(ESerieHighlightSpot.Third, ESerieHighlightSpot[ESerieHighlightSpot.Third]));
  list.push(item(ESerieHighlightSpot.Fourth, ESerieHighlightSpot[ESerieHighlightSpot.Fourth]));
  list.push(item(ESerieHighlightSpot.Fifth, ESerieHighlightSpot[ESerieHighlightSpot.Fifth]));

  return list;
}