import { AiFillFolderOpen } from 'react-icons/ai'
import { DataView, IDataViewColumn } from '../../../common/dataview/Dataview'
import { FiEdit2 } from 'react-icons/fi'
import { GetDashboardOrganizationsQuery } from '../../../../api/queries/organization/OrganizationQueries'
import { Heading, IconButton, Spinner } from '@chakra-ui/react'
import { IDashboardOrganization } from '../../../../models/organization/IOrganization'
import { NavLink } from 'react-router-dom'
import { routePrefixDashboard } from '../../../../ApplicationRouter'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const OrganizationTable = () => {
  const { t } = useTranslation();
  const [columns] = useState<IDataViewColumn[]>([
    { headerName: t("Dataview.Headers.Organization_Name"), field: "name", flex: 1, tooltipField: "name" },
    { headerName: t("Dataview.Headers.Organization_Description"), field: "description", flex: 1, tooltipField: "description" },
    {
      headerName: t("Dataview.Headers.Organization_Active_Series"), valueGetter: function (tableData: any) {
        const organization: IDashboardOrganization = tableData.data;
        return `${organization.totalActiveSeries}/${organization.totalSeries}`
      }, flex: 1
    },
    { headerName: t("Dataview.Headers.Actions"), cellRenderer: "actions", flex: 0.5 }
  ]);

  const { data, isLoading } = useQuery(['organizations-dashboard'], GetDashboardOrganizationsQuery);

  const renderCellAction = (tableData: any) => {
    const organization: IDashboardOrganization = tableData.data;
    return (
      <>
        <IconButton
          icon={<FiEdit2 fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Edit member"
          as={NavLink}
          to={`edit/${organization.id}`}
        />
        <IconButton
          icon={<AiFillFolderOpen fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Edit member"
          as={NavLink}
          to={`${routePrefixDashboard}/serie/${organization.id}`}
        />
      </>
    )
  }

  if (isLoading) return <Spinner />
  if (!isLoading && !data) return <Heading as="h1">{t("Dataview.Default_Empty_Message")}</Heading>

  return (
    <DataView
      actionsRenderer={renderCellAction}
      columns={columns}
      data={data}
    />
  )
}