import { Box, Button, Flex, useColorModeValue, useToast, VStack } from '@chakra-ui/react'
import { FaqQuestionFormValues, IFaqQuestion } from '../../../../models/faq/IFaq'
import { Form, Formik } from 'formik'
import { history } from '../../../../ApplicationRouter'
import { InputControl } from '../../../common/formik-chakra'
import { useCreateFaqQuestionMutation, useEditFaqQuestionMutation } from '../../../../api/queries/faq/FaqQueries'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { validationSchema } from './FaqQuestion.Validation'

interface IProps {
  faqQuestion?: IFaqQuestion;
  categoryId: string | undefined;
}

export const FaqQuestionForm = (props: IProps) => {
  const [formValues, setFormValues] = useState(new FaqQuestionFormValues())
  const boxBackground = useColorModeValue("bgSecondaryWhite", "bgSecondaryBlack");
  const handleCreateFaqQuestion = useCreateFaqQuestionMutation();
  const handleEditFaqQuestion = useEditFaqQuestionMutation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.faqQuestion) setFormValues(new FaqQuestionFormValues(props.faqQuestion))
  }, [props.faqQuestion])

  const handleSubmit = async (values: FaqQuestionFormValues) => {
    if(props.categoryId)
      values.categoryId = props.categoryId;
    if (props.faqQuestion) {
      handleEditFaqQuestion.mutate({ id: props.faqQuestion.id, formValues: values }, {
        onSuccess() {
          queryClient.invalidateQueries(['faq/questions'])
          history.back();
          toast({
            title: t("Faq_Question"),
            description: t("Faq_Question.Toast.Edit_Success"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Faq_Question"),
            description: t("Faq_Question.Toast.Edit_Error"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    } else {
      handleCreateFaqQuestion.mutate(values, {
        onSuccess() {
          queryClient.invalidateQueries(['faq/questions'])
          history.back();
          toast({
            title: t("Faq_Question"),
            description: t("Faq_Question.Toast.Create_Success"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: t("Faq_Question"),
            description: t("Faq_Question.Toast.Create_Error"),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Box bgColor={boxBackground} p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="translation.question" label="Question" isRequired />
                <InputControl name="translation.answer" label="Answer" isRequired />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">{t("Submit")}</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  )
}