import { Box, Flex, Heading, Image, Link, Text, useBreakpointValue, VStack } from '@chakra-ui/react'


interface IProps {
  company: string,
  companyLogo?: string,
  companySite: string,
  description: string,
  image: string,
  reverse?: boolean,
  aspectRatio?: JSX.Element
}

export const Company = (props: IProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const paddingLeft = isMobile ? "0rem" : !props.reverse ? "2rem" : "9rem"
  const width = isMobile ? "100%" : `calc(100% - ${props.reverse ? "2rem" : "9rem"})`
  const imagePaddingY = isMobile ? 0 : 16
  const imageMargin = isMobile ? 0 : props.reverse ? "0rem -8rem 0rem 0rem" : " 0rem 0rem 0rem -8rem"
  const paddingContentContainer = isMobile ? "2rem 1rem 3rem 1rem" : `6rem 0rem 6rem ${paddingLeft}`

  return (
    <Flex flexFlow={props.reverse ? "row-reverse wrap" : "row wrap"}>
      <Box flexBasis={isMobile ? "100%" : "60%"} bgColor="bgSecondary" height={{ base: "auto", lg: "2xl" }} order={{ base: 2, lg: 1 }}>
        <VStack w={width} p={paddingContentContainer} textAlign="left" alignItems="flex-start">
          <Heading as="h3" size="sm" color="#55579e" fontWeight="600">
            {props.company.toUpperCase()}
          </Heading>
          <br />
          {
            props.companyLogo && (
              <Link isExternal href={props.companySite}>
                <Image src={props.companyLogo} h="16" w="auto" />
              </Link>
            )
          }
          {
            !props.companyLogo && (
              <Heading as="h1">
                {props.company}
              </Heading>
            )
          }
          <br />
          <Text color="white">
            {props.description}
          </Text>
        </VStack>
      </Box>
      <Box flexBasis={isMobile ? "100%" : "calc(40% + 8rem)"} py={imagePaddingY} margin={imageMargin} zIndex={1} position="relative" height={{ base: "xs", lg: "auto" }} order={{ base: 1, lg: 1 }} >
        <Box display="block" as={Link} isExternal href={props.companySite} bgImage={`url(${props.image})`} bgSize="cover" bgPosition="center" w="100%" h="100%" zIndex={1} />
        {props.aspectRatio}
      </Box>
    </Flex>
  )
}