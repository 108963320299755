import { BaseProps, FormControl } from '../formik-chakra'
import { Box, Center, FormLabel, Image, InputGroup, useBreakpointValue, VStack } from '@chakra-ui/react'
import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { IImageResizerOptions, ImageShape } from '../../../models/general/IImageResizerOptions'
import { imageOrPlaceholder } from '../../../utils/General'
import { useField } from 'formik'

type FileUploadProps = {
  accept?: string
  multiple?: boolean
  imagePath?: string;
  imageResizerOptions?: IImageResizerOptions
  imageShape: ImageShape;
}

export type UploadImageProps = BaseProps & FileUploadProps;

export const UploadImage: React.FC<UploadImageProps> = forwardRef(
  (props: UploadImageProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, label, multiple = false, accept = 'image', imagePath, imageResizerOptions, imageShape, ...rest } = props
    const [{ onChange, ...field }, , { setValue }] = useField(name)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [newImage, setNewImage] = useState<any>("");
    const isDesktop = useBreakpointValue({ base: false, md: true })

    useEffect(() => {
      setNewImage(imageOrPlaceholder(props.imagePath, imageResizerOptions))
    }, [props.imagePath])

    const handleClick = () => {
      inputRef.current?.click()
    }

    const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
      const file: any = value.target.files?.[0];

      if (file) {
        value.target.files && setValue(file);

        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = (e) => {
          setNewImage(reader.result);
        }
      }
    }

    return (
      <FormControl name={name} {...rest} {...ref}>
        <InputGroup onClick={handleClick}>
          <input
            onChange={handleChange}
            type='file'
            accept={accept}
            multiple={multiple}
            id={name}
            ref={inputRef}
            hidden />
          <VStack>
            <FormLabel>{label}</FormLabel>
            {imageShape === ImageShape.circle &&
              <>
                {isDesktop ?
                  <Box position="relative" >
                    <Image fit={'cover'} height="160px" width="160px" minW="160px" minH="160px" src={newImage} alt={name} borderRadius="full" position="absolute" transform="translate(-50%, 0)" border="2px" />
                    <Center minH="160px" minW="160px" maxW="160px" maxH="160px" backgroundColor={'blackAlpha.700'} borderRadius="full" border="2px" position="absolute" transform="translate(-50%, 0)" opacity="0" transition=".2s ease" _hover={{ opacity: ".7", cursor: "pointer" }}>
                      <FiEdit2 size={30} />
                    </Center>
                  </Box>
                  :
                  <Image fit={'cover'} minH="160px" minW="160px" maxW="160px" maxH="160px" src={newImage} alt={name} borderRadius="full" border="2px" />
                }
              </>
            }
            {imageShape === ImageShape.rectangle &&
              <>
                {isDesktop ?
                  <Box position="relative" >
                    <Image fit={'cover'} minH="120px" minW="160px" maxW="160px" maxH="120px" src={newImage} alt={name} borderRadius="2xl" border="2px" position="absolute" transform="translate(-50%, 0)" />
                    <Center minH="120px" minW="160px" maxW="160px" maxH="120px" backgroundColor={'blackAlpha.700'} borderRadius="2xl" border="2px" position="absolute" transform="translate(-50%, 0)" opacity="0" transition=".2s ease" _hover={{ opacity: ".7", cursor: "pointer" }}>
                      <FiEdit2 size={30} />
                    </Center>
                  </Box>
                  :
                  <Image fit={'cover'} minH="120px" minW="160px" maxW="160px" maxH="120px" src={newImage} alt={name} borderRadius="2xl" border="2px" />
                }
              </>
            }
          </VStack>
        </InputGroup>
      </FormControl>
    )
  }
)

export default UploadImage