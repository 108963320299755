import { Box, SimpleGrid, useColorModeValue } from '@chakra-ui/react'
import { GetStatisticsCountQuery } from '../../../api/queries/statistics/StatisticQueries'
import { StatisticsCard } from '../../../components/secure/dashboard/statistics/StatisticsCard'
import { useQuery } from 'react-query'

export const DashboardStatistics = () => {
  const { data: statisticsCount, error: errorStatisticsCount, isError: isErrorStatisticsCount, isLoading: isLoadingStatisticsCount } = useQuery(['statistics'], GetStatisticsCountQuery);
  const cardBackgroundColor = useColorModeValue('bgSecondaryWhite', 'bgSecondaryBlack');

  return (
    <Box as="section" py={{ base: '4', md: '8' }}>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap={{ base: '5', md: '6' }}>
        <StatisticsCard bgColor={cardBackgroundColor} isLoading={isLoadingStatisticsCount} label={"Organizations"} value={statisticsCount?.data.organizationCount} />
        <StatisticsCard bgColor={cardBackgroundColor} isLoading={isLoadingStatisticsCount} label={"Series"} value={statisticsCount?.data.serieCount} />
        <StatisticsCard bgColor={cardBackgroundColor} isLoading={isLoadingStatisticsCount} label={"Nfts"} value={statisticsCount?.data.nftCount} />
        <StatisticsCard bgColor={cardBackgroundColor} isLoading={isLoadingStatisticsCount} label={"Claimed Nfts"} value={statisticsCount?.data.claimedNftCount} />
      </SimpleGrid>
    </Box>
  )
}