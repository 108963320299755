import { ComponentSingleStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

export const buttonStyles: ComponentSingleStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => ({
    color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.700"
  }),
  variants: {
    "subtle": (props: StyleFunctionProps) => ({
      color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.700",
      backgroundColor: props.colorMode === 'dark' ? "blackAlpha.200" : "gray.200",
    }),
    "link": (props: StyleFunctionProps) => ({
      color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.900"
    }),
    "icon": {
    },
    "ghost": {
      _hover: {
        bgColor: "transparent"
      },
      _active: {
        textDecor: "underline"
      },
      _focus: {
        bgColor: "transparent",
        textDecor: "underline"
      }
    },
    "b2bPrimary": {
      bgColor: "whiteAlpha.900",
      color: "ciphers.ultraMarine",
      height: "48px",
      fontWeight: 700,
      fontSize: "sm",
      lineHeight: 1,
      borderRadius: 4,
      letterSpacing: 2,
      _hover: {
        backgroundColor: "#cccccc"
      }
    },
    "b2bSecondary": (props: StyleFunctionProps) => ({
      bgColor: "ciphers.blueZodiac",
      color: "whiteAlpha.900",
      height: "48px",
      fontWeight: 700,
      fontSize: "sm",
      lineHeight: 1,
      borderRadius: 4,
      letterSpacing: 2,
      _hover: {
        backgroundColor: "#3f4074"
      }
    }),
    "b2bTertiary": {
      bgColor: "ciphers.main.blue",
      color: "whiteAlpha.900",
      borderRadius: 4,
      fontSize: "12px",
      fontWeight: 700,
      letterSpacing: 2,
      px: 8,
      _hover: {
        backgroundColor: "#8475ff"
      }
    },
    "b2bQuaternary": (props: StyleFunctionProps) => ({
      bgColor: "bgPrimary",
      color: "#6A77FF",
      fontSize: "sm",
      borderRadius: 4,
      letterSpacing: 2,
      _hover: {
        backgroundColor: "#32335a"
      }
    }),
    "primary": {
      bgColor: "ciphers.main.buttonPrimary",
      color: "whiteAlpha.900",
      borderRadius: 0,
      p: 6,
    },
    "secondary": (props: StyleFunctionProps) => ({
      bgColor: "ciphers.main.buttonSecondary",
      color: "whiteAlpha.900",
      borderRadius: 0,
      p: 6,
    })
  }
}