import { AspectRatio, Box, useBreakpointValue } from '@chakra-ui/react'
import { FooterB2B } from '../../components/layout/footer/FooterB2B'
import { Navbar } from '../../components/Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import { UseGoogleAnalytics } from '../../analytics/UseGoogleAnalytics'

export const LayoutB2B = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  // Set analytics
  // UseGtm('GTM-MM3Z462', 'GTM-MCJ5FDT'); // 'GTM-MM3Z462' Belongs to Ciphers.me | 'GTM-MCJ5FDT' Belongs to Menno, used for testing.
  UseGoogleAnalytics('G-LQ1R1SSPVD', 'G-BWXM4WFPEY'); // 'G-LQ1R1SSPVD' Belongs to Ciphers.me | 'G-BWXM4WFPEY' belongs to Menno, used for testing.

  return (
    <Box position='relative' overflow="hidden">
      <Box > {/* The size of the footer */}
        <Navbar.B2B />
        <Box marginTop={isDesktop ? 0 : "layout.navbarBase"} minHeight="100vh">
          <Outlet />
        </Box>
      </Box>
      <FooterB2B />
      <AspectRatio
        display={"block"}
        width={{ base: "40rem", md: "50rem" }}
        ratio={1 / 1}
        position="absolute"
        left={{ base: "-110%", md: "-10%" }}
        bottom={{ base: "0%", md: "-10%" }}
        transform="rotateZ(180deg)"
        filter="blur(100px)"
        _after={{
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          transform: "scale(1)",
          clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
          background: '#11095D'
        }}
      >
        {/* Add empty Box because AspectRatio needs at least one child */}
        <Box></Box>
      </AspectRatio>
      <AspectRatio
        display={{ base: "none", md: "block" }}
        width="60rem"
        ratio={1 / 1}
        position="absolute"
        right="-10%"
        bottom="-10%"
        filter="blur(100px)"
        transform="rotateZ(180deg)"
        _after={{
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          transform: "scale(0.7)",
          clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
          background: '#11095D'
        }}
      >
        {/* Add empty Box because AspectRatio needs at least one child */}
        <Box></Box>
      </AspectRatio>
    </Box>
  )
}