import { ComponentSingleStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

export const headingStyles: ComponentSingleStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => ({
    color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.700",
    fontWeight: "500"
  }),
  variants: {
    "href": {
      cursor: "pointer"
    },
    "campaignCardGridBase": {
      textShadow: "1px 1px 1px black"
    },
    "pageTitle": {
      color: "white",
      fontSize: "5xl"
    }
  }
}