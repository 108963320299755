import { Box, Button, Container, Heading, Stack, useBreakpointValue, useColorModeValue, useToast } from '@chakra-ui/react'
import { ChakraMotionDiv } from '../Framer/ChakraMotionDiv'
import { Form, Formik } from 'formik'
import { InputControl } from '../common/formik-chakra'
import { NewsletterSignupValidationSchema } from './NewsletterSignup.Validation'
import { RegisterInfoFormValues } from '../../models/b2b/RegisterInfo'
import { useRegisterInfoMutation } from '../../api/queries/b2b/useRegisterInfo'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const NewsletterSignupForm = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [formValues, setFormValues] = useState(new RegisterInfoFormValues());
  const handleRegisterInfo = useRegisterInfoMutation();

  const bgColor = useColorModeValue("bgPrimaryWhite", "bgPrimaryBlack");
  const blackOrWhite = useColorModeValue("White", "Black");
  const headingSize = useBreakpointValue({ base: 'sm', md: 'md' });

  const handleSubmit = async (values: RegisterInfoFormValues) => {
    handleRegisterInfo.mutate(values, {
      onSuccess() {
        toast({
          title: t("B2B.Register_Info.Success.Toast_Title"),
          description: t("B2B.Register_Info.Success.Toast_Description"),
          status: 'success',
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  return (
    <ChakraMotionDiv bgGradient={`linear(to-b, bgTertiary${blackOrWhite} 50%, bgSecondary${blackOrWhite} 50%, )`} textAlign="center">
      <Container py={{ base: '16', md: '24' }} size={{ base: "md", lg: "xl" }}>
        <Box
          bg={bgColor}
          py={{ base: '10', md: '16' }}
          px={{ base: '6', md: '16' }}
          borderRadius="lg"
          boxShadow={useColorModeValue('sm', 'sm-dark')}
          display="flex" flexDir={{ base: "column" }}
        >
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={NewsletterSignupValidationSchema}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Stack
                  spacing={{ base: '8', md: '8' }}
                  direction={{ base: 'column', lg: 'column' }}
                  justify="space-between"
                >
                  <Stack spacing={{ base: '4', md: '5' }}>
                    <Heading size={headingSize}>
                      {t("B2B.Register_Info.RegisterAndBeTheFirstToKnow")}
                    </Heading>
                  </Stack>
                  <Stack
                    direction="column"
                    spacing="4"
                    width="full"
                    maxW={{ base: 'md', xl: 'lg' }}
                  >
                    <Stack spacing="5">
                      <InputControl isRequired name='firstname' label="First name" />
                      <InputControl isRequired name='lastname' label="Last name" />
                      <InputControl isRequired name="email" label="Email" />
                    </Stack>
                    <Button variant="primary" type="submit" size="lg">
                      {t("Button.Subscribe")}
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </ChakraMotionDiv>
  )
}