import { useEffect, useState } from 'react'

export type windowSize = {
  width: undefined | number,
  height: undefined | number,
  orientation: "portrait" | "landscape" | undefined
}

export function useScreenSize() {
  const [windowSize, setWindowSize] = useState<windowSize>({
    width: undefined,
    height: undefined,
    orientation: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        orientation: window.orientation !== 0 ? "landscape" : "portrait"
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}