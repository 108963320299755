import * as Yup from 'yup'

export const ContactValidationSchema = (receivedStamp: boolean, scheduleMeeting: boolean) => Yup.object().shape({
  name: Yup.string()
    .required("B2B.ContactPage.Form.Fields.Name.Error"),
  company: Yup.string()
    .required("B2B.ContactPage.Form.Fields.Company.Error"),
  email: Yup.string()
    .email("B2B.ContactPage.Form.Fields.Email.ErrorFormat")
    .required("B2B.ContactPage.Form.Fields.Email.Error"),
  phone: Yup
    .string()
    .matches(/^\+?[-0-9]+$/, "B2B.ContactPage.Form.Fields.Phone.FormatError")
    .required("B2B.ContactPage.Form.Fields.Phone.Error"),
  ...(!receivedStamp && {
    address: Yup.string()
      .required("B2B.ContactPage.Form.Fields.Address.Error"),
    postalCode: Yup.string(),
    city: Yup.string()
      .required(),
    country: Yup.string()
      .required("B2B.ContactPage.Form.Fields.Country.Error"),
  }),
  preferredDay: Yup.string()
    .required(),
  preferredMoment: Yup.string()
    .required(),
  commentOrQuestion: Yup.string()
    .required("B2B.ContactPage.Form.Fields.CommentOrQuestion.Error"),
})