import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack, useColorModeValue } from '@chakra-ui/react'
import { NavLink, useParams } from 'react-router-dom'
import { RenderGuard } from '../../../../components/common/guards/RenderGuard'
import { Roles } from '../../../../enums/Roles'
import { routePrefixDashboard } from '../../../../ApplicationRouter'
import { SerieTable } from '../../../../components/secure/dashboard/serie/SerieTable'
import { useTranslation } from 'react-i18next'

export const Series = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <AutoWidthCenter bg={useColorModeValue("bgTertiaryWhite", "bgPrimaryBlack")}>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">{t('Series.Series')}</Heading>
          <RenderGuard roles={Roles.ADMIN}>
            <Button as={NavLink} to={id ? `${routePrefixDashboard}/serie/${id}/new` : `${routePrefixDashboard}/serie/new`} >{t('Series.Create')}</Button>
          </RenderGuard>
        </HStack>
        <Divider />
        <SerieTable id={id} />
      </Box>
    </AutoWidthCenter >
  )
}