import { createContext, useEffect, useMemo, useState } from 'react'
import { CustomStep, IJoyride, IJoyrideTutorial } from '../models/joyride/IJoyride'
import { JoyrideKey, joyrideTutorials } from './_joyrideData'

export const joyrideKey = "KJE_NFT-Joyride";

interface IJoyrideObject {
  continuous: boolean;
  getShouldRun: boolean;
  getSteps: CustomStep[] | undefined;
  performed: IJoyride;
  showSkip: boolean;
  stepIndex: number | undefined;
  completed: (key: JoyrideKey) => void;
  hasCompleted: (key: JoyrideKey) => boolean;
  nextStepIndex: () => void;
  previousStepIndex: () => void;
  resetShouldRun(): void
  skip: () => void;
  start: (key: JoyrideKey) => void;
}

export const useJoyride = () => {
  const init = () => {
    let joyride: IJoyride = { active: "", completed: [] };
    if (localStorage.getItem(joyrideKey)) {
      joyride = JSON.parse(localStorage.getItem(joyrideKey)!)
    }
    return joyride
  }

  const [performed, setPerformedState] = useState<IJoyride>(init());
  const [shouldRun, setShouldRun] = useState(false);
  const [isContinuous, setIsContinuous] = useState(false);
  const [showSkip, setShowSkip] = useState<boolean>(true);
  const [targetKey, setTargetKey] = useState<JoyrideKey>();
  const [activeTutorial, setActiveTutorial] = useState<IJoyrideTutorial>();
  const [stepIndex, setStepIndex] = useState<number>(0);

  useEffect(() => {
    if (targetKey !== undefined) {
      const tutorial = joyrideTutorials.tutorials.find((x) => x.key === targetKey)
      setActiveTutorial(tutorial);
    }
  }, [targetKey])

  useEffect(() => {
    if (activeTutorial !== undefined) {
      setIsContinuous(activeTutorial.continuous ?? false)
      setShowSkip(!activeTutorial.hideSkip ?? true)
    } else {
      setIsContinuous(false);
      setShowSkip(true);
    }
  }, [activeTutorial])

  const getSteps = useMemo(() => {
    if (activeTutorial === undefined) return undefined;
    return activeTutorial.steps
  }, [activeTutorial])

  function setPerformed(performed: IJoyride) {
    localStorage.setItem(joyrideKey, "");
    setPerformedState(performed);
    localStorage.setItem(joyrideKey, JSON.stringify(performed));
  }

  function hasCompleted(key: JoyrideKey) {
    return performed.completed.includes(key);
  }

  function completed(key: JoyrideKey) {
    const newPerformed: IJoyride = { ...performed };
    if (!newPerformed.completed.includes(key)) {
      newPerformed.completed.push(key);
      newPerformed.active = "";
      setPerformed(newPerformed);
    }
    setStepIndex(0);
    setTargetKey(undefined);
    setShouldRun(false);
    setIsContinuous(false);
    setShowSkip(true);
  }

  function startTutorial(key: JoyrideKey) {
    setShouldRun(true);
    setTargetKey(key);

    if (performed.active === key) {
      // Already active
      // We may have returned by a redirect so the stepIndex & performed shouldn't be updated
      return;
    }

    const newPerformed = { ...performed };
    newPerformed.active = key;
    setPerformed(newPerformed);
    setStepIndex(0); // init step
  }

  function skip() {
    if (targetKey) {
      completed(targetKey);
    }
  }

  function nextStepIndex() {
    setStepIndex(stepIndex + 1)
  }

  function previousStepIndex() {
    setStepIndex(stepIndex - 1)
  }

  function resetShouldRun() {
    // Used for redirects occured outside the joyride steps
    setShouldRun(false);
    setStepIndex(0);
  }

  const joyrideObject: IJoyrideObject = {
    continuous: isContinuous,
    getShouldRun: shouldRun,
    getSteps: getSteps,
    performed: performed,
    showSkip: showSkip,
    stepIndex: stepIndex,
    completed: completed,
    hasCompleted: hasCompleted,
    nextStepIndex: nextStepIndex,
    previousStepIndex: previousStepIndex,
    resetShouldRun: resetShouldRun,
    skip: skip,
    start: startTutorial,
  }

  return joyrideObject;
}

export const JoyrideContext = createContext<IJoyrideObject>({} as IJoyrideObject);