import { FaqRoutes } from './dashboardSubRoutes/FaqRoutes'
import { GeneralRoutes } from './dashboardSubRoutes/GeneralRoutes'
import { IRoute } from '../models/routes/IRoute'
import { OrganizationRoutes } from './dashboardSubRoutes/OrganizationRoutes'
import { SerieRoutes } from './dashboardSubRoutes/SerieRoutes'

const dashboardRoutes: IRoute[] = [
  ...GeneralRoutes,
  ...OrganizationRoutes,
  ...SerieRoutes,
  ...FaqRoutes
]

export default dashboardRoutes;