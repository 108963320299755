import { Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'

interface IProps {
  title: string,
  subTitle: string,
}

export const Header: React.FC<IProps> = ({ title, subTitle }) => {
  return (
    <Flex direction="column" justify="center" align="center" p={{ base: 8, md: 16 }}>
      <Heading as="h1" mb={6} fontSize="5xl" textAlign="center">{title}</Heading>
      <Text fontSize="2xl" textAlign="center">{subTitle}</Text>
    </Flex>
  )
}
