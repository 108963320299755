import { apiClient } from '../../base/ApiClients'
import { DashboardSerieFormValues, DashboardSerieFormValuesWithId, IDashboardSerie, IFeaturedSerie, ISerieCollection, ISerieDto } from '../../../models/Serie/ISerie'
import { ESerieHighlightSpot } from '../../../enums/ESerieHighlightSpot'
import { useMutation } from 'react-query'

export const GetFeaturedSeriesQuery = async () => {
  const { data } = await apiClient.get<IFeaturedSerie[]>("Serie/featured-series")
  return data;
}

export const GetFeaturedSerieQuery = async (highlightSpot: ESerieHighlightSpot) => {
  const { data } = await apiClient.get<ISerieDto>(`serie/featured?highlightSpot=${highlightSpot}`);
  return data;
}

export const GetSerieCollections = async () => {
  const { data } = await apiClient.get<ISerieCollection[]>(`serie/collections`);
  return data;
}

export const GetDashboardSeries = async () => {
  const { data } = await apiClient.get<IDashboardSerie[]>(`Serie/dashboard/all`)
  return data;
}

export const GetDashboardSeriesById = async (id: string | undefined) => {
  const { data } = await apiClient.get<IDashboardSerie[]>(`Serie/dashboard/all/${id}`)
  return data;
}

export const GetDashboardSerie = async (id: string | undefined) => {
  const { data } = await apiClient.get<IDashboardSerie>(`Serie/dashboard/${id}`)
  return data;
}

export const GetSerieQuery = async (id: any) => {
  const { data } = await apiClient.get<ISerieDto>(`serie/${id}`);
  return data;
}

export const CreateSerie = async (values: DashboardSerieFormValues) => {
  let formData = new FormData();
  formData.append("translation.title", values.translation.title);
  formData.append("translation.description", values.translation.description);
  formData.append("bannerImage", values.bannerImage);
  formData.append("smallImage", values.smallImage);
  formData.append("organizationId", values.organizationId);
  formData.append("serieType", values.serieType.toString());

  return await apiClient.post("serie", formData);
}

export const useCreateSerieMutation = () => useMutation(CreateSerie, {});

export const EditSerie = async (values: DashboardSerieFormValuesWithId) => {
  let formData = new FormData();
  formData.append("translation.title", values.formValues.translation.title);
  formData.append("translation.description", values.formValues.translation.description);
  formData.append("bannerImage", values.formValues.bannerImage);
  formData.append("smallImage", values.formValues.smallImage);
  formData.append("serieType", values.formValues.serieType.toString());

  return await apiClient.put(`serie/${values.id}`, formData);
}

export const useEditSerie = () => useMutation(EditSerie, {});

export const UpdatePrivateSerie = async (serieId: string) => {
  return await apiClient.put(`serie/${serieId}/private`)
}

export const useUpdatePrivateSerieMutation = () => useMutation(UpdatePrivateSerie, {})

export const DeleteSerie = async (id: any) => {
  return await apiClient.delete(`serie/${id}`)
}

export const useDeleteSerie = () => useMutation(DeleteSerie, {})