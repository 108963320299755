import { AG_GRID_LOCALE_EN } from './AgGrid.Locale.en'
import { AgGridReact } from 'ag-grid-react'
import { BsCheckLg } from 'react-icons/bs'
import { ColumnApi, GridApi, GridReadyEvent, ITooltipParams, RowDragEvent, RowHeightParams } from 'ag-grid-community'
import { formatDateTime } from '../../../utils/General'
import { Icon, useColorModeValue } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

export type DataViewRenderer =
  | "actions"
  | "checkmark"
  | "renderdate"
  | "renderdatetime"
  | "enum"

export type DataViewSort =
  | "desc"
  | "asc"

interface IEnumRenderInput {
  value: string | number;
  type: any;
  translationKey: string;
  seperator: "_" | ".";
}

export interface IDataViewColumn {
  headerName: string;
  hide?: boolean;
  field?: string;
  sortable?: boolean;
  filter?: boolean;
  cellRenderer?: DataViewRenderer | ((input: any) => string | JSX.Element);
  cellRendererParams?: any;
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  valueGetter?: any;
  rowDrag?: boolean;
  lockPosition?: boolean;
  tooltipField?: string;
  tooltipValueGetter?: (params: ITooltipParams) => any;
  sort?: DataViewSort;
  valueFormatter?: (params: any) => any;
}

interface IProps<T> {
  actionsRenderer?: (data: any) => JSX.Element;
  columns: IDataViewColumn[];
  data: T[];
  onDrop?: (movingNodeId: string, previousNodeId?: string, nextNodeId?: string) => void;
  quickFilter?: string
  getRowHeight?: ((params: RowHeightParams<T>) => number | null | undefined) | undefined
}

export const DataView = <T extends unknown>({ actionsRenderer, columns, data, onDrop: onPassData, quickFilter, getRowHeight }: IProps<T>) => {
  const [gridApi, setGridApi] = useState<GridApi<T>>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { t } = useTranslation();

  const onGridReady = (params: GridReadyEvent<T>) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.setRowData(data);
  }

  (gridApi && quickFilter) && gridApi.setQuickFilter(quickFilter);

  // Renderers
  const checkmarkRenderer = (input: any): JSX.Element => {
    const isChecked: boolean = input.getValue();
    return isChecked ? <Icon as={BsCheckLg} /> : <></>
  }

  const datetimeRenderer = (input: any): string => {
    return formatDateTime(input.getValue(), "i18n.Formatted.DateTime")
  }

  const dateRenderer = (input: any): string => {
    return formatDateTime(input.getValue(), "i18n.Formatted.Date");
  }

  const enumRenderer = (input: IEnumRenderInput): string => {
    const enumValue = Object.keys(input.type)[Object.values(input.type).indexOf(input.value)];

    return t(`${input.translationKey}${input.seperator}${enumValue}`)
  }
  // End renderers

  // Row dragging
  const onRowDragMove = (event: RowDragEvent<T>) => {
    if (!gridApi) return;
    var movingNode = event.node;
    var overNode = event.overNode;
    var rowNeedsToMove = movingNode !== overNode;

    const moveInArray = (arr: T[], fromIndex: number, toIndex: number) => {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    if (rowNeedsToMove && overNode !== undefined && overNode.data !== null) {
      var movingData = movingNode.data;
      var overData = overNode.data;
      if (movingData === undefined || overData === undefined) return;
      var fromIndex = data.indexOf(movingData);
      var toIndex = data.indexOf(overData);
      var newData = data.slice();
      moveInArray(newData, fromIndex, toIndex);
      data = newData;
      gridApi.setRowData(newData);
      gridApi.clearFocusedCell();
    }
  }

  const getRowId = (data: any) => {
    return data.data.id;
  }

  const onDrop = (event: RowDragEvent<T>) => {
    console.log("event", event);

    if (onPassData !== undefined) {
      const movingNode = event.node;
      if (movingNode !== null && movingNode.rowIndex) {
        // Casting to any because the T does not know it contains a id. (︶︹︺)
        const previousNode = data[movingNode.rowIndex - 1] as any;
        const nextNode = data[movingNode.rowIndex + 1] as any;
        onPassData(movingNode.id as string, previousNode?.id, nextNode?.id);
      }
    }
  }
  // End row dragging

  return (
    <div className={useColorModeValue("ag-theme-alpine", "ag-theme-alpine-dark")} style={{ height: "80vh", width: "100%", lineHeight: 1 }}>
      <AgGridReact
        components={{
          actions: actionsRenderer,
          checkmark: checkmarkRenderer,
          renderdate: dateRenderer,
          renderdatetime: datetimeRenderer,
          enum: enumRenderer
        }}
        columnDefs={columns}
        defaultColDef={{ filter: false }}
        enableBrowserTooltips={true}
        localeText={AG_GRID_LOCALE_EN}
        onGridReady={onGridReady}
        pagination={true}
        paginationAutoPageSize={true}
        rowData={data}
        getRowHeight={getRowHeight}
        suppressCellFocus={true}
        suppressMovableColumns={true}
        suppressRowClickSelection={true}
        enableCellTextSelection={!columns.some(x => x.rowDrag)}

        // Row dragging
        animateRows={true}
        getRowId={getRowId}
        onRowDragEnd={onDrop}
        onRowDragLeave={onDrop}
        onRowDragMove={onRowDragMove}
        suppressMoveWhenRowDragging={true}

        // Virtualisation
        suppressColumnVirtualisation={true}
        suppressRowVirtualisation={true}
      />
    </div>
  )
}
