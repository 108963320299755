import { ArrowPointing, ErrorMessage } from './ErrorMessage'
import { Box, Button, Center, Flex, Heading, Icon, Input, Select, Text, Textarea, useBreakpointValue, useToast, VStack } from '@chakra-ui/react'
import { ContactInfoFormValues } from '../../../models/b2b/ContactInfo'
import { ContactValidationSchema } from './Contact.Vlidation'
import { Field, FieldProps, Formik, FormikHelpers, getIn } from 'formik'
import { InputField } from './InputField'
import { MeetingNo, MeetingYes, StampNo, StampYes } from '../../../assets/icons/components'
import { Trans, useTranslation } from 'react-i18next'
import { useContactInfoMutation } from '../../../api/queries/b2b/useContactInfo'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const initialValues: ContactInfoFormValues = {
  name: '',
  company: '',
  email: '',
  phone: '',
  address: '',
  postalCode: '',
  city: '',
  country: '',
  preferredDay: 'Monday',
  preferredMoment: '09:00 - 11:00',
  commentOrQuestion: ''
}

export const Contact = () => {
  const { t } = useTranslation()
  let { hideButton } = useParams()
  const hideIDontReceiveAStamp = hideButton === "1"
  const [receivedStamp, setReceivedStamp] = useState<boolean>(() => {
    if (hideIDontReceiveAStamp) return true
    return false
  })
  const [scheduleMeeting, setscheduleMeeting] = useState<boolean>(true)
  const [showCommonErrorMessage, setShowCommonErrorMessage] = useState<boolean>(false)
  const handleContactInfo = useContactInfoMutation();
  const toast = useToast();
  const arrowDirection = useBreakpointValue({ base: ArrowPointing.Top, md: ArrowPointing.Left })
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" })
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
  const timeSlots = ["09:00 - 11:00", "11:00 - 13:00", "13:00 - 15:00", "15:00 - 17:00"]

  const handleSubmit = (values: ContactInfoFormValues, actions: FormikHelpers<ContactInfoFormValues>) => {
    const valuesToSubmit = {
      receivedStamp,
      scheduleMeeting,
      ...values
    }
    handleContactInfo.mutate(valuesToSubmit, {
      onSuccess() {
        toast({
          title: t("B2B.ContactPage.Form.Success.Title"),
          description: t("B2B.ContactPage.Form.Success.Description"),
          variant: "success",
          duration: 20 * 1000,
          isClosable: true,
          position: "top",
        })
        actions.resetForm()
      },
      onError() {
        toast({
          title: t("B2B.ContactPage.Form.Error.Title"),
          description: t("B2B.ContactPage.Form.Error.Description"),
          status: "error",
          duration: 20 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  return (
    <Center>
      <Box width={boxWidth} mt={16} zIndex={1}>
        <Box pl={{ base: 0, md: 8 }}>
          <Heading as="h1" variant="pageTitle" textAlign={{ base: "center", md: "left" }} mb={14} fontSize={{ base: "4xl", md: "5xl" }}>
            <Trans i18nKey="B2B.ContactPage.Title">
              Placeholder <span className='magic'></span>
            </Trans>
          </Heading>
        </Box>

        {showCommonErrorMessage && (
          <Box width={{ base: "100%", md: "80%" }} px={{ base: 2, md: 8 }} py={{ base: 2, md: 4 }} bgGradient="linear(to-r, #8721FF, #FE36AD)">
            <Text as="span" color="white">{t("B2B.ContactPage.ErrorMessage")}</Text>
          </Box>
        )}
        <Flex flexFlow="row wrap" justifyContent="space-between" position="relative">
          <Box flexBasis={{ base: "100%", md: "80%" }} backgroundColor="bgSecondary" padding={{ base: 4, md: 8 }} mb={20}>
            <Formik
              initialValues={initialValues}
              validationSchema={ContactValidationSchema(receivedStamp, scheduleMeeting)}
              onSubmit={(values, actions) => {
                handleSubmit(values, actions)
              }}
            >
              {({ handleSubmit, errors, touched, values, setFieldValue, isValid, submitCount }) => {
                //submitCount > 0 otherwise the error will show from the start
                (submitCount > 0 && !isValid) ? setShowCommonErrorMessage(true) : setShowCommonErrorMessage(false)

                const cityError = (getIn(touched, "city") && getIn(errors, "city"))
                return (
                  <form onSubmit={handleSubmit}>
                    <Flex justifyContent="space-between" width={{ base: "100%", md: "67%" }} mb={6}>
                      <Button
                        display="flex"
                        padding={{ base: 1, md: 3 }}
                        whiteSpace="normal"
                        alignItems="center"
                        height={20}
                        flexBasis="49%"
                        justifyContent="center"
                        border={receivedStamp ? "1px solid #B4FFFF" : "none"}
                        boxShadow={receivedStamp ? " 0px 0px 10px #B4FFFF" : "none"}
                        backgroundColor="ciphers.blueZodiac"
                        onClick={() => setReceivedStamp(true)}
                        _hover={{
                          backgroundColor: "ciphers.blueZodiac"
                        }}>
                        <Icon as={StampYes} color="ciphers.main.green" w={8} h={8} mr={2} />
                        <Text as="span" color={receivedStamp ? "white" : "ciphers.cornflowerBlue"}>{t("B2B.ContactPage.Form.Buttons.ReceivedStamp")}</Text>
                      </Button>
                      {!hideIDontReceiveAStamp && (
                        <Button
                          display="flex"
                          padding={{ base: 1, md: 3 }}
                          whiteSpace="normal"
                          alignItems="center"
                          height={20}
                          flexBasis="49%"
                          justifyContent="center"
                          border={!receivedStamp ? "1px solid #B4FFFF" : "none"}
                          boxShadow={!receivedStamp ? " 0px 0px 10px #B4FFFF" : "none"}
                          backgroundColor="ciphers.blueZodiac"
                          onClick={() => setReceivedStamp(false)}
                          _hover={{
                            backgroundColor: "ciphers.blueZodiac"
                          }}>
                          <Icon as={StampNo} color="ciphers.persianRose" w={8} h={8} mr={2} />
                          <Text as="span" color={!receivedStamp ? "white" : "ciphers.cornflowerBlue"}>{t("B2B.ContactPage.Form.Buttons.NotReceivedStamp")}</Text>
                        </Button>
                      )}
                    </Flex>
                    <Text color="white" textOverflow={"ellipsis"} width={{ base: "100%", md: "67%" }} mb={9}>
                      {receivedStamp ? t("B2B.ContactPage.MessageReceived") : t("B2B.ContactPage.MessageNotReceived")} { }
                    </Text>
                    <Box mb={14}>
                      <Heading as="h2" color="white" mb={6} fontSize={{ base: "1.625rem", md: "3xl" }}>{t("B2B.ContactPage.Form.Title1")}</Heading>
                      <VStack spacing={{ base: 4, md: 6 }} alignItems="stretch">
                        <Field
                          name="name"
                          component={InputField}
                          label={t("B2B.ContactPage.Form.Fields.Name.Title")}
                        />
                        <Field
                          name="company"
                          component={InputField}
                          label={t("B2B.ContactPage.Form.Fields.Company.Title")}
                        />
                        <Field
                          name="email"
                          component={InputField}
                          label={t("B2B.ContactPage.Form.Fields.Email.Title")}
                        />
                        <Field
                          name="phone"
                          component={InputField}
                          label={t("B2B.ContactPage.Form.Fields.Phone.Title")}
                        />
                      </VStack>
                    </Box>
                    {!receivedStamp && (
                      <Box mb={14}>
                        <Heading as="h2" color="white" mb={4} fontSize={{ base: "1.625rem", md: "3xl" }}>{t("B2B.ContactPage.Form.Title2")}</Heading>
                        <Text color="white" mb={4}>
                          <Trans i18nKey="B2B.ContactPage.Form.Title2Description">
                            Placeholder <br></br>
                          </Trans>
                        </Text>
                        <VStack spacing={{ base: 4, md: 6 }} alignItems="stretch">
                          <Field
                            name="address"
                            component={InputField}
                            label={t("B2B.ContactPage.Form.Fields.Address.Title")}
                          />
                          <Flex flexFlow="row wrap" justifyContent="space-between">
                            <Flex justifyContent="space-between" width={{ base: "100%", md: "67%" }}>
                              <Field name="postalCode">
                                {({ field, form: { touched, errors }, }: FieldProps<string, HTMLInputElement>) => (
                                  <Box px={2} py={1} borderRadius={8} backgroundColor="white" flexBasis={{ base: "40%", md: "30%" }} border="none">
                                    <Text as="label" fontSize="sm" htmlFor={field.name} color="cornflowerBlue">{t("B2B.ContactPage.Form.Fields.PostalCode.Title")}</Text>
                                    <Input size="sm" border="none" color="black" {...field} padding={0} _focusVisible={{ border: "none" }}></Input>
                                  </Box>
                                )}
                              </Field>
                              <Field name="city">
                                {({ field, form: { touched, errors }, }: FieldProps<string, HTMLInputElement>) => (
                                  <Box px={2} py={1} borderRadius={8} backgroundColor="white" flexBasis={{ base: "55%", md: "65%" }} border={cityError ? "2px solid #E60066" : "none"}>
                                    <Text as="label" fontSize="sm" htmlFor={field.name} color={cityError ? "#E60066" : "cornflowerBlue"}>{t("B2B.ContactPage.Form.Fields.City.Title")}</Text>
                                    <Input size="sm" border="none" color="black" {...field} padding={0} _focusVisible={{ border: "none" }}></Input>
                                  </Box>
                                )}
                              </Field>
                            </Flex>
                            {(getIn(touched, "city") && getIn(errors, "city")) && (
                              <Box my={{ base: 5, md: 0 }} flexBasis={{ base: "100%", md: "30%" }}>
                                <ErrorMessage errorMessage={t("B2B.ContactPage.Form.Fields.City.Error")} arrowPointing={arrowDirection} />
                              </Box>
                            )}
                          </Flex>
                          <Field
                            name="country"
                            component={InputField}
                            label="Country"
                          />
                        </VStack>
                      </Box>
                    )}
                    <Box mb={14}>
                      <Heading as="h2" color="white" mb={4} fontSize={{ base: "1.625rem", md: "3xl" }}>{t("B2B.ContactPage.Form.Title3")}</Heading>
                      <VStack spacing={{ base: 4, md: 6 }} alignItems="stretch">
                        <Flex justifyContent="space-between" width={{ base: "100%", md: "67%" }}>
                          <Button
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={1}
                            whiteSpace="normal"
                            height={20}
                            flexBasis="49%"
                            border={scheduleMeeting ? "1px solid #B4FFFF" : "none"}
                            boxShadow={scheduleMeeting ? " 0px 0px 10px #B4FFFF" : "none"}
                            backgroundColor="ciphers.blueZodiac"
                            onClick={() => {
                              setFieldValue("preferredMoment", timeSlots[0])
                              setFieldValue("preferredDay", days[0])
                              setscheduleMeeting(true)
                            }}
                            _hover={{
                              backgroundColor: "ciphers.blueZodiac"
                            }}>
                            <Icon as={MeetingYes} color="ciphers.main.green" w={8} h={8} mr={3} />
                            <Text as="span" color={scheduleMeeting ? "white" : "ciphers.cornflowerBlue"}>{t("B2B.ContactPage.Form.Buttons.ScheduleMeeting")}</Text>
                          </Button>
                          <Button
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            padding={1}
                            whiteSpace="normal"
                            height={20}
                            flexBasis="49%"
                            border={!scheduleMeeting ? "1px solid #B4FFFF" : "none"}
                            boxShadow={!scheduleMeeting ? " 0px 0px 10px #B4FFFF" : "none"}
                            backgroundColor="ciphers.blueZodiac"
                            onClick={() => {
                              setFieldValue("preferredMoment", "-")
                              setFieldValue("preferredDay", "-")
                              setscheduleMeeting(false)
                            }
                            }
                            _hover={{
                              backgroundColor: "ciphers.blueZodiac"
                            }}>
                            <Icon as={MeetingNo} color="ciphers.persianRose" w={8} h={8} mr={2} />
                            <Text as="span" color={!scheduleMeeting ? "white" : "ciphers.cornflowerBlue"}>{t("B2B.ContactPage.Form.Buttons.NotScheduleMeeting")}</Text>
                          </Button>
                        </Flex>
                        {scheduleMeeting && (
                          <Flex width={{ base: "100%", md: "67%" }} flexFlow="row wrap" justifyContent="space-between">
                            <Box flexBasis={{ base: "100%", md: "49%" }} py={1} borderRadius={8} backgroundColor="white" mb={{ base: 4, md: 0 }}>
                              <Text pl={4} as="label" fontSize="sm" htmlFor="prefferedDay" color="cornflowerBlue">{t("B2B.ContactPage.Form.Fields.PrefferedDay")}</Text>
                              <Select
                                size="sm"
                                fontSize="md"
                                value={values.preferredDay}
                                name="preferredDay"
                                fontWeight={700}
                                color="ciphers.luckyPoint"
                                border="none" pl={1}
                                _focusVisible={{ border: "none" }}
                                onChange={(e) => setFieldValue("preferredDay", e.currentTarget.value)}
                              >
                                {days.map(day => <Text as="option" fontWeight={700} color="ciphers.luckyPoint" value={day}>{day}</Text>)}
                              </Select>
                            </Box>
                            <Box flexBasis={{ base: "100%", md: "49%" }} py={1} borderRadius={8} backgroundColor="white">
                              <Text as="label" fontSize="sm" htmlFor="preferredMoment" pl={4} color="cornflowerBlue">{t("B2B.ContactPage.Form.Fields.PrefferedMoment")}</Text>
                              <Select
                                size="sm"
                                fontSize="md"
                                value={values.preferredMoment}
                                pl={1}
                                name="prefferedMoment"
                                fontWeight={700}
                                color="ciphers.luckyPoint"
                                border="none"
                                _focusVisible={{ border: "none" }}
                                onChange={(e) => setFieldValue("preferredMoment", e.currentTarget.value)}
                              >
                                {timeSlots.map(ts => <Text as="option" fontWeight={700} color="ciphers.luckyPoint" value={ts}>{t("Between")} {ts}</Text>)}
                              </Select>
                            </Box>
                          </Flex>
                        )}
                      </VStack>
                    </Box>
                    <Box>
                      <Heading as="h2" color="white" mb={4} fontSize="1.625rem">{t("B2B.ContactPage.Form.Fields.CommentOrQuestion.Title")}</Heading>
                      <Field name="commentOrQuestion">
                        {({
                          field,
                          form: { touched, errors },
                        }: FieldProps<string, HTMLInputElement>) => {
                          const hasError = getIn(touched, field.name) && getIn(errors, field.name);
                          return (
                            <Flex flexFlow="row wrap" justifyContent="space-between">
                              <Textarea color="black" {...field} border={hasError ? "2px solid #E60066" : "none"} borderColor="#E60066" backgroundColor="white" minHeight={40} flexBasis={{ base: "100%", md: "67%" }} />
                              {hasError && (
                                <Box my={{ base: 5, md: 0 }} flexBasis={{ base: "100%", md: "30%" }}>
                                  <ErrorMessage errorMessage={t(getIn(errors, field.name))} arrowPointing={arrowDirection} />
                                </Box>
                              )
                              }
                            </Flex>
                          )
                        }}
                      </Field>
                    </Box>
                    <Button isLoading={handleContactInfo.isLoading} px={10} py={6} width={{ base: "100%", md: "auto" }} mt={8} variant="b2bTertiary" type='submit'>{t("Button.Send").toUpperCase()}</Button>
                  </form>
                )
              }}
            </Formik>
          </Box>

        </Flex>
      </Box >
    </Center >
  )
}
