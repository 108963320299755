import { apiClient } from './../../base/ApiClients'
import { FaqCategoryFormValues, FaqCategoryFormValuesWithId, FaqQuestionFormValues, FaqQuestionFormValuesWithId, IFaqCategory, IFaqQuestion } from './../../../models/faq/IFaq'
import { useMutation } from 'react-query'

export const GetFaqCategoriesAndQuestions = async () => {
  const { data } = await apiClient.get<IFaqCategory[]>("Faq/categories")
  return data;
}

export const GetFaqCategoryQuery = async (id : string) => {
  const { data } = await apiClient.get<IFaqCategory>(`Faq/categories/${id}`)
  return data;
}

export const CreateFaqCategoryQuery = async (values: FaqCategoryFormValues) => {
  return await apiClient.post('faq/categories', values);
}

export const useCreateFaqCategoryMutation = () => useMutation(CreateFaqCategoryQuery, {})

export const EditFaqCategoryQuery = async (values: FaqCategoryFormValuesWithId) => {
  return await apiClient.put(`faq/categories/${values.id}`, values.formValues);
}

export const useEditFaqCategoryQueryMutation = () => useMutation(EditFaqCategoryQuery, {})

export const DeleteFaqCategory = async (id: string) => {
  return await apiClient.delete(`faq/categories/${id}`)
}

export const useDeleteFaqCategoryMutation = () => useMutation(DeleteFaqCategory, {})

export const GetFaqQuestionQuery = async (id: string) => {
  const { data } = await apiClient.get<IFaqQuestion>(`Faq/questions/${id}`)
  return data;
}

export const CreateFaqQuestion = async (values: FaqQuestionFormValues) => {
  return await apiClient.post(`faq/${values.categoryId}/questions`, values)
}

export const useCreateFaqQuestionMutation = () => useMutation(CreateFaqQuestion, {})

export const EditFaqQuestion = async (values: FaqQuestionFormValuesWithId) => {
  return await apiClient.put(`faq/questions/${values.id}`, values.formValues)
}

export const useEditFaqQuestionMutation = () => useMutation(EditFaqQuestion, {})

export const DeleteFaqQuestion = async (id: string) => {
  return await apiClient.delete(`faq/questions/${id}`)
}

export const useDeleteFaqQuestionMutation = () => useMutation(DeleteFaqQuestion, {})