import { chakra, GridItem, ResponsiveValue } from '@chakra-ui/react'
import { get_average_rgb, isImageTooDark, rgbToHex } from '../../../utils/imageUtils/ColorScience'
import { IImageResizerOptions } from '../../../models/general/IImageResizerOptions'
import { imageOrPlaceholder } from '../../../utils/General'
import { INftImage } from '../../../models/nfts/INft'
import { useMemo } from 'react'

interface IProps {
  nftImage: INftImage;
  children: React.ReactNode;
  colSpan?: ResponsiveValue<number | "auto"> | undefined;
  rowSpan?: ResponsiveValue<number | "auto"> | undefined;
  imageResizerOptions?: IImageResizerOptions | undefined;
}

const CampaignCardGridItem2 = (props: IProps) => {
  const { nftImage, children, imageResizerOptions, ...rest } = props;
  const image = imageOrPlaceholder(nftImage.path, imageResizerOptions)
  const img = new Image();
  img.src = image ?? "";
  const avgRGB = useMemo(() => get_average_rgb(img), []);
  const avgHEX = useMemo(() => rgbToHex(avgRGB.r, avgRGB.g, avgRGB.b), []);

  if (img.src == null || img.src === "") {
    return <>loading</>
  }

  return (
    <GridItem
      className="grow-on-hover"
      color={isImageTooDark(avgHEX) ? "white" : "black"}
      bgImage={img.src}
      bgSize="cover"
      bgPosition="center center"
      display="flex"
      alignItems="flex-end"
      p="4"
      borderRadius="14px"
      minW={{ sm: "100%", md: "285px" }}
      minH="381px"
      {...rest}
    >
      {props.children}
    </GridItem>
  )
}

const CampaignCardGridItem = chakra(CampaignCardGridItem2);
export default CampaignCardGridItem;