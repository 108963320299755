import i18n from '../i18n'
import placeholderImage_2 from '../assets/placeholders/test-image_2.jpg'
import { IImageResizerOptions, ImageResizerOptions } from '../models/general/IImageResizerOptions'
// import placeholderImage_1 from '../assets/placeholders/test-image_1.jpg'
// import placeholderImage_3 from '../assets/placeholders/test-image_3.jpg'
// import placeholderImage_4 from '../assets/placeholders/test-image_4.jpg'
// import placeholderImage_5 from '../assets/placeholders/test-image_5.jpg'
// import placeholderImage_6 from '../assets/placeholders/test-image_6.jpg'

export const isLocalhost = function () {
  return window.location.hostname === "localhost";
}

export const localhostLogText = function (text: string) {
  if (isLocalhost()) {
    console.log(text);
  }
}

export const localhostLogFetch = function (type: any, url: string, body?: any) {
  if (isLocalhost()) {
    console.log("fetch: " + type + " " + url + (body ? ", with body data: " + body : ""));
  }
}

export const localhostLogFetchResult = function (type: any, url: string, result: any) {
  if (isLocalhost()) {
    console.log("fetch: " + type + " " + url + " -> RESULT:");
    console.dir(result);
  }
}

export const removeSurroundingQuotes = function (str: string) {
  return isSurroundedBy(str, '""') || isSurroundedBy(str, "''")
    ? str.slice(1, -1)
    : str;
}

export const isSurroundedBy = function (str: string, delimiters: string) {
  return (
    str && str.length >= 2 &&
    delimiters[0] === str[0] &&
    delimiters[1] === str[str.length - 1]
  );
}

export const cleanupFilename = (filename: string) => {
  filename = filename.trim();
  filename = filename.replace(/;.*$/, ""); // indien aanwezig, replace ";" en alles daarachter met ""
  return removeSurroundingQuotes(filename);
}

export const imageOrPlaceholder = function (path?: string, imageResizerOptions?: IImageResizerOptions) {
  if ((process.env.REACT_APP_ALLOW_LOCAL_PLACEHOLDER === "true" && isLocalhost()) || !path) {
    const random = Math.floor(Math.random() * 6) + 1;
    switch (random) {
      // case 1:
      //   return placeholderImage_1;
      // case 2:
      //   return placeholderImage_2;
      // case 3:
      //   return placeholderImage_3;
      // case 4:
      //   return placeholderImage_4;
      // case 5:
      //   return placeholderImage_5;
      // case 6:
      //   return placeholderImage_6;
      default:
        return placeholderImage_2; // TODO: Re-render triggers new random image...
    }
  } else {
    // Some chakra components have trouble with '\' in the path, this replaces all '\' with a '/'
    var forwardPath = path.replace(/\\/g, "/");
    const classInstance = new ImageResizerOptions(imageResizerOptions);
    return `${process.env.REACT_APP_FILEBROWSER_ROOT_URL}/${forwardPath}${classInstance.toQueryStringValues('?')}`;
  }
}

export const formatDateTime = (date: Date | undefined, formatter: "i18n.Formatted.DateTime" | "i18n.Formatted.Date") => {
  return (!date || new Date(date).toUTCString() === new Date("0001-01-01T00:00:00").toUTCString())
    ? "-"
    : i18n.t(formatter, { date: new Date(date) });
}